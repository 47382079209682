import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { axiosApi } from "helpers/api_helper"
import { GET_TOP_CLANS } from "helpers/url_helper"
import { BlinkBlur } from "react-loading-indicators"
import { loadingDelayTime } from "helpers/constants"

const TopClans = () => {
  const [topClans, setTopClans] = useState([])
  const [period, setPeriod] = useState("All")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getTopClans()
  }, [])

  const getTopClans = (date_range = period) => {
    setIsLoading(true)
    axiosApi
      .get(`${GET_TOP_CLANS}/${date_range}`)
      .then(res => {
        setTopClans(res.data)
        setTimeout(() => {
          setIsLoading(false)
        }, [loadingDelayTime])
      })
      .catch(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, [loadingDelayTime])
      })
  }

  const togglePeriodChecked = value => {
    setPeriod(value)
    getTopClans(value)
  }

  return (
    <>
      <div className="top-clans-card p-4 h-100">
        <div className="title">
          <h3>Top Clans</h3>

          <div className="btn-group" role="group">
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio_3m"
              autoComplete="off"
              onClick={() => togglePeriodChecked("3m")}
            />
            <label className="btn btn-outline-success" htmlFor="btnradio_3m">
              3 Months
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio_6m"
              autoComplete="off"
              onClick={() => togglePeriodChecked("6m")}
            />
            <label className="btn btn-outline-success" htmlFor="btnradio_6m">
              6 Months
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio_all"
              autoComplete="off"
              defaultChecked
              onClick={() => togglePeriodChecked("All")}
            />
            <label className="btn btn-outline-success" htmlFor="btnradio_all">
              All
            </label>
          </div>
        </div>

        <div className="mt-3">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "480px" }}
            >
              <BlinkBlur color="#34c38f" size="medium" text="" textColor="" />
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="ecpx-table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-start">Clan Name</th>
                    <th className="text-end" style={{ minWidth: "180px" }}>
                      Total $ECPx deposit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topClans.map((row, id) => {
                    return (
                      <tr key={`leaderboard-li-${id}`}>
                        <td scope="row">{id + 1}</td>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              className="rounded"
                              src={row.clan_logo}
                              alt={row.clan_name}
                              width={"40px"}
                            />
                            <span>{row.clan_name}</span>
                          </div>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {row.total_deposit} $ECPX
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TopClans
