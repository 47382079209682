import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { BlinkBlur } from "react-loading-indicators"
import { axiosApi } from "helpers/api_helper"
import { GET_BATTLE_CLANS } from "helpers/url_helper"
import { loadingDelayTime } from "helpers/constants"

import rank1Logo from "assets/ecpx/images/leaderboard/Rank1.png"
import rank2Logo from "assets/ecpx/images/leaderboard/Rank2.png"
import rank3Logo from "assets/ecpx/images/leaderboard/Rank3.png"

const rankLogos = [rank1Logo, rank2Logo, rank3Logo]

const ClanRanks = props => {
  const [battleClans, setBattleClans] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getBattleClans = battleId => {
    setIsLoading(true)
    axiosApi
      .get(`${GET_BATTLE_CLANS}/${battleId}`)
      .then(res => {
        setBattleClans(res.data)
        console.log(res.data)
        if (res.data.length > 0) {
          props.onClanClick(res.data[0])
        }
        setTimeout(() => setIsLoading(false), [loadingDelayTime])
      })
      .catch(() => setTimeout(() => setIsLoading(false), [loadingDelayTime]))
  }

  useEffect(() => {
    getBattleClans(props.battle_id)
  }, [props.battle_id])

  return (
    <>
      <div className="leaderboard-card">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "516px" }}
          >
            <BlinkBlur color="#34c38f" size="medium" text="" textColor="" />
          </div>
        ) : (
          <div className="table-responsive">
            <Table className="ecpx-table mb-0">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Emblem</th>
                  <th>Name</th>
                  <th>Total BP</th>
                </tr>
              </thead>
              <tbody>
                {battleClans.map((row, id) => {
                  return (
                    <tr
                      key={`clan-li-${row.clan_id}`}
                      onClick={() => {
                        props.onClanClick(row)
                      }}
                    >
                      <td scope="row">
                        {id > 2 ? (
                          id + 1
                        ) : (
                          <img
                            src={rankLogos[id]}
                            alt={`rank${id + 1}`}
                            width={"40px"}
                          />
                        )}
                      </td>
                      <td>
                        <img
                          className="rounded"
                          src={row.clan_logo}
                          alt={row.name}
                          width={"50px"}
                        />
                      </td>
                      <td>{row.clan_name}</td>
                      <td>{row.vote}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  )
}

export default ClanRanks
