import React, { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

import {
  TokenPayment,
  BigUIntValue,
  U32Value,
  U64Value,
  Account,
  Address,
  SmartContract,
  BytesValue,
  ContractFunction,
  AddressValue,
  ResultsParser,
  OptionalValue,
  TokenIdentifierValue,
  Interaction,
  VariadicValue,
} from "@multiversx/sdk-core/out"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out"
import { contracts, ElrondApiUrl, ElrondGatewayUrl } from "configs"

import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks"
import { sendTransactions } from "@multiversx/sdk-dapp/services"

import { admins, owner } from "configs"
import {
  CLAN_INFO,
  ECPX_TOKEN_ID,
  GenesisCollection,
  XCollection,
} from "helpers/constants"
import {
  convertWeiToEsdt,
  convertEsdtToWei,
  shortenEsdt,
  nonceToGenesis,
  nonceToECPX,
} from "utils"
import { toast } from "react-toastify"
import axios from "axios"
import PulseLoader from "react-spinners/ClipLoader"
import classnames from "classnames"

import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"

const clanOption = [
  {
    label: "Clans",
    options: [
      { label: "The Chieftains", value: "The Chieftains" },
      { label: "Air Force", value: "Air Force" },
      { label: "Bounty Hunters", value: "Bounty Hunters" },
      { label: "Demonic Resurrectors", value: "Demonic Resurrectors" },
      { label: "Epic Demons", value: "Epic Demons" },
      { label: "Gilded Syndicate", value: "Gilded Syndicate" },
      { label: "The Vandals", value: "The Vandals" },
      { label: "Legion of Doom", value: "Legion of Doom" },
      { label: "Lunatic Clowns", value: "Lunatic Clowns" },
      { label: "Nomads Rising", value: "Nomads Rising" },
      { label: "Savannah Wizards", value: "Savannah Wizards" },
      { label: "Shadow Fighters", value: "Shadow Fighters" },
      { label: "Silent Assassins", value: "Silent Assassins" },
      { label: "Supreme Squad", value: "Supreme Squad" },
      { label: "Tech Destroyers", value: "Tech Destroyers" },
      { label: "The Reptiles", value: "The Reptiles" },
      { label: "Thunder Clan", value: "Thunder Clan" },
      { label: "The Purples", value: "The Purples" },
      { label: "Valhalla Warriors", value: "Valhalla Warriors" },
      { label: "Zombie Unit", value: "Zombie Unit" },
    ],
  },
]

const Settings = () => {
  const [stakingInfo, setStakingInfo] = useState(undefined)
  const [saleInfo, setSaleInfo] = useState(undefined)
  const [battleInfo, setBattleInfo] = useState(undefined)

  const [newGenesisEgldReward, setNewGenesisEgldReward] = useState("")
  const [newGenesisEcpxReward, setNewGenesisEcpxReward] = useState("")

  const [newGenesisSpecialToken, setNewGenesisSpecialToken] = useState("")
  const [newGenesisSpecialEgldReward, setNewGenesisSpecialEgldReward] =
    useState("")
  const [newGenesisSpecialEcpxReward, setNewGenesisSpecialEcpxReward] =
    useState("")

  const [newXEgldReward, setNewXEgldReward] = useState("")
  const [newXEcpxReward, setNewXEcpxReward] = useState("")

  const [newXSpecialToken, setNewXSpecialToken] = useState("")
  const [newXSpecialEgldReward, setNewXSpecialEgldReward] = useState("")
  const [newXSpecialEcpxReward, setNewXSpecialEcpxReward] = useState("")

  const [newKingEgldReward, setNewKingEgldReward] = useState("")
  const [newKingEcpxReward, setNewKingEcpxReward] = useState("")

  const [newClanType, setNewClanType] = useState("")

  const [newClanEgldReward, setNewClanEgldReward] = useState("")
  const [newClanEcpxReward, setNewClanEcpxReward] = useState("")

  const [depositEgldAmount, setDepositEgldAmount] = useState("")
  const [depositEcpxAmount, setDepositEcpxAmount] = useState("")

  const [activeTab, setactiveTab] = useState("1")

  const [selectedClan, setSelectedClan] = useState(null)

  const currentTime = useMemo(() => Math.round(Date.now() / 1000), [])

  const [startTime, setStartTime] = useState(currentTime)
  const [endTime, setEndTime] = useState(currentTime)
  const [ecpxFee, setEcpxFee] = useState("")
  const [egldPrize, setEgldPrize] = useState("")
  const [ecpxPrize, setEcpxPrize] = useState("")
  const [minPower, setMinPower] = useState("")

  const [splitEcpxAmount, setSplitEcpxAmount] = useState(0)
  const [genesisEcpxApr, setGenesisEcpxApr] = useState(0)
  const [punkzEcpxApr, setPunkzEcpxApr] = useState(0)

  // const [newFirstPackPrice, setNewFirstPackPrice] = useState('');
  // const [newSecondPackPrice, setNewSecondPackPrice] = useState('');
  // const [newThirdPackPrice, setNewThirdPackPrice] = useState('');

  const { address: userAddress } = useGetAccountInfo()
  const navigate = useNavigate()
  const { hasPendingTransactions } = useGetPendingTransactions()

  const nftStakingContractAddress = new Address(contracts.NftStaking.address)
  const nftStakingContract = new SmartContract({
    address: nftStakingContractAddress,
    abi: contracts.NftStaking.abi,
  })

  const nftSaleContractAddress = new Address(contracts.NftSale.address)
  const nftSaleContract = new SmartContract({
    address: nftSaleContractAddress,
    abi: contracts.NftSale.abi,
  })

  const battleContractAddress = new Address(contracts.BattleDao.address)
  const battleContract = new SmartContract({
    address: battleContractAddress,
    abi: contracts.BattleDao.abi,
  })

  const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, {
    timeout: 30000,
  })

  useEffect(() => {
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getStakingInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        console.log(config)

        setStakingInfo(config)
      } catch (error) {
        console.log(error)
      }
    })()

    ;(async () => {
      try {
        const query = nftSaleContract.createQuery({
          func: new ContractFunction("getSaleInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition = nftSaleContract.getEndpoint("getSaleInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        setSaleInfo(config)
      } catch (error) {
        console.log(error)
      }
    })()

    ;(async () => {
      try {
        const query = battleContract.createQuery({
          func: new ContractFunction("getBattleInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition = battleContract.getEndpoint("getBattleInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const info = firstValue?.valueOf()

        setBattleInfo(info)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [hasPendingTransactions])

  useEffect(() => {
    if (!userAddress || !admins.includes(userAddress)) {
      navigate("/")
    }
  }, [userAddress])

  const setClanInfo = address => {
    if (userAddress != owner) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const clan of CLAN_INFO) {
          args.push(
            ...[
              BytesValue.fromUTF8(clan[0]),
              new U64Value(clan[1]),
              new U64Value(clan[2]),
              new U64Value(clan[3]),
              new U64Value(clan[4]),
              new U64Value(clan[5]),
            ]
          )
        }

        const tx = nftStakingContract.methodsExplicit
          .setClanInfos(args)
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const updateGenesisBasicEgldReward = () => {
    if (!newGenesisEgldReward) {
      toast.info("Please input new Genesis Basic EGLD Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateGenesisBasicEgldReward([
          new BigUIntValue(convertEsdtToWei(newGenesisEgldReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const updateGenesisBasicEcpxReward = () => {
    if (!newGenesisEcpxReward) {
      toast.info("Please input new Genesis Basic ECPX Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateGenesisBasicEcpxReward([
          new BigUIntValue(convertEsdtToWei(newGenesisEcpxReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const setGenesisSpecialEgldReward = () => {
    if (!newGenesisSpecialToken) {
      toast.info("Please input Genesis NFT token id to set Special Reward")

      return
    }

    if (!newGenesisSpecialEgldReward) {
      toast.info("Please input Special EGLD daily reward amount")

      return
    }

    ;(async () => {
      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/nfts/${newGenesisSpecialToken}`
        )

        if (data.collection != GenesisCollection) {
          toast.warning("Incorrect Genesis Collection")

          return
        }

        const tx = nftStakingContract.methodsExplicit
          .setGenesisSpecialEgldReward([
            new U64Value(data.nonce),
            new BigUIntValue(convertEsdtToWei(newGenesisSpecialEgldReward)),
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const removeGenesisSpecialEgldReward = nonce => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .setGenesisSpecialEgldReward([
            new U64Value(nonce),
            new BigUIntValue(0),
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const setGenesisSpecialEcpxReward = () => {
    if (!newGenesisSpecialToken) {
      toast.info("Please input Genesis NFT token id to set Special Reward")

      return
    }

    if (!newGenesisSpecialEcpxReward) {
      toast.info("Please input Special ECPX daily reward amount")

      return
    }

    ;(async () => {
      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/nfts/${newGenesisSpecialToken}`
        )

        if (data.collection != GenesisCollection) {
          toast.warning("Incorrect Genesis Collection")

          return
        }

        const tx = nftStakingContract.methodsExplicit
          .setGenesisSpecialEcpxReward([
            new U64Value(data.nonce),
            new BigUIntValue(convertEsdtToWei(newGenesisSpecialEcpxReward)),
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const removeGenesisSpecialEcpxReward = nonce => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .setGenesisSpecialEcpxReward([
            new U64Value(nonce),
            new BigUIntValue(0),
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const updateXBasicEgldReward = () => {
    if (!newXEgldReward) {
      toast.info("Please input new ECPX Collection Basic EGLD Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateXBasicEgldReward([
          new BigUIntValue(convertEsdtToWei(newXEgldReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const updateXBasicEcpxReward = () => {
    if (!newXEcpxReward) {
      toast.info("Please input new ECPX collection Basic ECPX Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateXBasicEcpxReward([
          new BigUIntValue(convertEsdtToWei(newXEcpxReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const setXSpecialEgldReward = () => {
    if (!newXSpecialToken) {
      toast.info("Please input ECPX NFT token id to set Special Reward")

      return
    }

    if (!newXSpecialEgldReward) {
      toast.info("Please input Special EGLD daily reward amount")

      return
    }

    ;(async () => {
      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/nfts/${newXSpecialToken}`
        )

        if (data.collection != XCollection) {
          toast.warning("Incorrect ECPX Collection")

          return
        }

        const tx = nftStakingContract.methodsExplicit
          .setXSpecialEgldReward([
            new U64Value(data.nonce),
            new BigUIntValue(convertEsdtToWei(newXSpecialEgldReward)),
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const removeXSpecialEgldReward = nonce => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .setXSpecialEgldReward([new U64Value(nonce), new BigUIntValue(0)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const setXSpecialEcpxReward = () => {
    if (!newXSpecialToken) {
      toast.info("Please input ECPX NFT token id to set Special Reward")

      return
    }

    if (!newXSpecialEcpxReward) {
      toast.info("Please input Special ECPX daily reward amount")

      return
    }

    ;(async () => {
      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/nfts/${newXSpecialToken}`
        )

        if (data.collection != XCollection) {
          toast.warning("Incorrect ECPX Collection")

          return
        }

        const tx = nftStakingContract.methodsExplicit
          .setXSpecialEcpxReward([
            new U64Value(data.nonce),
            new BigUIntValue(convertEsdtToWei(newXSpecialEcpxReward)),
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const removeXSpecialEcpxReward = nonce => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .setXSpecialEcpxReward([new U64Value(nonce), new BigUIntValue(0)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const updateKingBasicEgldReward = () => {
    if (!newKingEgldReward) {
      toast.info("Please input new King of Clan EGLD Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateKingEgldReward([
          new BigUIntValue(convertEsdtToWei(newKingEgldReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const updateKingBasicEcpxReward = () => {
    if (!newKingEcpxReward) {
      toast.info("Please input new King of Clan ECPX Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateKingEcpxReward([
          new BigUIntValue(convertEsdtToWei(newKingEcpxReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const updateClanBasicEgldReward = () => {
    if (!newClanEgldReward) {
      toast.info("Please input new Battle Clan EGLD Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateClanEgldReward([
          new BigUIntValue(convertEsdtToWei(newClanEgldReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const updateClanBasicEcpxReward = () => {
    if (!newClanEcpxReward) {
      toast.info("Please input new Battle Clan ECPX Daily Reward")

      return
    }

    ;(async () => {
      const tx = nftStakingContract.methodsExplicit
        .updateClanEcpxReward([
          new BigUIntValue(convertEsdtToWei(newClanEcpxReward)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  const updateClanType = () => {
    if (!userAddress) {
      return
    }

    toast.info("Updating clan type is processing")

    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .setClanType([BytesValue.fromUTF8(newClanType)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "900000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        toast.warning("Please check Token Id")
        console.log(error)
      }
    })()
  }

  const depositEgld = type => {
    if (!userAddress || !depositEgldAmount) {
      return
    }

    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .deposit([new U64Value(type)])
          .withValue(convertEsdtToWei(depositEgldAmount))
          .buildTransaction()

        const rawTx = {
          value: convertEsdtToWei(depositEgldAmount).toString(),
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const depositEcpx = type => {
    if (!userAddress || !depositEcpxAmount) {
      return
    }

    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .deposit([new U64Value(type)])
          .withSingleESDTTransfer(
            TokenPayment.fungibleFromBigInteger(
              ECPX_TOKEN_ID,
              convertEsdtToWei(depositEcpxAmount),
              18
            )
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const depositToken = () => {
    if (!userAddress || !depositEcpxAmount) {
      return
    }

    ;(async () => {
      try {
        const tx = nftSaleContract.methodsExplicit
          .depositFt([])
          .withSingleESDTTransfer(
            TokenPayment.fungibleFromBigInteger(
              ECPX_TOKEN_ID,
              convertEsdtToWei(depositEcpxAmount),
              18
            )
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftSale.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const depositNft = () => {
    if (!userAddress) {
      return
    }

    ;(async () => {
      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/accounts/${userAddress}/nfts?size=200&collections=${XCollection}`
        )

        const args = []

        for (const nft of data) {
          args.push(TokenPayment.nonFungible(XCollection, nft.nonce))
        }

        if (!args.length) {
          toast.info(`No ${clanType} NFTs to deposit`)

          return
        }

        const tx = nftSaleContract.methodsExplicit
          .depositNft([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 60 + args.length * 2 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const createBattle = () => {
    if (!userAddress) {
      return
    }

    if (!selectedClan || selectedClan.length < 2) {
      toast.warn("Please select 2+ Clans to create battle")
      return
    }

    if (endTime <= startTime) {
      toast.warn("End time should be after Start time.")
      return
    }

    if (!ecpxFee) {
      toast.warn("Please input $ECPX fee")
      return
    }

    if (!egldPrize) {
      toast.warn("Please input $EGLD Prize")
      return
    }

    if (!ecpxPrize) {
      toast.warn("Please input $ECPX Prize")
      return
    }

    if (!minPower) {
      toast.warn("Please input min power to unlock Prize")
      return
    }

    // console.log(selectedClan, startTime, endTime, ecpxFee);
    ;(async () => {
      try {
        const clanArgs = []
        for (const clan of selectedClan) {
          clanArgs.push(BytesValue.fromUTF8(clan.value))
        }
        const tx = battleContract.methodsExplicit
          .createBattle([
            new U64Value(startTime),
            new U64Value(endTime),
            new BigUIntValue(convertEsdtToWei(ecpxFee)),
            new BigUIntValue(convertEsdtToWei(egldPrize)),
            new BigUIntValue(convertEsdtToWei(ecpxPrize)),
            new U64Value(Math.round(+minPower)),
            ...clanArgs,
          ])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.BattleDao.address,
          gasLimit: "100000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const selectClan = selected => {
    console.log(selected)
    setSelectedClan(selected)
  }

  const splitStakingDeposit = () => {
    if (!userAddress || !splitEcpxAmount) {
      return
    }

    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .deposit([new U64Value(4)])
          .withSingleESDTTransfer(
            TokenPayment.fungibleFromBigInteger(
              ECPX_TOKEN_ID,
              convertEsdtToWei(splitEcpxAmount),
              18
            )
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const splitStakingWithdraw = () => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .withdraw([
            new TokenIdentifierValue(ECPX_TOKEN_ID),
            new BigUIntValue(convertEsdtToWei(splitEcpxAmount)),
            new BigUIntValue(4),
          ])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const applyGenesisApr = () => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .updateValidatorBasicEcpxApr([new BigUIntValue(genesisEcpxApr)])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  
  }

  const applyPunkzApr = () => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .updateValidatorPunkzEcpxApr([new BigUIntValue(punkzEcpxApr)])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="hub-setting">
          {stakingInfo && saleInfo ? (
            <Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1")
                    }}
                  >
                    NFT Staking
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    NFT Sale
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    Battle Dao
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4")
                    }}
                  >
                    Merge Staking
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="p-3 pt-5 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        Genesis Deposited EGLD:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.genesis_egld_reward_pool)
                        )}{" "}
                        $EGLD
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="EGLD AMOUNT"
                          type="number"
                          value={depositEgldAmount}
                          onChange={e => setDepositEgldAmount(e.target.value)}
                        />
                        <button onClick={() => depositEgld(0)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        Genesis Deposited ECPX:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.genesis_ecpx_reward_pool)
                        )}{" "}
                        $ECPX
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="ECPX AMOUNT"
                          type="number"
                          value={depositEcpxAmount}
                          onChange={e => setDepositEcpxAmount(e.target.value)}
                        />
                        <button onClick={() => depositEcpx(0)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        ECPX Deposited EGLD:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.x_egld_reward_pool)
                        )}{" "}
                        $EGLD
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="EGLD AMOUNT"
                          type="number"
                          value={depositEgldAmount}
                          onChange={e => setDepositEgldAmount(e.target.value)}
                        />
                        <button onClick={() => depositEgld(1)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        ECPX Deposited ECPX:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.x_ecpx_reward_pool)
                        )}{" "}
                        $ECPX
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="ECPX AMOUNT"
                          type="number"
                          value={depositEcpxAmount}
                          onChange={e => setDepositEcpxAmount(e.target.value)}
                        />
                        <button onClick={() => depositEcpx(1)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        CLAN Deposited EGLD:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.clan_egld_reward_pool)
                        )}{" "}
                        $EGLD
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="EGLD AMOUNT"
                          type="number"
                          value={depositEgldAmount}
                          onChange={e => setDepositEgldAmount(e.target.value)}
                        />
                        <button onClick={() => depositEgld(2)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        CLAN Deposited ECPX:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.clan_ecpx_reward_pool)
                        )}{" "}
                        $ECPX
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="ECPX AMOUNT"
                          type="number"
                          value={depositEcpxAmount}
                          onChange={e => setDepositEcpxAmount(e.target.value)}
                        />
                        <button onClick={() => depositEcpx(2)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        KING Deposited EGLD:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.king_egld_reward_pool)
                        )}{" "}
                        $EGLD
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="EGLD AMOUNT"
                          type="number"
                          value={depositEgldAmount}
                          onChange={e => setDepositEgldAmount(e.target.value)}
                        />
                        <button onClick={() => depositEgld(3)}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h5>
                        KING Deposited ECPX:{" "}
                        {shortenEsdt(
                          convertWeiToEsdt(stakingInfo.king_ecpx_reward_pool)
                        )}{" "}
                        $ECPX
                      </h5>

                      <div className="d-flex">
                        <input
                          placeholder="ECPX AMOUNT"
                          type="number"
                          value={depositEcpxAmount}
                          onChange={e => setDepositEcpxAmount(e.target.value)}
                        />
                        <button onClick={() => depositEcpx(3)}>DEPOSIT</button>
                      </div>
                    </Col>

                    {owner == userAddress && stakingInfo.clan_info_is_empty && (
                      <Col xs="12">
                        <button
                          className="btn btn-primary waves-effect waves-light mb-5"
                          onClick={() =>
                            setClanInfo(contracts.NftStaking.address)
                          }
                        >
                          Set Clan Info
                        </button>
                      </Col>
                    )}

                    <Col md="6" xxl="4">
                      <div className="setting-panel">
                        <h3>Genesis Collection</h3>

                        <h6 className="mt-5">
                          Basic EGLD Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(
                              stakingInfo.genesis_basic_egld_reward
                            )
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">
                          Update Basic EGLD Daily Reward:
                        </h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newGenesisEgldReward}
                            onChange={e =>
                              setNewGenesisEgldReward(e.target.value)
                            }
                          />
                          <button
                            onClick={() => updateGenesisBasicEgldReward()}
                          >
                            UPDATE
                          </button>
                        </div>

                        <h6 className="mt-5">
                          Basic ECPX Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(
                              stakingInfo.genesis_basic_ecpx_reward
                            )
                          )}{" "}
                          $ECPX
                        </h6>
                        <h6 className="mt-2">
                          Update Basic ECPX Daily Reward:
                        </h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newGenesisEcpxReward}
                            onChange={e =>
                              setNewGenesisEcpxReward(e.target.value)
                            }
                          />
                          <button
                            onClick={() => updateGenesisBasicEcpxReward()}
                          >
                            UPDATE
                          </button>
                        </div>

                        <h6 className="mt-5">
                          Special EGLD Daily Reward Per TokenID
                        </h6>
                        <h6 className="mt-2">Add Special EGLD Daily Reward:</h6>
                        <div
                          className="d-flex flex-column"
                          style={{ maxWidth: 250 }}
                        >
                          <input
                            placeholder="Genesis NFT TokenId"
                            type="text"
                            value={newGenesisSpecialToken}
                            onChange={e =>
                              setNewGenesisSpecialToken(e.target.value)
                            }
                          />
                          <input
                            placeholder="Daily Reward Amount"
                            className="my-1"
                            type="number"
                            value={newGenesisSpecialEgldReward}
                            onChange={e =>
                              setNewGenesisSpecialEgldReward(e.target.value)
                            }
                          />
                          <button onClick={() => setGenesisSpecialEgldReward()}>
                            UPDATE
                          </button>
                        </div>

                        {stakingInfo.genesis_special_egld_reward.map(
                          (info, index) => (
                            <div
                              className="d-flex mt-2 align-items-center justify-content-between"
                              key={index}
                            >
                              <h6 className="mb-0">
                                {nonceToGenesis(info.token_nonce)}:
                              </h6>
                              <h6 className="mb-0">
                                {shortenEsdt(convertWeiToEsdt(info.reward))}{" "}
                                $EGLD
                              </h6>
                              <button
                                onClick={() =>
                                  removeGenesisSpecialEgldReward(
                                    info.token_nonce.toNumber()
                                  )
                                }
                              >
                                REMOVE
                              </button>
                            </div>
                          )
                        )}

                        <h6 className="mt-5">
                          Special ECPX Daily Reward Per TokenID
                        </h6>
                        <h6 className="mt-2">Add Special ECPX Daily Reward:</h6>
                        <div
                          className="d-flex flex-column"
                          style={{ maxWidth: 250 }}
                        >
                          <input
                            placeholder="Genesis NFT TokenId"
                            type="text"
                            value={newGenesisSpecialToken}
                            onChange={e =>
                              setNewGenesisSpecialToken(e.target.value)
                            }
                          />
                          <input
                            placeholder="Daily Reward Amount"
                            className="my-1"
                            type="number"
                            value={newGenesisSpecialEcpxReward}
                            onChange={e =>
                              setNewGenesisSpecialEcpxReward(e.target.value)
                            }
                          />
                          <button onClick={() => setGenesisSpecialEcpxReward()}>
                            UPDATE
                          </button>
                        </div>

                        {stakingInfo.genesis_special_ecpx_reward.map(
                          (info, index) => (
                            <div
                              className="d-flex mt-2 align-items-center justify-content-between"
                              key={index}
                            >
                              <h6 className="mb-0">
                                {nonceToGenesis(info.token_nonce)}:
                              </h6>
                              <h6 className="mb-0">
                                {shortenEsdt(convertWeiToEsdt(info.reward))}{" "}
                                $ECPX
                              </h6>
                              <button
                                onClick={() =>
                                  removeGenesisSpecialEcpxReward(
                                    info.token_nonce.toNumber()
                                  )
                                }
                              >
                                REMOVE
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </Col>

                    <Col md="6" xxl="4">
                      <div className="setting-panel">
                        <h3>ECPX Collection</h3>

                        <h6 className="mt-5">
                          Basic EGLD Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(stakingInfo.x_basic_egld_reward)
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">
                          Update Basic EGLD Daily Reward:
                        </h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newXEgldReward}
                            onChange={e => setNewXEgldReward(e.target.value)}
                          />
                          <button onClick={() => updateXBasicEgldReward()}>
                            UPDATE
                          </button>
                        </div>

                        <h6 className="mt-5">
                          Basic ECPX Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(stakingInfo.x_basic_ecpx_reward)
                          )}{" "}
                          $ECPX
                        </h6>
                        <h6 className="mt-2">
                          Update Basic ECPX Daily Reward:
                        </h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newXEcpxReward}
                            onChange={e => setNewXEcpxReward(e.target.value)}
                          />
                          <button onClick={() => updateXBasicEcpxReward()}>
                            UPDATE
                          </button>
                        </div>

                        <h6 className="mt-5">
                          Special EGLD Daily Reward Per TokenID
                        </h6>
                        <h6 className="mt-2">Add Special EGLD Daily Reward:</h6>
                        <div
                          className="d-flex flex-column"
                          style={{ maxWidth: 250 }}
                        >
                          <input
                            placeholder="ECPX NFT TokenId"
                            type="text"
                            value={newXSpecialToken}
                            onChange={e => setNewXSpecialToken(e.target.value)}
                          />
                          <input
                            placeholder="Daily Reward Amount"
                            className="my-1"
                            type="number"
                            value={newXSpecialEgldReward}
                            onChange={e =>
                              setNewXSpecialEgldReward(e.target.value)
                            }
                          />
                          <button onClick={() => setXSpecialEgldReward()}>
                            UPDATE
                          </button>
                        </div>

                        {stakingInfo.x_special_egld_reward.map(
                          (info, index) => (
                            <div
                              className="d-flex mt-2 align-items-center justify-content-between"
                              key={index}
                            >
                              <h6 className="mb-0">
                                {nonceToECPX(info.token_nonce)}:
                              </h6>
                              <h6 className="mb-0">
                                {shortenEsdt(convertWeiToEsdt(info.reward))}{" "}
                                $EGLD
                              </h6>
                              <button
                                onClick={() =>
                                  removeXSpecialEgldReward(
                                    info.token_nonce.toNumber()
                                  )
                                }
                              >
                                REMOVE
                              </button>
                            </div>
                          )
                        )}

                        <h6 className="mt-5">
                          Special ECPX Daily Reward Per TokenID
                        </h6>
                        <h6 className="mt-2">Add Special ECPX Daily Reward:</h6>
                        <div
                          className="d-flex flex-column"
                          style={{ maxWidth: 250 }}
                        >
                          <input
                            placeholder="ECPX NFT TokenId"
                            type="text"
                            value={newXSpecialToken}
                            onChange={e => setNewXSpecialToken(e.target.value)}
                          />
                          <input
                            placeholder="Daily Reward Amount"
                            className="my-1"
                            type="number"
                            value={newXSpecialEcpxReward}
                            onChange={e =>
                              setNewXSpecialEcpxReward(e.target.value)
                            }
                          />
                          <button onClick={() => setXSpecialEcpxReward()}>
                            UPDATE
                          </button>
                        </div>

                        {stakingInfo.x_special_ecpx_reward.map(
                          (info, index) => (
                            <div
                              className="d-flex mt-2 align-items-center justify-content-between"
                              key={index}
                            >
                              <h6 className="mb-0">
                                {nonceToECPX(info.token_nonce)}:
                              </h6>
                              <h6 className="mb-0">
                                {shortenEsdt(convertWeiToEsdt(info.reward))}{" "}
                                $ECPX
                              </h6>
                              <button
                                onClick={() =>
                                  removeXSpecialEcpxReward(
                                    info.token_nonce.toNumber()
                                  )
                                }
                              >
                                REMOVE
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </Col>

                    <Col md="6" xxl="4">
                      <div className="setting-panel">
                        <h3>King of Clan</h3>

                        <h6 className="mt-5">
                          EGLD Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(stakingInfo.king_basic_egld_reward)
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">Update EGLD Daily Reward:</h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newKingEgldReward}
                            onChange={e => setNewKingEgldReward(e.target.value)}
                          />
                          <button onClick={() => updateKingBasicEgldReward()}>
                            UPDATE
                          </button>
                        </div>

                        <h6 className="mt-5">
                          ECPX Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(stakingInfo.king_basic_ecpx_reward)
                          )}{" "}
                          $ECPX
                        </h6>
                        <h6 className="mt-2">Update ECPX Daily Reward:</h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newKingEcpxReward}
                            onChange={e => setNewKingEcpxReward(e.target.value)}
                          />
                          <button onClick={() => updateKingBasicEcpxReward()}>
                            UPDATE
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col md="6" xxl="4">
                      <div className="setting-panel">
                        <h3>Winning Clan of Battle</h3>

                        <h6 className="mt-5">
                          Special Clan Type:{" "}
                          {stakingInfo.clan_type.toString()
                            ? stakingInfo.clan_type.toString()
                            : "Clan Pool is closed"}
                        </h6>
                        <h6 className="mt-2">Update EGLD Daily Reward:</h6>
                        <div className="d-flex justify-content-between">
                          <select
                            value={newClanType}
                            onChange={e => setNewClanType(e.target.value)}
                          >
                            <option value="">Close Clan Pool</option>
                            {CLAN_INFO.map((clan_info, index) => (
                              <option key={index} value={clan_info[0]}>
                                {clan_info[0]}
                              </option>
                            ))}
                          </select>
                          <button onClick={() => updateClanType()}>
                            Update Clan Pool
                          </button>
                        </div>

                        <h6 className="mt-5">
                          EGLD Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(stakingInfo.clan_basic_egld_reward)
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">Update EGLD Daily Reward:</h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newClanEgldReward}
                            onChange={e => setNewClanEgldReward(e.target.value)}
                          />
                          <button onClick={() => updateClanBasicEgldReward()}>
                            UPDATE
                          </button>
                        </div>

                        <h6 className="mt-5">
                          ECPX Daily Reward:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(stakingInfo.clan_basic_ecpx_reward)
                          )}{" "}
                          $ECPX
                        </h6>
                        <h6 className="mt-2">Update ECPX Daily Reward:</h6>
                        <div className="d-flex">
                          <input
                            placeholder="Daily Reward Amount"
                            type="number"
                            value={newClanEcpxReward}
                            onChange={e => setNewClanEcpxReward(e.target.value)}
                          />
                          <button onClick={() => updateClanBasicEcpxReward()}>
                            UPDATE
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col xs="12" sm="6" className="mb-5">
                      <h3>
                        Deposited ECPX Token:{" "}
                        {shortenEsdt(convertWeiToEsdt(saleInfo.ecpx_amount))}{" "}
                        $ECPX
                      </h3>

                      <div className="d-flex">
                        <input
                          placeholder="EGLD AMOUNT"
                          type="number"
                          value={depositEcpxAmount}
                          onChange={e => setDepositEcpxAmount(e.target.value)}
                        />
                        <button onClick={() => depositToken()}>DEPOSIT</button>
                      </div>
                    </Col>

                    <Col xs="12" sm="6" className="mb-5">
                      <h3>
                        Deposited Normal ECPX NFT count:{" "}
                        {saleInfo.member_count.toNumber()}
                      </h3>
                      <h3>
                        Deposited KING NFT count:{" "}
                        {saleInfo.king_count.toNumber()}
                      </h3>

                      <div className="d-flex">
                        <button onClick={() => depositNft()}>DEPOSIT</button>
                      </div>
                    </Col>

                    {owner == userAddress && saleInfo.clan_info_is_empty && (
                      <Col xs="12">
                        <button
                          className="btn btn-primary waves-effect waves-light mb-5"
                          onClick={() => setClanInfo(contracts.NftSale.address)}
                        >
                          Set Clan Info
                        </button>
                      </Col>
                    )}

                    <Col md="6" xxl="4">
                      <div className="setting-panel">
                        <h3>First Pack</h3>

                        <h6 className="mt-2">
                          Price:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(saleInfo.first_pack_price)
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">
                          ECPX NFT: {saleInfo.first_pack_nfts.toNumber()}
                        </h6>
                        <h6 className="mt-2">
                          ECPX Token:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(saleInfo.first_pack_ft)
                          )}{" "}
                          $ECPX
                        </h6>

                        <h3 className="mt-4">Second Pack</h3>

                        <h6 className="mt-2">
                          Price:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(saleInfo.second_pack_price)
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">
                          ECPX NFT: {saleInfo.second_pack_nfts.toNumber()}
                        </h6>
                        <h6 className="mt-2">
                          ECPX Token:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(saleInfo.second_pack_ft)
                          )}{" "}
                          $ECPX
                        </h6>

                        <h3 className="mt-4">Third Pack</h3>

                        <h6 className="mt-2">
                          Price:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(saleInfo.third_pack_price)
                          )}{" "}
                          $EGLD
                        </h6>
                        <h6 className="mt-2">
                          ECPX NFT: {saleInfo.third_pack_nfts.toNumber()}
                        </h6>
                        <h6 className="mt-2">
                          ECPX Token:{" "}
                          {shortenEsdt(
                            convertWeiToEsdt(saleInfo.third_pack_ft)
                          )}{" "}
                          $ECPX
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    {owner == userAddress && battleInfo.clan_info_is_empty && (
                      <Col xs="12">
                        <button
                          className="btn btn-primary waves-effect waves-light mb-5"
                          onClick={() =>
                            setClanInfo(contracts.BattleDao.address)
                          }
                        >
                          Set Clan Info
                        </button>
                      </Col>
                    )}

                    <h3>Create Battle</h3>

                    <form>
                      <label className="control-label">
                        Select Battle Clans
                      </label>

                      <Select
                        value={selectedClan}
                        isMulti={true}
                        onChange={selectedOptions => {
                          selectClan(selectedOptions)
                        }}
                        options={clanOption}
                        className="select2-selection"
                      />
                    </form>

                    <DemoContainer components={["MobileDateTimePicker"]}>
                      <DemoItem label="Start Time">
                        <MobileDateTimePicker
                          onChange={value =>
                            setStartTime(
                              Math.round(value.toDate().getTime() / 1000)
                            )
                          }
                          defaultValue={dayjs(currentTime * 1000)}
                        />
                      </DemoItem>

                      <DemoItem label="End Time">
                        <MobileDateTimePicker
                          onChange={value =>
                            setEndTime(
                              Math.round(value.toDate().getTime() / 1000)
                            )
                          }
                          defaultValue={dayjs(currentTime * 1000)}
                        />
                      </DemoItem>
                    </DemoContainer>

                    <form>
                      <label className="control-label mt-3">
                        $ECPX Fee to Vote
                      </label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="$ECPX FEE"
                        type="number"
                        value={ecpxFee}
                        onChange={e => setEcpxFee(e.target.value)}
                      />
                    </form>

                    <form>
                      <label className="control-label mt-3">$EGLD Prize</label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="$EGLD PRIZE"
                        type="number"
                        value={egldPrize}
                        onChange={e => setEgldPrize(e.target.value)}
                      />
                    </form>

                    <form>
                      <label className="control-label mt-3">$ECPX PRIZE</label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="$ECPX PRIZE"
                        type="number"
                        value={ecpxPrize}
                        onChange={e => setEcpxPrize(e.target.value)}
                      />
                    </form>

                    <form>
                      <label className="control-label mt-3">
                        Min Power to Unlock Prize
                      </label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="Min Power"
                        type="number"
                        value={minPower}
                        onChange={e => setMinPower(e.target.value)}
                      />
                    </form>

                    <div className="d-flex">
                      <button
                        className="w-100 mt-3"
                        style={{ height: 45 }}
                        onClick={() => createBattle()}
                      >
                        Create Battle
                      </button>
                    </div>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <form>
                      <label className="control-label mt-3">
                        Genesis $ECPX APR
                      </label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="Genesis $ECPX APR"
                        type="number"
                        value={genesisEcpxApr}
                        onChange={e => setGenesisEcpxApr(e.target.value)}
                      />
                    </form>

                    <form>
                      <label className="control-label mt-3">
                        Punkz $ECPX APR
                      </label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="Punkz $ECPX APR"
                        type="number"
                        value={punkzEcpxApr}
                        onChange={e => setPunkzEcpxApr(e.target.value)}
                      />
                    </form>

                    <form className="d-flex align-items-center">
                      <label className="control-label mt-3">$ECPX Amount</label>

                      <input
                        style={{ width: "100%", height: 45 }}
                        placeholder="$ECPX Amount"
                        type="number"
                        value={splitEcpxAmount}
                        onChange={e => setSplitEcpxAmount(e.target.value)}
                      />
                      <button
                        className="w-100"
                        type="button"
                        style={{ height: 45 }}
                        onClick={() => splitStakingDeposit()}
                      >
                        Deposit
                      </button>
                      <button
                        className="w-100"
                        type="button"
                        style={{ height: 45 }}
                        onClick={() => splitStakingWithdraw()}
                      >
                        Withdraw
                      </button>
                    </form>

                    <div className="d-flex">
                      <button
                        className="w-100 mt-3"
                        type="button"
                        style={{ height: 45 }}
                        onClick={() => applyGenesisApr()}
                      >
                        Apply Genesis Apr
                      </button>
                      <button
                        type="button"
                        className="w-100 mt-3"
                        style={{ height: 45 }}
                        onClick={() => applyPunkzApr()}
                      >
                        Apply Punkz Apr
                      </button>
                    </div>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          ) : (
            <div
              style={{ minHeight: "calc(100vh - 100px)" }}
              className="d-flex justify-content-center align-items-center"
            >
              <PulseLoader color="#fff" />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Settings)
