import NftStaking from "pages/NftStaking"
import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import BuyPackage from "pages/BuyPackage"
import BuyToken from "pages/BuyToken"
import Marketplace from "pages/marketplace"
import Settings from "pages/Settings"
import Battle from "pages/Battle"
import Leaderboard from "pages/Leaderboard"
import ClanPools from "pages/ClanPools"
import CryptoWallet from "pages/CryptoWallet/crypto-wallet"
import BattleStats from "pages/BattleStats"
import Tokenomy from "pages/Tokenomy"
import Roadmap from "pages/Roadmap"
import ComingSoon from "pages/ComingSoon"
import ValidatorStaking from "pages/ValidatorStaking"

const routes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/comingsoon",
    component: <ComingSoon />,
  },
  {
    path: "/merge-staking",
    component: <ValidatorStaking />,
  },
  {
    path: "/nft-staking",
    component: <NftStaking />,
  },
  {
    path: "/buy-package",
    component: <BuyPackage />,
  },
  {
    path: "/buy-ecpx",
    component: <BuyToken />,
  },
  {
    path: "/marketplace",
    component: <Marketplace />,
  },
  {
    path: "/setting",
    component: <Settings />,
  },
  {
    path: "/battle",
    component: <Battle />,
  },
  {
    path: "/leaderboard",
    component: <Leaderboard />,
  },
  {
    path: "/battle-stats",
    component: <BattleStats />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/clanpools",
    component: <ClanPools />,
  },
  {
    path: "/my-wallet",
    component: <CryptoWallet />,
  },
  {
    path: "/tokenomy",
    component: <Tokenomy />,
  },
  {
    path: "/roadmap",
    component: <Roadmap />,
  },
]

export { routes }
