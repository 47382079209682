import React, { useState, useEffect } from 'react';

import BigNumber from 'bignumber.js/bignumber';

import { useSelector } from "react-redux";
import { convertWeiToEsdt, getTokenImg, handleTokenImgError, shortenEsdt } from 'utils';

const MyTokens = () => {
    const { coins } = useSelector((state) => state.Coin);
    const [searchKey, setSearchKey] = useState('');

    return (
        <div className='my-tokens-panel'>
            <h3 className='text-center'>
                My Tokens
            </h3>
            <form className="app-search">
              <div className="position-relative">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            {
                coins.filter((coin) => coin.ticker.toLowerCase().includes(searchKey.toLowerCase()) ).map((coin, index) => 
                    <div className='token-balance d-flex justify-content-between align-items-center' key={index}>
                        <img src={ getTokenImg(coin.identifier) } alt='' width={56} height={56} onError={handleTokenImgError} />

                        <div className='d-flex flex-column px-1'>
                            <h6>{coin.ticker}</h6>
                            <span style={{ color: '#00B2FF' }}>{shortenEsdt(new BigNumber(coin.price))}</span>
                            <p>Price</p>
                        </div>

                        <div className='divider' />

                        <div className='d-flex flex-column px-1'>
                            <h6>Balance</h6>
                            <span style={{ color: '#00FFA3' }}>{shortenEsdt(convertWeiToEsdt(coin.balance, coin.decimals))}</span>
                            <p>Current Balance</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default MyTokens;