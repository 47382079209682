import {
    UPDATE_NFT_INFO
} from "./actionTypes";

const INIT_STATE = {
    genesis: [],
    x: [],
    king: [],
    emblem: [],
    punkz: []
}

const Nft = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_NFT_INFO:
            return {
                ...state,
                genesis: action.payload.data.genesis,
                x: action.payload.data.x,
                king: action.payload.data.king,
                emblem: action.payload.data.emblem,
                punkz: action.payload.data.punkz
            };
        default:
            return state;
    }
}

export default Nft;
