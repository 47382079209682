import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { activityDataDashboard } from "common/data"

const Roadmap = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <p>
          2023 was a year of both triumphs and challenges. A triumph as we
          successfully launched the project, nurturing an organically growing
          community with genuinely valuable members. It was challenging due to
          the necessity of building in a bear market, a condition we all know
          makes creating a product exceptionally difficult. However, we all
          managed more than well.
        </p>

        <p>
          The outlook for 2024 is poised to be revolutionary, not only for us
          but for the entire ecosystem. This year, the efforts of our entire
          team will be concentrated on the Hub and its evolution. Foremost, ECPx
          HUB is set for a complete revamp, encompassing the infrastructure –
          more features, more security and more quality.
        </p>

        <p>
          All events such as launch of new collections, $ECPx listings,
          community events or new UI/UX improvements are not described in the
          roadmap but will be announced in our social channels. Stay tuned!
        </p>

        <div id="conference-timeline" className="mt-4 mb-4">
          <div className="timeline-start">2024 v 1.0</div>
          <div className="conference-center-line"></div>
          <div className="conference-timeline-content">
            <div className="timeline-article">
              <div className="content-left-container">
                <div className="left">
                  <p className="tml">
                    <strong>New “Connect Wallet” Module</strong>
                    <br />
                    <br />
                    The new module will provide a great UX and seamless
                    connection with the Hub, even for people who are
                    encountering crypto for the first time.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Deployed on Devnet</span>
                </p>
              </div>
              <div className="new24-date text-center">Q1</div>
            </div>
            <div className="timeline-article">
              <div className="content-left-container">
                <div className="left">
                  <p className="tml">
                    <strong>Brand new “Battles Leaderboard”</strong>
                    <br />
                    <br />
                    The Leaderboard will deliver real-time information about
                    battles. We can find clan rankings for the current battle,
                    top clans for specific periods based on spent $ECPx in
                    battles, top ERDs/Warriors for specific periods based on
                    spent $ECPx, and other useful data.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Deployed on Devnet</span>
                </p>
              </div>
            </div>
            <div className="timeline-article">
              <div className="content-left-container">
                <div className="left">
                  <p className="tml">
                    <strong>Brand new “My Wallet”</strong>
                    <br />
                    <br />
                    This is the new place with up-to-date information about the
                    assets you own… current balance, tokens etc.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">in Process</span>
                </p>
              </div>
            </div>
            <div className="timeline-article">
              <div className="content-left-container">
                <div className="left">
                  <p className="tml">
                    <strong>Brand new “Battle Stats” SC</strong>
                    <br />
                    <br />
                    Display, sort and download specific data for the battles.
                    Useful tool for Clan Kings, reward distributions, real-time
                    activities etc.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">In Process</span>
                </p>
              </div>
            </div>
            <div className="timeline-article">
              <div className="content-left-container">
                <div className="left">
                  <p className="tml">
                    <strong>Brand new “Inventory Wallet” SC</strong>
                    <br />
                    <br />
                    It will allow users to see their entire arsenal of NFTs with
                    which they can participate in battles, and they will no need
                    to stake/unstake their NFTs to participate in battles.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">In Process</span>
                </p>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="right">
                  <p className="tml">
                    <strong>New “DAO Battles” SC</strong>
                    <br />
                    <br />
                    New UI, ability to add other collections, voting with $EGLD,
                    option to invest rewards directly in CryptoScalper, infinite
                    BP, and much more…
                  </p>
                </div>
                <p>
                  <span className="timeline-author">in Process</span>
                </p>
              </div>
              <div className="new24-date text-center">Q2</div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="right">
                  <p className="tml">
                    <strong>Launch of ECPx HUB 2.0</strong>
                    <br />
                    <br />
                    Deploy the new version on Mainnet with all the features
                    described above.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Date TBA</span>
                </p>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="right">
                  <p className="tml">
                    <strong>CryptoScalper – auto trading Bot</strong>
                    <br />
                    <br />
                    Back tests &amp; Creating all the necessary tools for
                    interacting with the bot. Launch!
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Date TBA</span>
                </p>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="right">
                  <p className="tml">
                    <strong>$ECPx Special staking</strong>
                    <br />
                    <br />
                    Multiple types of staking options for the $ECPx token. When
                    the time comes, all staking variations will be announced.
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Coming Soon</span>
                </p>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-left-container">
                <div className="content-left">
                  <h3 className="tml">TBA</h3>
                  <p>
                    Due to the highly dynamic environment in which we operate,
                    we decided that it is better to announce Q3 and Q4 at a
                    slightly later stage, according to the needs and priorities
                    that will arise by then.{" "}
                    <span className="article-number">?</span>
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Coming Soon</span>
                </p>
              </div>
              <div className="new24-date text-center">Q3</div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="content-right">
                  <h3 className="tml">TBA</h3>
                  <p>
                    Due to the highly dynamic environment in which we operate,
                    we decided that it is better to announce Q3 and Q4 at a
                    slightly later stage, according to the needs and priorities
                    that will arise by then.{" "}
                    <span className="article-number">?</span>
                  </p>
                </div>
                <p>
                  <span className="timeline-author">Coming Soon</span>
                </p>
              </div>
              <div className="new24-date text-center">Q4</div>
            </div>
          </div>
          <div className="timeline-end">Last update 22.01.2024</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Roadmap
