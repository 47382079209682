import React, { useCallback, useState } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import styles from "./styles.module.scss"
import clsx from "clsx"
import { GenesisCollection, PunkzCollection, XCollection } from "helpers/constants"

export const NFTStakeModal = ({
  nfts,
  onStake,
  setModal,
  isOpen,
  nftCollection,
  basicEcpxRate,
  punkzEcpxRate,
}) => {
  const [selectedGenesis, setSelectedGenesis] = useState([])
  const [totalEcpx, setTotalEcpx] = useState(0)

  const onSelectGenesis = useCallback(
    nft => {
      let _selectedGenesis = [...selectedGenesis]
      let _nftIndex = _selectedGenesis.findIndex(el => {
        return el.nonce == nft.nonce
      })
      if (_nftIndex != -1) {
        if (nft.collection == GenesisCollection) {
          let _totalEcpx = totalEcpx
          _totalEcpx = _totalEcpx - basicEcpxRate
          setTotalEcpx(_totalEcpx)
        } else if (nft.collection == PunkzCollection) {
          let _totalEcpx = totalEcpx
          _totalEcpx -= punkzEcpxRate
          setTotalEcpx(_totalEcpx)
        }
        _selectedGenesis.splice(_nftIndex, 1)
      } else {
        if (nft.collection == GenesisCollection) {
          let _totalEcpx = totalEcpx
          _totalEcpx = _totalEcpx + basicEcpxRate
          setTotalEcpx(_totalEcpx)
        } else if (nft.collection == PunkzCollection) {
          let _totalEcpx = totalEcpx
          _totalEcpx += punkzEcpxRate
          setTotalEcpx(_totalEcpx)
        }
        _selectedGenesis.push(nft)
      }
      setSelectedGenesis(_selectedGenesis)
    },
    [selectedGenesis]
  )

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setModal(false)
      }}
      centered
      className="genesis-select-modal"
    >
      <ModalHeader>
        <div className="d-flex align-items-center">
          <h3 style={{ margin: "0px" }}>{nftCollection} NFTs</h3>+
          <h3 style={{ margin: "0px" }}>{totalEcpx}</h3>$ECPX tokens
        </div>
      </ModalHeader>
      <ModalBody className="genesis-modal-body">
        <Row className="my-nfts">
          {nfts.length > 0 ? (
            nfts.map((nft, index) => {
              return (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="nft-card mb-1"
                  key={index}
                >
                  <img src={nft.image} alt="" />
                  <h6 className="clan">{nft.clan}</h6>
                  <h6 className="identifier">{nft.identifier}</h6>
                  <button
                    className={`stake ${
                      selectedGenesis.findIndex(el => el.nonce == nft.nonce) ==
                      -1
                        ? ""
                        : "selected"
                    }`}
                    onClick={() => onSelectGenesis(nft)}
                  >
                    <i className="bx bx-chart" />
                    Select
                  </button>
                </Col>
              )
            })
          ) : (
            <Col xs="12">
              <h4 className="text-center my-3">You don't have NFT</h4>
            </Col>
          )}
        </Row>
        <Row
          className="genesis-modal-footer"
          style={{ justifyContent: "center" }}
        >
          <Col
            xs="4"
            className="stake-button"
            style={{ justifyContent: "center", display: "flex" }}
          >
            {" "}
            <button
              className={clsx("btn btn-block", styles.stakeButton)}
              onClick={() => {
                setModal(false)
                onStake(selectedGenesis, totalEcpx)
              }}
            >
              <i className="bx bx-chart" />
              Stake
            </button>
          </Col>
          <Col
            xs="4"
            className="close-button"
            style={{ justifyContent: "center", display: "flex" }}
          >
            {" "}
            <button
              className={clsx("btn btn-block", styles.closeButton)}
              onClick={() => setModal(false)}
            >
              <i className="bx bx-chart" />
              Close
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export const NftUnstakeModal = ({
  nfts,
  onUnstake,
  setModal,
  isOpen,
  nftCollection,
}) => {
  const [selectedGenesis, setSelectedGenesis] = useState([])
  const onSelectGenesis = useCallback(
    nft => {
      let _selectedGenesis = [...selectedGenesis]
      let _nftIndex = _selectedGenesis.findIndex(el => {
        return el.nonce == nft.nonce
      })
      if (_nftIndex != -1) {
        _selectedGenesis.splice(_nftIndex, 1)
      } else _selectedGenesis.push(nft)
      setSelectedGenesis(_selectedGenesis)
    },
    [selectedGenesis]
  )

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setModal(false)
      }}
      centered
      className="genesis-select-modal"
    >
      <ModalHeader>
        {" "}
        <Col xs="12">
          <h3>{nftCollection} NFTs</h3>
        </Col>
      </ModalHeader>
      <ModalBody className="genesis-modal-body">
        <Row className="my-nfts">
          {nfts.length > 0 ? (
            nfts.map((nft, index) => {
              return (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="nft-card mb-1"
                  key={index}
                >
                  <img src={nft.image} alt="" />
                  <h6 className="clan">{nft.clan}</h6>
                  <h6 className="identifier">{nft.identifier}</h6>
                  <button
                    className={`stake ${
                      selectedGenesis.findIndex(el => el.nonce == nft.nonce) ==
                      -1
                        ? ""
                        : "selected"
                    }`}
                    onClick={() => onSelectGenesis(nft)}
                  >
                    <i className="bx bx-chart" />
                    Select
                  </button>
                </Col>
              )
            })
          ) : (
            <Col xs="12">
              <h4 className="text-center my-3">You don't have Staked NFT</h4>
            </Col>
          )}
        </Row>
        <Row
          className="genesis-modal-footer"
          style={{ justifyContent: "center" }}
        >
          <Col
            xs="4"
            className="stake-button"
            style={{ justifyContent: "center", display: "flex" }}
          >
            {" "}
            <button
              className={clsx("btn btn-block", styles.stakeButton)}
              onClick={() => {
                setModal(false)
                onUnstake(selectedGenesis)
              }}
            >
              <i className="bx bx-chart" />
              Unstake
            </button>
          </Col>
          <Col xs="4" className="close-button">
            {" "}
            <button
              className={clsx("btn btn-block", styles.closeButton)}
              onClick={() => setModal(false)}
            >
              <i className="bx bx-chart" />
              Close
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
