import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks"
import BigNumber from "bignumber.js/bignumber"
import { convertWeiToEsdt, shortenEsdt } from "utils"
import { BigZero, ECPX_TOKEN_ID } from "helpers/constants"
import axios from "axios"
import { ElrondApiUrl } from "configs"

const Balance = () => {
  const [ecpxBalance, setEcpxBalance] = useState(BigZero)

  const coin = useSelector(state => state.Coin)
  const { coins } = useSelector(state => state.Coin)
  const {
    account: { address: userAddress, balance: userBalance },
  } = useGetAccountInfo()
  const [ecpxPrice, setEcpxPrice] = useState(0)

  useEffect(() => {
    if (!userAddress) return
    ;(async () => {
      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/accounts/${userAddress}/tokens/${ECPX_TOKEN_ID}`
        )

        setEcpxBalance(data?.balance)
      } catch {}
    })()
  }, [userAddress])

  async function triggerGetEcpxPrice() {
    try {
      const { data } = await axios.get(
        "https://apiv2.e-compass.io/price/last?exchange=onedex&basePair=ECPX&quotePair=USDC"
      )
      if (data && data.price) {
        const _ecpxPrice = Number(data.price)
        setEcpxPrice(_ecpxPrice)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    triggerGetEcpxPrice()
    const interval = setInterval(() => {
      triggerGetEcpxPrice()
    }, 60_000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="balance-panel">
      <h4>My Balance</h4>
      <span>
        {userAddress
          ? shortenEsdt(convertWeiToEsdt(new BigNumber(userBalance)), 2)
          : 0}{" "}
        $EGLD
      </span>
      <p>
        $
        {userAddress
          ? shortenEsdt(
              convertWeiToEsdt(
                new BigNumber(userBalance).multipliedBy(coin.egld)
              )
            )
          : 0}
      </p>

      <div />

      <span>
        {shortenEsdt(convertWeiToEsdt(new BigNumber(ecpxBalance)), 2)} $ECPX
      </span>
      <p>
        $
        {shortenEsdt(
          convertWeiToEsdt(new BigNumber(ecpxBalance)).multipliedBy(ecpxPrice),
          5
        )}
      </p>
    </div>
  )
}

export default Balance
