import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js/bignumber';
import clsx from 'clsx';
import { BigZero, coinNames } from 'helpers/constants';
import {
    convertBigNumberValueToLocalString,
    convertWeiToEsdt,
    getMaxAmount,
    getSymbol,
    getTokenImg,
    handleTokenImgError,
    shortenEsdt,
} from 'utils';
import styles from './styles.module.scss';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { CustomInput } from '../CustomInput';
import { DropdownList } from 'react-widgets';
import 'react-widgets/styles.css';
import './style.scss';

export const PaymentPanel = ({
    title,
    tokenId,
    amount,
    setAmount,
    onClick,
    setSwapType,
    isFirstToken,
    isPayment = false,
    tokens,
    setToken,
    pairTokenInfos,
}) => {
    const [alert, setAlert] = useState(false);

    const { hasPendingTransactions } = useGetPendingTransactions();

    useEffect(() => {
        if (!isFirstToken || BigZero.isEqualTo(amount) || amount.lte(pairTokenInfos[tokenId] ? pairTokenInfos[tokenId].balance : 0)) {
            setAlert(false);
        } else {
            setAlert(true);
        }
    }, [amount]);

    useEffect(() => {
        if (!hasPendingTransactions) return;

        setAmount(BigZero);
    }, [hasPendingTransactions]);

    return (
        <div style={{ flex: 1 }}>
            <div style={{ color: 'white' }}>
                {title}
            </div>
            <div className={styles.tokenPanel}>
                {/* <div
                    onClick={onClick}
                    className={clsx(styles.tokenSelector, 'd-flex align-items-center pointer')}
                >
                    {
                        tokenId ? (
                            <>
                                <img width={28} src={getTokenImg(tokenId)} onError={handleTokenImgError} alt='first token' />
                                <span className={styles.tokenName}>{getSymbol(tokenId)}</span>
                            </>
                        ) : (
                            <span className={clsx(styles.tokenName, 'ml-0')}>SELECT</span>
                        )
                    }
                    <i className='fa-solid fa-angle-down text-white' />
                </div> */}
                <div className={clsx(styles.tokenSelector)}>
                    <DropdownList
                        className='payment-tokens-list'
                        data={tokens}
                        renderListItem={({ item }) => (
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex'>
                                    <img width={28} src={getTokenImg(item)} onError={handleTokenImgError} alt='first token' />
                                    <span className={styles.tokenName}>{getSymbol(item)}</span>
                                </div>
                                <div className='align-self-center'>
                                    <div className={styles.tokenInfo}>{shortenEsdt(pairTokenInfos[item] ? pairTokenInfos[item].balance : 0)}</div>
                                </div>
                            </div>
                        )}
                        renderValue={() => (
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <img width={22} src={getTokenImg(tokenId)} onError={handleTokenImgError} alt='first token' />
                                    <span className={styles.tokenName}>{getSymbol(tokenId)}</span>
                                </div>
                            </div>
                        )}
                        value={tokenId}
                        onChange={value => setToken(value)}
                    />
                </div>
                <CustomInput
                    className={styles.input}
                    placeholder='Amount'
                    value={amount}
                    noMax={!isFirstToken}
                    isPayment={isPayment}
                    maxValue={getMaxAmount(pairTokenInfos && pairTokenInfos[tokenId] ? pairTokenInfos[tokenId].balance : 0)}
                    // maxValue={pairTokenInfos[tokenId]?.balance}
                    setAmount={(newAmount) => {
                        setAmount(newAmount);
                    }}
                    // setSwapType={setSwapType}
                    isDisabled={!isFirstToken}
                />
            </div>
            <div className='d-flex justify-content-end w-100 px-1'>
                <span style={{ color: '#858585', fontSize: '.8rem' }}>
                    Balance: {pairTokenInfos && pairTokenInfos[tokenId] ? convertBigNumberValueToLocalString(pairTokenInfos[tokenId].balance) : '0.000'}
                </span>
            </div>
        </div>
    );
};
