import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import Coin from "./coin/reducer";
import Nft from "./nft/reducer";

const rootReducer = combineReducers({
  Layout,
  Coin,
  Nft,
});

export default rootReducer;
