import { BigZero } from "helpers/constants"
import { UPDATE_COIN_INFO } from "./actionTypes"

const INIT_STATE = {
  egld: 0,
  coins: [],
  availableBalance: 0,
}

const Coin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_COIN_INFO:
      return {
        ...state,
        egld: action.payload.data.egldPrice,
        coins: action.payload.data.assets,
        availableBalance: action.payload.data.availableBalance
      }
    default:
      return state
  }
}

export default Coin
