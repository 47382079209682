import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export const CustomButton = (
    {
        className,
        onClick,
        children
    }
) => {
    return (
        <button
            className={clsx(styles.button, className)}
            onClick={onClick}
        >
            {children}
        </button>
    );
};
