import BigNumber from "bignumber.js/bignumber"
import { isDev } from "configs"

export const BigZero = new BigNumber(0)
export const WEGLD = isDev ? "WEGLD-d7c6bb" : "WEGLD-bd4d79"
export const ECPX_TOKEN_ID = isDev ? "ECPX-7b8e20" : "ECPX-5cbfeb"
export const ONE = isDev ? "ONE-1873d4" : "ONE-f9954f"

export const CLAN_INFO = [
  ["The Chieftains", 1, 200, 0, 81, 4005],
  ["Air Force", 201, 400, 0, 343, 4006],
  ["Bounty Hunters", 401, 600, 0, 579, 4007],
  ["Demonic Resurrectors", 601, 800, 0, 792, 4008],
  ["Epic Demons", 801, 1000, 4001, 986, 4009],
  ["Gilded Syndicate", 1001, 1200, 0, 1050, 4010],
  ["The Vandals", 1201, 1400, 0, 1240, 4021],
  ["Legion of Doom", 1401, 1600, 0, 1454, 4011],
  ["Lunatic Clowns", 1601, 1800, 0, 1607, 4012],
  ["Nomads Rising", 1801, 2000, 0, 1841, 4013],
  ["Savannah Wizards", 2001, 2200, 4002, 2090, 4014],
  ["Shadow Fighters", 2201, 2400, 0, 2217, 4015],
  ["Silent Assassins", 2401, 2600, 0, 2449, 4016],
  ["Supreme Squad", 2601, 2800, 0, 2647, 4017],
  ["Tech Destroyers", 2801, 3000, 0, 2866, 4018],
  ["The Reptiles", 3001, 3200, 4003, 3015, 4020],
  ["Thunder Clan", 3201, 3400, 0, 3313, 4022],
  ["The Purples", 3401, 3600, 0, 3461, 4019],
  ["Valhalla Warriors", 3601, 3800, 4004, 3615, 4023],
  ["Zombie Unit", 3801, 4000, 0, 3916, 4024],
]

export const GenesisCollection = isDev ? "HUNGRYC9-0694ca" : "MAXWELLS-a03c6e"
export const XCollection = isDev ? "HUNGRYC9-0694ca" : "ECPX-cdbb2d"
export const PunkzCollection = isDev? "": "RPZ-b7ead4"
export const coinNames = isDev
  ? [
      "EGLD",
      "WEGLD-d7c6bb",
      "USDC-8d4068",
      "USDT-188935",
      "BUSD-632f7d",
      "ONE-1873d4",
      "LEGLD-3e7182",
      "RARE-71c1b6",
      "MEX-dc289c",
    ]
  : [
      "EGLD",
      "WEGLD-bd4d79",
      "USDC-c76f1f",
      "USDT-f8c08c",
      "BUSD-40b57e",
      "ONE-f9954f",
      "LEGLD-d74da9",
      "RARE-99e8b0",
      "MEX-455c57",
      "WBTC-5349b3",
      "WETH-b4ca29",
      "HODL-b8bd81",
      "KRO-df97ec",
    ]

export const loadingDelayTime = 500
