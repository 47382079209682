import React, { useState, useEffect, useCallback } from "react"

import {
  Row,
  Col,
  Modal,
} from "reactstrap"
import Balance from "components/Common/Balance"
import ClanPool from "assets/ecpx/images/nft-staking/ClanPool.png"
import { SlPresent } from "react-icons/sl"
import { CiBitcoin } from "react-icons/ci"
import { RiHandCoinLine } from "react-icons/ri"
import { useSelector, useDispatch } from "react-redux"
import styles from "./styles.module.scss"
import {
  TokenPayment,
  BigUIntValue,
  U32Value,
  U64Value,
  Account,
  Address,
  SmartContract,
  BytesValue,
  ContractFunction,
  AddressValue,
  ResultsParser,
  OptionalValue,
  TokenIdentifierValue,
  Interaction,
  VariadicValue,
  List,
  ListType,
  Type,
} from "@multiversx/sdk-core/out"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out"
import {
  contracts,
  ECPX_TOKEN_ID,
  ElrondApiUrl,
  ElrondGatewayUrl,
} from "configs"
import { RiNftFill } from "react-icons/ri"
import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks"
import { sendTransactions } from "@multiversx/sdk-dapp/services"

import { admins, owner } from "configs"
import { BigZero, CLAN_INFO, GenesisCollection } from "helpers/constants"
import {
  convertWeiToEsdt,
  convertEsdtToWei,
  shortenEsdt,
  nonceToGenesis,
  nonceToECPX,
} from "utils"
import { toast } from "react-toastify"
import axios from "axios"
import { getNftInfo } from "store/actions"
import {
  NFTStakeModal,
  NftUnstakeModal,
} from "components/Nftstaking/NFTSelectModal"
import clsx from "clsx"


const ClanPools = () => {
  document.title = "NFT Staking | ECPx HUB"
  const [stakingInfo, setStakingInfo] = useState(undefined)
  const [userStakingInfo, setUserStakingInfo] = useState(undefined)
  const [poolStakingInfo, setPoolStakingInfo] = useState(undefined)
  const [poolUserStakingInfo, setPoolUserStakingInfo] = useState(undefined)

  const [stakedGenesis, setStakedGenesis] = useState([])
  const [stakedX, setStakedX] = useState([])
  const [stakedClan, setStakedClan] = useState([])
  const [stakedKing, setStakedKing] = useState([])
  const [currentPoolStakedTokens, setCurrentPoolStakedTokens] = useState([])

  const [showClaimModal, setShowClaimModal] = useState(false)
  const [poolStakeModal, setPoolStakeModal] = useState(false)
  const [poolUnstakeModal, setPoolUnstakeModal] = useState(false)
  const [clanType, setClanType] = useState("")
  const [currentPoolId, setCurrentPoolId] = useState(undefined)
  const [poolManageModal, setPoolManageModal] = useState(false)
  const [poolManageMode, setPoolManageMode] = useState("deposit")
  const [poolAmount, setPoolAmount] = useState(0)
  const [poolRewardModal, setPoolRewardModal] = useState(false)
  const [basicEgld, setBasicEgld] = useState(0)
  const [basicEcpx, setBasicEcpx] = useState(0)
  const [poolEgldAmount, setPoolEgldAmount] = useState(0)
  const [poolEcpxAmount, setPoolEcpxAmount] = useState(0)

  const { genesis, x, king, emblem } = useSelector(state => state.Nft)

  const nftStakingContractAddress = new Address(contracts.NftStaking.address)
  const nftStakingContract = new SmartContract({
    address: nftStakingContractAddress,
    abi: contracts.NftStaking.abi,
  })

  const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, {
    timeout: 30000,
  })

  const { address: userAddress } = useGetAccountInfo()
  const { hasPendingTransactions } = useGetPendingTransactions()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userAddress) return

    dispatch(getNftInfo(userAddress))
  }, [userAddress, hasPendingTransactions])

  useEffect(() => {
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getStakingInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        setStakingInfo(config)

        setClanType(config.clan_type.toString())
      } catch (error) {
        console.log(error)
      }
    })()
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getPoolsInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getPoolsInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        console.log(
          resultsParser.parseQueryResponse(queryResponse, endpointDefinition)
        )
        const poolsInfo = firstValue?.valueOf()
        setPoolStakingInfo(poolsInfo)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [hasPendingTransactions])

  useEffect(() => {
    if (!userAddress) return
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getUserStakingInfo"),
          args: [new AddressValue(new Address(userAddress))],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getUserStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        const staked_genesis_nonces = config.genesis_user_staked_tokens
        const staked_genesis_tokens = []
        const staked_genesis_token_ids = []

        for (const nonce of staked_genesis_nonces) {
          const token_id = nonceToGenesis(nonce)

          staked_genesis_token_ids.push(token_id)
        }

        if (staked_genesis_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_genesis_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              staked_genesis_tokens.push({
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_x_nonces = config.x_user_staked_tokens
        const staked_x_tokens = []
        const staked_x_token_ids = []

        for (const nonce of staked_x_nonces) {
          const token_id = nonceToECPX(nonce)

          staked_x_token_ids.push(token_id)
        }

        if (staked_x_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_x_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              const metadata_no = Number(nft.name.split("#")[1])
              let clan = ""

              for (const info of CLAN_INFO) {
                if (
                  (metadata_no >= info[1] && metadata_no <= info[2]) ||
                  metadata_no == info[3] ||
                  metadata_no == info[4]
                ) {
                  clan = info[0]

                  break
                }
              }

              staked_x_tokens.push({
                clan,
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_king_nonces = config.king_user_staked_tokens
        const staked_king_tokens = []
        const staked_king_token_ids = []

        for (const nonce of staked_king_nonces) {
          const token_id = nonceToECPX(nonce)

          staked_king_token_ids.push(token_id)
        }

        if (staked_king_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_king_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              const metadata_no = Number(nft.name.split("#")[1])
              let clan = ""

              for (const info of CLAN_INFO) {
                if (
                  (metadata_no >= info[1] && metadata_no <= info[2]) ||
                  metadata_no == info[3] ||
                  metadata_no == info[4]
                ) {
                  clan = info[0]

                  break
                }
              }

              staked_king_tokens.push({
                clan,
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_clan_nonces = config.clan_user_staked_tokens
        const staked_clan_tokens = []
        const staked_clan_token_ids = []

        for (const nonce of staked_clan_nonces) {
          const token_id = nonceToECPX(nonce)

          staked_clan_token_ids.push(token_id)
        }

        if (staked_clan_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_clan_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              const metadata_no = Number(nft.name.split("#")[1])
              let clan = ""

              for (const info of CLAN_INFO) {
                if (
                  (metadata_no >= info[1] && metadata_no <= info[2]) ||
                  metadata_no == info[3] ||
                  metadata_no == info[4]
                ) {
                  clan = info[0]

                  break
                }
              }

              staked_clan_tokens.push({
                clan,
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        setUserStakingInfo(config)
        setStakedGenesis(staked_genesis_tokens)
        setStakedX(staked_x_tokens)
        setStakedKing(staked_king_tokens)
        setStakedClan(staked_clan_tokens)
      } catch (error) {
        console.log(error)
      }
    })()
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getPoolStakingInfo"),
          args: [new AddressValue(new Address(userAddress))],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getPoolStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        console.log("pool user info", config)

        setPoolUserStakingInfo(config)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userAddress, hasPendingTransactions])

  const genesisStakeAll = () => {
    if (!userAddress || !genesis.length) {
      return
    }

    toast.info("Staking all Genesis NFTs is processing")
    ;(async () => {
      try {
        const args = []

        for (const nft of genesis) {
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        const tx = nftStakingContract.methodsExplicit
          .genesisStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisUnstakeAll = () => {
    if (!userAddress || !stakedGenesis.length) {
      return
    }

    toast.info("Unstaking all staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedGenesis.length < 50 ? 200 + stakedGenesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisSelectiveStake = selectedGenesis => {
    if (!userAddress || !genesis.length) {
      return
    }

    toast.info("Staking selected Genesis NFTs is processing")
    ;(async () => {
      try {
        const args = []

        for (const nft of selectedGenesis) {
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        const tx = nftStakingContract.methodsExplicit
          .genesisStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisSelectiveUnstake = selectedGenesis => {
    if (!userAddress || !stakedGenesis.length) {
      return
    }

    let args = new List(
      new Type("u64"),
      selectedGenesis.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedGenesis.length < 50 ? 200 + stakedGenesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisStake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Staking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisStake([])
          .withSingleESDTNFTTransfer(
            TokenPayment.nonFungible(nft.collection, nft.nonce),
            new Address(userAddress)
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const poolStake = selectedGenesis => {
    if (!userAddress || !x.filter(nft => nft.clan != clanType).length) {
      return
    }

    toast.info("Staking selected Genesis NFTs is processing")
    ;(async () => {
      try {
        const args = []

        for (const nft of selectedGenesis) {
          const textDecoder = new TextDecoder()
          const poolName = textDecoder.decode(
            poolStakingInfo.clan_infos[currentPoolId - 1]
          )
          if (nft.clan != poolName) {
            toast.error("Sorry, you selected different member of clan nft.")
            return
          }
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        const tx = nftStakingContract.methodsExplicit
          .poolStake([new BigUIntValue(currentPoolId)])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const poolUnstake = selectedNFTs => {
    if (!userAddress || !selectedNFTs.length) {
      return
    }

    let args = new List(
      new Type("u64"),
      selectedNFTs.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .poolUnstake([new BigUIntValue(currentPoolId), args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            selectedNFTs.length < 50 ? 200 + selectedNFTs.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const checkClaim = () => {
    if (!userAddress) {
      return
    }

    if (userStakingInfo) {
      if (
        BigZero.isEqualTo(userStakingInfo.user_egld_reward) &&
        BigZero.isEqualTo(userStakingInfo.user_ecpx_reward)
      ) {
        toast.info("No enough rewards to claim")

        return
      }

      if (!userStakingInfo.egld_enough_deposit) {
        toast.warn("No enough deposited EGLD to claim")

        return
      }

      if (!userStakingInfo.ecpx_enough_deposit) {
        toast.warn("No enough deposited ECPX to claim")

        return
      }

      setShowClaimModal(true)
    }
  }

  const claim = type => {
    if (!userAddress) {
      return
    }

    toast.info("Claiming Reward is processing")
    setShowClaimModal(false)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .claim([new U64Value(type)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xStake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Staking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withSingleESDTNFTTransfer(
            TokenPayment.nonFungible(nft.collection, nft.nonce),
            new Address(userAddress)
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xSelectiveUnStake = selectedX => {
    if (!userAddress) {
      return
    }

    let args = new List(
      new Type("u64"),
      selectedX.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingStake = nft => {
    if (!userAddress) {
      return
    }

    let emblem_nonce = 0

    for (const emblem_nft of emblem) {
      if (emblem_nft.clan == nft.clan) {
        emblem_nonce = emblem_nft.nonce

        break
      }
    }

    if (!emblem_nonce) {
      toast.warn(`You don't have emblem to stake King of ${nft.clan}`)

      return
    }

    toast.info(`Staking King of ${nft.clan} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(
            [
              TokenPayment.nonFungible(nft.collection, nft.nonce),
              TokenPayment.nonFungible(nft.collection, emblem_nonce),
            ],
            new Address(userAddress)
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .kingUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const clanUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .clanUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xStakeAll = () => {
    if (!userAddress || !x.length) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of x) {
          if (nft.clan !== clanType) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
          }
        }

        if (!args.length) {
          toast.info("No ECPX NFT to stake")

          return
        }

        toast.info("Staking all ECPX NFTs is processing")

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xUnstakeAll = () => {
    if (!userAddress || !stakedX.length) {
      return
    }

    toast.info("Unstaking all ECPX NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedX.length < 50 ? 200 + stakedX.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xSelectiveStake = selectedX => {
    if (!userAddress || !x.length) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of selectedX) {
          if (nft.clan !== clanType) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
          }
        }

        console.log(args)
        if (!args.length) {
          toast.info("No ECPX NFT to stake")

          return
        }

        toast.info("Staking all ECPX NFTs is processing")

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingStakeAll = () => {
    if (!userAddress || !king.length) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of king) {
          let emblem_nonce = 0

          for (const emblem_nft of emblem) {
            if (emblem_nft.clan == nft.clan) {
              emblem_nonce = emblem_nft.nonce

              break
            }
          }

          if (emblem_nonce) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
            args.push(TokenPayment.nonFungible(nft.collection, emblem_nonce))
          }
        }

        if (!args.length) {
          toast.warn("No King of Clan with Emblem")

          return
        }

        toast.info(`Staking all Kings of Clan is processing`)

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingUnstakeAll = () => {
    if (!userAddress || !stakedKing.length) {
      return
    }

    toast.info(`Unstaking all Kings of Clan is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .kingUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedKing.length < 50 ? 200 + stakedKing.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingSelectiveStake = selectedKings => {
    if (!userAddress || !king.length) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of selectedKings) {
          let emblem_nonce = 0

          for (const emblem_nft of emblem) {
            if (emblem_nft.clan == nft.clan) {
              emblem_nonce = emblem_nft.nonce

              break
            }
          }

          if (emblem_nonce) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
            args.push(TokenPayment.nonFungible(nft.collection, emblem_nonce))
          }
        }

        if (!args.length) {
          toast.warn("No King of Clan with Emblem")

          return
        }

        toast.info(`Staking all Kings of Clan is processing`)

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingSelectiveUnstake = selectedKings => {
    if (!userAddress || !stakedKing.length) {
      return
    }
    let args = new List(
      new Type("u64"),
      selectedKings.map(el => new U64Value(el.nonce))
    )
    toast.info(`Unstaking all Kings of Clan is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .kingUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedKing.length < 50 ? 200 + stakedKing.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const clanStakeAll = () => {
    if (!userAddress || !x.length || !clanType) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of x) {
          if (nft.clan == clanType) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
          }
        }

        if (!args.length) {
          toast.info(`No ${clanType} NFTs to stake`)

          return
        }

        toast.info(`Staking all ${clanType} NFTs is processing`)

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const clanUnstakeAll = () => {
    if (!userAddress || !stakedClan.length) {
      return
    }

    toast.info(`Unstaking all ${clanType} NFTs is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .clanUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedClan.length < 50 ? 200 + stakedClan.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onCreatePool = nft => {
    if (!(king.length > 0)) {
      // toast.error("You don't have King NFT.");
      // return;
    }
    ;(async () => {
      try {
        const args = []
        args.push(new TokenIdentifierValue(nft.collection))
        for (const clan of CLAN_INFO) {
          if (nft.clan == clan[0]) {
            console.log(clan[0])
            args.push(
              ...[
                BytesValue.fromUTF8(clan[0]),
                new U64Value(clan[1]),
                new U64Value(clan[2]),
                new U64Value(clan[3]),
                new U64Value(clan[4]),
                new U64Value(clan[5]),
              ]
            )
          }
        }
        console.log(args)
        if (!(args.length > 0)) {
          toast.error("You don't have King NFT.")
          return
        }
        const tx = nftStakingContract.methodsExplicit
          .createPool(args)
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onEgldDeposit = pool_id => {
    toast.info("Processing Deposit transaction")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_deposit([new BigUIntValue(pool_id)])
          .withValue(convertEsdtToWei(poolEgldAmount))
          .buildTransaction()

        const rawTx = {
          value: convertEsdtToWei(poolEgldAmount).toString(),
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onEcpxDeposit = pool_id => {
    toast.info("Processing Deposit transaction")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_deposit([new BigUIntValue(pool_id)])
          .withSingleESDTTransfer(
            TokenPayment.fungibleFromBigInteger(
              ECPX_TOKEN_ID,
              convertEsdtToWei(poolEcpxAmount),
              18
            )
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onEgldWithDraw = pool_id => {
    toast.info("Processing Withdraw transaction")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_withdraw([
            TokenIdentifierValue.egld(),
            new BigUIntValue(convertEsdtToWei(poolEgldAmount)),
            new BigUIntValue(pool_id),
          ])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_withdraw([
            new TokenIdentifierValue(ECPX_TOKEN_ID),
            new BigUIntValue(convertEsdtToWei(poolEcpxAmount)),
            new BigUIntValue(pool_id),
          ])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onEcpxWithDraw = pool_id => {
    toast.info("Processing Withdraw transaction")
  
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_withdraw([
            new TokenIdentifierValue(ECPX_TOKEN_ID),
            new BigUIntValue(convertEsdtToWei(poolEcpxAmount)),
            new BigUIntValue(pool_id),
          ])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onClaim = pool_id => {
    if (!userAddress) {
      return
    }

    toast.info("Claiming Reward is processing")
    setShowClaimModal(false)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_claim([new BigUIntValue(pool_id)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }
  const onPoolUpdateBasicReward = pool_id => {
    ;(async () => {
      console.log(pool_id, new BigUIntValue(convertEsdtToWei(0.1)))
      const tx = nftStakingContract.methodsExplicit
        .updatePoolBasicReward([
          new BigUIntValue(pool_id),
          new BigUIntValue(convertEsdtToWei(basicEgld)),
          new BigUIntValue(convertEsdtToWei(basicEcpx)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="nft-staking">
          <Row>
            <Col xl="9">
              <Row>
                <h2 className="text-center">Clan Stakings</h2>
                <p className="text-center">
                  To create a special staking pool for all warriors, you need to
                  own the King and Emblem of the respective clan.
                </p>
              </Row>
              <div className="pools-container">
                {poolStakingInfo ? (
                  poolStakingInfo.pool_ids.map((el, index) => {
                    let pool_id = Number(el)
                    return (
                      <Col md="12" className="mb-5" key={index}>
                        <div className="nft-staking-pool">
                          <img src={ClanPool} alt="" width={60} height={60} />
                          <h3>
                            {poolStakingInfo.clan_infos[index].toString()}
                          </h3>
                          <>
                            <Row style={{ width: "100%" }}>
                              <Col xs={6}>
                                <p className="pool">
                                  <RiHandCoinLine />
                                  EGLD Rewards:
                                </p>
                                <p className="pool">
                                  <RiHandCoinLine />
                                  ECPX Rewards:
                                </p>
                                <p className="pool">
                                  <RiNftFill /> Staked NFTs:
                                </p>
                                <p className="pool">
                                  <SlPresent /> Daily EGLD:
                                </p>
                                <p className="pool">
                                  <SlPresent /> Daily ECPX:
                                </p>
                              </Col>
                              <Col xs={6}>
                                <span className="item">
                                  {shortenEsdt(
                                    convertWeiToEsdt(
                                      poolStakingInfo.egld_rewards[index]
                                    )
                                  )}{" "}
                                  $EGLD{" "}
                                </span>
                                <span className="item">
                                  {shortenEsdt(
                                    convertWeiToEsdt(
                                      poolStakingInfo.ecpx_rewards[index]
                                    )
                                  )}
                                  $ECPX
                                </span>
                                <span className="item">
                                  {poolStakingInfo.staked_counts[
                                    index
                                  ].toNumber()}
                                </span>
                                <span className="item">
                                  {shortenEsdt(
                                    convertWeiToEsdt(
                                      poolStakingInfo.basic_egld_rewards[index]
                                    )
                                  )}
                                  $EGLD
                                </span>
                                <span className="item">
                                  {shortenEsdt(
                                    convertWeiToEsdt(
                                      poolStakingInfo.basic_ecpx_rewards[index]
                                    )
                                  )}
                                  $ECPX
                                </span>
                              </Col>
                            </Row>
                            <Row style={{ width: "100%" }}>
                              <Col xs={6}>
                                <p>
                                  <CiBitcoin /> Claimable EGLD:
                                </p>
                                <p>
                                  <CiBitcoin /> Claimable ECPX:
                                </p>
                                <p>
                                  <RiNftFill /> Your Staked NFTs:
                                </p>
                              </Col>
                              <Col xs={6}>
                                <span className="item">
                                  {" "}
                                  {poolUserStakingInfo
                                    ? shortenEsdt(
                                        convertWeiToEsdt(
                                          poolUserStakingInfo
                                            .pool_user_egld_reward[index]
                                        )
                                      )
                                    : 0}
                                  $EGLD
                                </span>
                                <span className="item">
                                {poolUserStakingInfo
                                    ? shortenEsdt(
                                        convertWeiToEsdt(
                                          poolUserStakingInfo
                                            .pool_user_ecpx_reward[index]
                                        )
                                      )
                                    : 0}
                                  $ECPX
                                </span>
                                <span className="item">
                                  {" "}
                                  {poolUserStakingInfo
                                    ? poolUserStakingInfo
                                        .pool_user_staked_tokens[index].length
                                    : 0}
                                </span>
                              </Col>
                            </Row>
                            <div
                              className="d-flex flex-column justify-content-around w-100"
                              style={{ marginTop: "20px"}}
                            >
                              <Row style={{margin:"auto", width:"100%"}}>
                                <button
                                   className={clsx(
                                    "btn btn-outline",
                                    styles.stakeButton
                                  )}
                                  onClick={() => {
                                    setPoolStakeModal(true)
                                    setCurrentPoolId(pool_id)
                                  }}
                                >
                                  <i className="bx bx-chart" />
                                  Stake
                                </button>

                                <button
                                     className={clsx(
                                      "btn btn-outline",
                                      styles.closeButton
                                    )}
                                  onClick={() => {
                                    setPoolUnstakeModal(true)
                                    setCurrentPoolId(pool_id)
                                    ;(async () => {
                                      const nonces =
                                        poolUserStakingInfo
                                          .pool_user_staked_tokens[index]
                                      const tokens = []
                                      const token_ids = []

                                      for (const nonce of nonces) {
                                        const token_id = nonceToECPX(nonce)

                                        token_ids.push(token_id)
                                      }
                                      console.log(token_ids)

                                      if (token_ids.length) {
                                        try {
                                          const { data } = await axios.get(
                                            `${ElrondApiUrl}/nfts?size=200&identifiers=${token_ids.join(
                                              ","
                                            )}`
                                          )
                                          for (const nft of data) {
                                            tokens.push({
                                              collection: nft.collection,
                                              identifier: nft.identifier,
                                              nonce: nft.nonce,
                                              image: nft.url,
                                            })
                                          }
                                        } catch (error) {
                                          console.log(error)
                                        }
                                      }

                                      setCurrentPoolStakedTokens(tokens)
                                    })()
                                  }}
                                >
                                  <i className="bx bx-chart" />
                                  Unstake
                                </button>

                                <button
                                  className={clsx(
                                    "btn btn-outline",
                                    styles.claim
                                  )}
                                  onClick={() => onClaim(pool_id)}
                                  disabled={
                                    !(
                                      poolUserStakingInfo &&
                                      poolUserStakingInfo.pool_claimable[index]
                                    )
                                  }
                                >
                                  <i className="bx bx-chart" />
                                  Claim
                                </button>
                              </Row>
                              <Row style={{margin:"auto", width:"100%"}}>
                                <button
                                  className={clsx(
                                    "btn btn-outline",
                                    styles.deposit
                                  )}
                                  onClick={() => {
                                    setCurrentPoolId(pool_id)
                                    setPoolManageMode("deposit")
                                    setPoolManageModal(true)
                                  }}
                                >
                                  <i className="bx bx-chart" />
                                  Deposit
                                </button>
                                <button
                                  className={clsx(
                                    "btn btn-outline",
                                    styles.withdraw
                                  )}
                                  onClick={() => {
                                    setCurrentPoolId(pool_id)
                                    setPoolManageMode("withdraw")
                                    setPoolManageModal(true)
                                  }}
                                >
                                  <i className="bx bx-chart" />
                                  Withdraw
                                </button>
                                <button
                                  className={clsx(
                                    "btn btn-outline",
                                    styles.updateReward
                                  )}
                                  onClick={() => {
                                    setCurrentPoolId(pool_id)
                                    setPoolRewardModal(true)
                                  }}
                                >
                                  <i className="bx bx-chart" />
                                  Rewards
                                </button>
                              </Row>
                            </div>
                          </>
                        </div>
                      </Col>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
            </Col>

            <Col xl="3">
              <Row>
                <Col xs="12" className="mb-3">
                  <Balance />
                </Col>
                <Col xs="12" className="mb-3">
                  {king.length > 0 ? (
                    king.map((nft, index) => (
                      <div
                        key={index}
                        className="d-flex items-center flex-column"
                      >
                        <img src={nft.image} style={{ width: "100%" }} />
                        <button
                          className={clsx("btn btn-block", styles.stakeButton)}
                          onClick={() => {
                            onCreatePool(nft)
                          }}
                        >
                          Create Pool
                        </button>
                      </div>
                    ))
                  ) : (
                    <Col xs="12">
                      <h4 className="text-center my-3">
                        No King of Clans in your Wallet
                      </h4>
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <NFTStakeModal
        nfts={x.filter(nft => nft.clan != clanType)}
        onStake={poolStake}
        setModal={setPoolStakeModal}
        isOpen={poolStakeModal}
        nftCollection="Elrond Cyberpunks X King of Clan"
      />
      <NftUnstakeModal
        nfts={currentPoolStakedTokens}
        onUnstake={poolUnstake}
        setModal={setPoolUnstakeModal}
        isOpen={poolUnstakeModal}
        nftCollection="Elrond Cyberpunks X King of Clan"
      />
      <Modal
        isOpen={poolManageModal}
        toggle={() => {
          setPoolManageModal(false)
        }}
        style={{
          width: "fit-content",
          zIndex: 888,
        }}
        centered
      >
        <div
          className="d-flex"
          style={{
            margin: "10px 20px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>EGLD Amount:</span>
          <input
            className="form-control"
            type="text"
            style={{ width: "200px" }}
            defaultValue={poolEgldAmount}
            onChange={e => setPoolEgldAmount(e.target.value)}
          />{" "}
          <button
            className={clsx("btn btn-block", styles.stakeButton)}
            onClick={() => {
              if (poolManageMode == "deposit") {
                onEgldDeposit(currentPoolId)
              } else {
                onEgldWithDraw(currentPoolId)
              }
            }}
          >
            {poolManageMode == "deposit" ? "Deposit" : "Withdraw"}
          </button>{" "}
        </div>
        <div
          className="d-flex"
          style={{
            gap: "10px",
            alignItems: "center",
            margin: "10px 20px",
          }}
        >
          <span>ECPX Amount:</span>
          <input
            className="form-control"
            type="text"
            style={{ width: "200px" }}
            defaultValue={poolEcpxAmount}
            onChange={e => setPoolEcpxAmount(e.target.value)}
          />{" "}
          <button
            className={clsx("btn btn-block", styles.stakeButton)}
            onClick={() => {
              if (poolManageMode == "deposit") {
                onEcpxDeposit(currentPoolId)
              } else {
                onEcpxWithDraw(currentPoolId)
              }
            }}
          >
            {poolManageMode == "deposit" ? "Deposit" : "Withdraw"}
          </button>{" "}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 20px",
            gap: "5px",
          }}
        >
          {" "}
          <button
            className={clsx("btn btn-block", styles.closeButton)}
            onClick={() => setPoolManageModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={poolRewardModal}
        toggle={() => {
          setPoolRewardModal(false)
        }}
        style={{
          width: "fit-content",
        }}
        centered
      >
        <div
          className="d-flex"
          style={{
            margin: "10px 20px",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <span>EGLD Amount:</span>
          <input
            className="form-control"
            type="text"
            style={{ width: "200px" }}
            defaultValue={basicEgld}
            onChange={e => setBasicEgld(e.target.value)}
          />{" "}
        </div>
        <div
          className="d-flex"
          style={{
            margin: "10px 20px",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <span>ECPX Amount:</span>
          <input
            className="form-control"
            type="text"
            style={{ width: "200px" }}
            defaultValue={basicEcpx}
            onChange={e => setBasicEcpx(e.target.value)}
          />{" "}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 20px",
            gap: "5px",
          }}
        >
          {" "}
          <button
            className={clsx("btn btn-block", styles.stakeButton)}
            onClick={() => {
              onPoolUpdateBasicReward(currentPoolId)
              setPoolRewardModal(false)
            }}
          >
            OK
          </button>{" "}
          <button
            className={clsx("btn btn-block", styles.closeButton)}
            onClick={() => setPoolRewardModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ClanPools
