import BigNumber from 'bignumber.js/bignumber';
import UNKNOWN from 'assets/ecpx/images/coin/unknown.png';
export * from './time';
import imgEgld from 'assets/images/coins/EGLD.svg';
import { CLAN_INFO, GenesisCollection, PunkzCollection, XCollection } from 'helpers/constants';

import { isDev } from 'configs';

export const shortenAddress = (address, count = 6) => {
    const shortened = address.substring(0, count) + '...' + address.substring(address.length - count);

    return shortened;
};


export const convertWeiToEsdt = (v, decimals = 18) => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const result = value.dividedBy(new BigNumber(10).pow(decimals));

    return result;
};

export const convertEsdtToWei = (v, decimals = 18) => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const result = value.multipliedBy(new BigNumber(10).pow(decimals)).decimalPlaces(0, BigNumber.ROUND_FLOOR);

    return result;
};

export const shortenEsdt = (v, precision = 4) => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const number = +value.toFixed(precision, BigNumber.ROUND_FLOOR);

    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: precision }).format(number);

};

export const getTokenImg = (token_id) => {
    if(token_id == 'EGLD') {
        return imgEgld;
    } else {
        return isDev ? 
            `https://raw.githubusercontent.com/ElrondNetwork/assets/master/devnet/tokens/${token_id}/logo.png` :
            `https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/${token_id}/logo.png`;
    }
};

export const handleTokenImgError = (e) => {
    e.target.src = UNKNOWN;
};

export const nonceToGenesis = (nonce) => {
    const nonce_string = nonce.toString(16);

    return `${GenesisCollection}-${nonce_string.padStart(nonce_string.length % 2 ? nonce_string.length + 1: nonce_string.length, '0')}`
}

export const nonceToPunkz = (nonce) => {
    const nonce_string = nonce.toString(16);

    return `${PunkzCollection}-${nonce_string.padStart(nonce_string.length % 2 ? nonce_string.length + 1: nonce_string.length, '0')}`
}

export const nonceToECPX = (nonce) => {
    const nonce_string = nonce.toString(16);

    return `${XCollection}-${nonce_string.padStart(nonce_string.length % 2 ? nonce_string.length + 1: nonce_string.length, '0')}`
}

export const getEmblem = (target) => {
    let no = 0;

    for(const clan of CLAN_INFO) {
        if(clan[0] == target) {
            no = clan[5];
        }
    }

    if(!no) {
        return UNKNOWN;
    }
    
    return isDev ?
        `https://devnet-media.elrond.com/nfts/asset/bafybeienbjfrbqipipy525kc4fqerhsnum5lzl4vlmifbzuulflktilvfa/${no}.png` :
        `https://media.elrond.com/nfts/asset/bafybeienbjfrbqipipy525kc4fqerhsnum5lzl4vlmifbzuulflktilvfa/${no}.png`
}

const localDecimalSeparator = 0.1.toLocaleString().replace(/\d/g, '');
const bgFormatter = {
    decimalSeparator: localDecimalSeparator,
    groupSeparator: localDecimalSeparator == '.' ? ',' : '.',
    groupSize: 3
};

export const convertBigNumberValueToLocalString = (value, precision) => {
    let v = new BigNumber(value).toFormat(precision ? precision : 4, BigNumber.ROUND_FLOOR, bgFormatter);

    // remove trailing zeros
    if (v.search(localDecimalSeparator) >= 0) {
        v = v.replace(/\.?0+$/, '');
    }

    return v;
};

export const getMaxAmount = (v, threshold) => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    if (!threshold) {
        threshold = 0.0001;
    }

    if (value.gt(threshold)) {
        return value.minus(threshold);
    } else {
        return new BigNumber(0);
    }
};

export const getSymbol = (tokenIdentifier) => {
    try {
        const tokenSymbol = tokenIdentifier.split('-')[0].toUpperCase();

        return tokenSymbol;
    } catch (error) {
        return '';
    }
};