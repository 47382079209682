import React, { useEffect, useState } from "react"

import { Container, Row, Col, Progress } from "reactstrap"
import {
  TokenPayment,
  Address,
  SmartContract,
  ContractFunction,
  ResultsParser,
} from "@multiversx/sdk-core/out"
import { contracts, ElrondGatewayUrl } from "configs"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out"

import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks"

import UNKNOWN from "assets/ecpx/images/battle/unknown.png"

import Balance from "components/Common/Balance"
import CollectionInfo from "components/Common/CollectionInfo"
import { convertWeiToEsdt, getEmblem, shortenEsdt } from "utils"

import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { ECPX_TOKEN_ID } from "helpers/constants"

import BigNumber from "bignumber.js/bignumber"
import { sendTransactions } from "@multiversx/sdk-dapp/services"

const Battle = () => {
  const [refresh, setRefresh] = useState(false)

  const [battleInfo, setBattleInfo] = useState(undefined)

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)

  const battleContractAddress = new Address(contracts.BattleDao.address)
  const battleContract = new SmartContract({
    address: battleContractAddress,
    abi: contracts.BattleDao.abi,
  })

  const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, {
    timeout: 30000,
  })

  const { address: userAddress } = useGetAccountInfo()
  const { hasPendingTransactions } = useGetPendingTransactions()
  const { x, king } = useSelector(state => state.Nft)

  useEffect(() => {
    ;(async () => {
      try {
        const query = battleContract.createQuery({
          func: new ContractFunction("getBattleInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition = battleContract.getEndpoint("getBattleInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const info = firstValue?.valueOf()

        setBattleInfo(info)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [refresh, hasPendingTransactions])

  useEffect(() => {
    if (battleInfo) {
      if (battleInfo.status.name == "Created") {
        const wait_time =
          battleInfo.start_time.toNumber() - battleInfo.current_time.toNumber()

        setDays(Math.floor(wait_time / 60 / 60 / 24))
        setHours(Math.floor(wait_time / 60 / 60) % 24)
        setMinutes(Math.floor(wait_time / 60) % 60)
      } else if (battleInfo.status.name == "Started") {
        const wait_time =
          battleInfo.end_time.toNumber() - battleInfo.current_time.toNumber()

        setDays(Math.floor(wait_time / 60 / 60 / 24))
        setHours(Math.floor(wait_time / 60 / 60) % 24)
        setMinutes(Math.floor(wait_time / 60) % 60)
      } else {
        setDays(0)
        setHours(0)
        setMinutes(0)
      }
    }
  }, [battleInfo])

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefresh(!refresh)
    }, 30000)

    return () => clearTimeout(timer)
  }, [refresh])

  const onSupport = clan => {
    const args = []
    for (const nft of x) {
      if (nft.clan == clan) {
        args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
      }
    }

    for (const nft of king) {
      if (nft.clan == clan) {
        args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
      }
    }

    if (!args.length) {
      toast.info(`You don't have ${clan} to vote`)
      return
    }

    let count = args.length
    args.push(
      TokenPayment.fungibleFromBigInteger(
        ECPX_TOKEN_ID,
        new BigNumber(battleInfo.fee).multipliedBy(count),
        18
      )
    )
    ;(async () => {
      try {
        const tx = battleContract.methodsExplicit
          .voteBattle([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 60 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="battle-dao">
          <Row>
            <Col>
              {(!battleInfo ||
                (battleInfo && battleInfo.status.name == "Inactive")) && (
                <div className="battle-clans">
                  <div>
                    <img src={UNKNOWN} alt="" />
                  </div>

                  <div>
                    <h5 className="text-white m-1">VS</h5>
                  </div>

                  <div>
                    <img src={UNKNOWN} alt="" />
                  </div>

                  <div>
                    <h5 className="text-white m-1">VS</h5>
                  </div>

                  <div>
                    <img src={UNKNOWN} alt="" />
                  </div>
                </div>
              )}

              {battleInfo && battleInfo.status.name != "Inactive" && (
                <div className="battle-clans">
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{ height: "7rem" }}
                  >
                    <img
                      src={getEmblem(battleInfo.battle_clans[0].toString())}
                      alt={battleInfo.battle_clans[0].toString()}
                      style={{
                        width: "auto",
                        height: "60px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <h6 className="text-white text-center mt-1">
                      {battleInfo.battle_clans[0].toString()}
                    </h6>
                  </div>

                  {battleInfo.battle_clans.slice(1).map((clan, index) => {
                    return (
                      <React.Fragment key={`vs-${index}`}>
                        <div>
                          <h5 className="text-white mx-3 mb-3">VS</h5>
                        </div>

                        <div
                          className="d-flex flex-column justify-content-start align-items-center"
                          style={{ height: "7rem", width: "4rem" }}
                        >
                          <img
                            src={getEmblem(clan.toString())}
                            alt={clan.toString()}
                            style={{
                              width: "auto",
                              height: "60px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                          <h6 className="text-white text-center mt-1">
                            {clan.toString()}
                          </h6>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              )}
            </Col>

            <Col xl="9">
              <Row className="battle-rule">
                <h4 className="text-center mb-4">
                  Give your power to your clan
                </h4>
                <p className="text-center">
                  To join the battle, you need to own an NFT from the respective
                  clan and the $ECPX token
                </p>
                <p className="text-center">1 NFT = 1 Battle Point (BP)</p>
                <span className="text-center">
                  The prize pool will be open for the clan that has collected
                  the most BP
                </span>
              </Row>

              <Row className="battle-info my-4">
                {battleInfo &&
                  (battleInfo.status.name == "Inactive" ||
                    battleInfo.status.name == "Created") && (
                    <>
                      <h4>Next Battle Starts After</h4>

                      <div className="d-flex justify-content-center mt-4">
                        <div className="mx-1">
                          <div className="d-flex">
                            <h2>{Math.floor(days / 10)}</h2>
                            <h2>{days % 10}</h2>
                          </div>
                          <p className="text-center">Days</p>
                        </div>

                        <div className="mx-1">
                          <div className="d-flex">
                            <h2>{Math.floor(hours / 10)}</h2>
                            <h2>{hours % 10}</h2>
                          </div>
                          <p className="text-center">Hours</p>
                        </div>

                        <div className="mx-1">
                          <div className="d-flex">
                            <h2>{Math.floor(minutes / 10)}</h2>
                            <h2>{minutes % 10}</h2>
                          </div>
                          <p className="text-center">Minutes</p>
                        </div>
                      </div>

                      <hr />
                    </>
                  )}

                <h4 className="mb-4">Battle Info</h4>

                <Col sm="6">
                  <p>Prize Pool</p>
                  <div style={{ color: "#00ffa3" }}>
                    {battleInfo
                      ? shortenEsdt(convertWeiToEsdt(battleInfo.egld_prize))
                      : "0"}{" "}
                    EGLD
                  </div>
                  <div style={{ color: "#00ffa3" }}>
                    {battleInfo
                      ? shortenEsdt(convertWeiToEsdt(battleInfo.ecpx_prize))
                      : "0"}{" "}
                    $ECPX
                  </div>
                </Col>

                <Col sm="6">
                  <p>Rules</p>
                  <div className="text-white">
                    <span>Vote Fee:</span>{" "}
                    {battleInfo
                      ? shortenEsdt(convertWeiToEsdt(battleInfo.fee))
                      : "0"}{" "}
                    $ECPX
                  </div>
                  <div className="text-white">
                    <span>Battle duration:</span>{" "}
                    {battleInfo ? battleInfo.duration.toNumber() : "0"} days
                  </div>
                  <div className="text-white">
                    <span>Minimum Battle Points(BP) to unlock prize pool:</span>{" "}
                    {battleInfo ? battleInfo.min_power.toNumber() : "0"} NFTs
                  </div>
                </Col>
              </Row>

              <Row className="my-4" style={{ rowGap: "15px" }}>
                {battleInfo &&
                  (battleInfo.status.name == "Started" ||
                    battleInfo.status.name == "Finished") &&
                  battleInfo.battle_clans.map((clan, index) => {
                    return (
                      <Col sm={6} xl={4} key={`bp-${index}`}>
                        <div style={{ paddingTop: "90px" }}>
                          <div className="battle-panel">
                            <img
                              src={getEmblem(clan.toString())}
                              alt={clan.toString()}
                            />
                            <h3
                              className="text-center"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {clan.toString()}
                            </h3>
                            {battleInfo.status.name == "Started" ? (
                              <p className="text-center">
                                End After: {days.toString().padStart(2, "0")}:
                                {hours.toString().padStart(2, "0")}:
                                {minutes.toString().padStart(2, "0")}
                              </p>
                            ) : (
                              <p className="text-center">Battle is finished</p>
                            )}

                            <div className="mt-4 mb-2">
                              <Progress
                                color="warning"
                                value={Math.round(
                                  (battleInfo.battle_votes[index].toNumber() *
                                    100) /
                                    ([
                                      "Epic Demons",
                                      "Savannah Wizards",
                                      "The Reptiles",
                                      "Valhalla Warriors",
                                    ].includes(clan.toString())
                                      ? 201
                                      : 200)
                                )}
                              ></Progress>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <span className="mt-0">
                                  {battleInfo.battle_votes[index].toNumber()}
                                </span>
                                &nbsp;
                                <p>BP</p>
                              </div>
                              <div>
                                <p>
                                  {[
                                    "Epic Demons",
                                    "Savannah Wizards",
                                    "The Reptiles",
                                    "Valhalla Warriors",
                                  ].includes(clan.toString())
                                    ? 201
                                    : 200}{" "}
                                  BP
                                </p>
                              </div>
                            </div>

                            <button
                              className="support mt-4"
                              disabled={battleInfo.status.name == "Finished"}
                              onClick={() => onSupport(clan.toString())}
                            >
                              <i className="bx bx-chart" />
                              Support
                            </button>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
              </Row>
            </Col>
            <Col xl="3">
              <Col xs="12" className="mb-3">
                <Balance />
              </Col>

              <Col xs="12">
                <CollectionInfo condensed />
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Battle
