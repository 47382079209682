import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { routes } from "./routes";

import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";

import { getCoinInfo, getNftInfo } from 'store/actions';

// Import scss
import "./assets/scss/theme.scss";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {

  const dispatch = useDispatch();
  const { address: userAddress } = useGetAccountInfo();

  useEffect(() => {
    if(!userAddress) return;

    dispatch(getCoinInfo(userAddress));
    dispatch(getNftInfo(userAddress));
  }, [userAddress]);

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  return (
      <Routes>
        {routes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Layout>{route.component}</Layout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;