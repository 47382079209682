import {
    Address,
    SmartContract,
    AbiRegistry,
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { ElrondApiUrl, ElrondGatewayUrl, otherContracts, contracts } from 'configs';

export const elrondProvider = new ProxyNetworkProvider(ElrondGatewayUrl, { timeout: 10_000 });

function createSmartContract(abiJson, scAddress) {
    // const abiRegistry = AbiRegistry.create(abiJson);
    return new SmartContract({
        address: scAddress ? new Address(scAddress) : undefined,
        abi: abiJson,
    });
}

export const onedexSmartContract = createSmartContract(otherContracts.Onedex.abi, otherContracts.Onedex.address);
export const battleSmartContract = createSmartContract(contracts.BattleDao.abi, contracts.BattleDao.address);
