import { call, put, takeEvery, all, fork } from "redux-saga/effects"
import { GET_COIN_INFO } from "./actionTypes"
import { updateCoinInfo } from "./actions"
import axios from "axios"
import { ElrondApiUrl } from "configs"
import { WEGLD } from "helpers/constants"
import BigNumber from "bignumber.js/bignumber"
import { convertWeiToEsdt, shortenEsdt } from "utils"

const callApi = async userAddress => {
  try {
    const { data } = await axios.get(
      `${ElrondApiUrl}/accounts/${userAddress}/tokens?size=1000`
    )

    const assets = []

    // assets.push({
    //     balance: userBalance,
    //     identifier: 'EGLD',
    //     ticker: 'EGLD',
    //     decimals: 18
    // });

    const priceInfo = await axios.get(`${ElrondApiUrl}/mex/tokens?size=1000`)

    const coinPrice = priceInfo.data
    let egldPrice = 0

    for (const token of data) {
      let hasPrice = false
      for (const price of coinPrice) {
        if (token.type == "FungibleESDT") {
          if (price.id == WEGLD) egldPrice = price.price

          if (price.id == token.identifier) {
            assets.unshift({
              ...token,
              price: price.price,
            })

            hasPrice = true
            break
          }
        }
      }

      if (!hasPrice) {
        assets.push({
          ...token,
          price: 0,
          valueUsd: 0,
        })
      }
    }

    let availableBalance = 0

    for (const asset of assets) {
      availableBalance += asset.valueUsd
      console.log(availableBalance)
    }

    return { egldPrice, assets, availableBalance }
  } catch (error) {
    console.log(error)
  }
}

function* getCoinInfo({ payload: { data: address } }) {
  try {
    const response = yield call(callApi, address)

    yield put(updateCoinInfo(response))
  } catch (error) {
    console.log(error)
  }
}

function* coinSaga() {
  yield takeEvery(GET_COIN_INFO, getCoinInfo)
}

export default coinSaga
