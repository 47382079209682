import React, { useState, useEffect, useCallback } from "react"

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap"

import Genesis from "assets/ecpx/images/nft-staking/genesis.png"
import ECPX from "assets/ecpx/images/nft-staking/ecpx.png"
import Battle from "assets/ecpx/images/nft-staking/battle.png"
import CustomPool from "assets/ecpx/images/nft-staking/custom.png"
import Punk from "assets/ecpx/images/nft-staking/punk.png"

import Balance from "components/Common/Balance"
import CollectionInfo from "components/Common/CollectionInfo"
import ClanPool from "assets/ecpx/images/nft-staking/ClanPool.png"

import { useSelector, useDispatch } from "react-redux"

import {
  TokenPayment,
  BigUIntValue,
  U32Value,
  U64Value,
  Account,
  Address,
  SmartContract,
  BytesValue,
  ContractFunction,
  AddressValue,
  ResultsParser,
  OptionalValue,
  TokenIdentifierValue,
  Interaction,
  VariadicValue,
  List,
  ListType,
  Type,
} from "@multiversx/sdk-core/out"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out"
import {
  contracts,
  ECPX_TOKEN_ID,
  ElrondApiUrl,
  ElrondGatewayUrl,
} from "configs"

import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks"
import { sendTransactions } from "@multiversx/sdk-dapp/services"

import { admins, owner } from "configs"
import { BigZero, CLAN_INFO, GenesisCollection } from "helpers/constants"
import {
  convertWeiToEsdt,
  convertEsdtToWei,
  shortenEsdt,
  nonceToGenesis,
  nonceToECPX,
} from "utils"
import { toast } from "react-toastify"
import axios from "axios"
import { getNftInfo } from "store/actions"
import {
  NFTStakeModal,
  NftUnstakeModal,
} from "components/Nftstaking/NFTSelectModal"

const NftCard = ({ nft, onClick, staked = false }) => {
  return (
    <Col xs={12} sm={6} md={4} lg={3} className="nft-card mb-1">
      <img src={nft.image} alt="" />
      <h6 className="clan">{nft.clan}</h6>
      <h6 className="identifier">{nft.identifier}</h6>
      <button className="stake" onClick={onClick}>
        <i className="bx bx-chart" />
        {staked ? "Unstake" : "Stake"}
      </button>
    </Col>
  )
}

const NftStaking = () => {
  document.title = "NFT Staking | ECPx HUB"
  const [stakingInfo, setStakingInfo] = useState(undefined)
  const [userStakingInfo, setUserStakingInfo] = useState(undefined)
  const [poolStakingInfo, setPoolStakingInfo] = useState(undefined)
  const [poolUserStakingInfo, setPoolUserStakingInfo] = useState(undefined)

  const [stakedGenesis, setStakedGenesis] = useState([])
  const [stakedX, setStakedX] = useState([])
  const [stakedClan, setStakedClan] = useState([])
  const [stakedKing, setStakedKing] = useState([])
  const [currentPoolStakedTokens, setCurrentPoolStakedTokens] = useState([])

  const [showClaimModal, setShowClaimModal] = useState(false)
  const [genesisStakeModal, setGenesisStakeModal] = useState(false)
  const [genesisUnstakeModal, setGenesisUnstakeModal] = useState(false)
  const [xStakeModal, setXStakeModal] = useState(false)
  const [xUnstakeModal, setXUnstakeModal] = useState(false)
  const [clanStakeModal, setClanStakeModal] = useState(false)
  const [clanUnstakeModal, setClanUnstakeModal] = useState(false)
  const [kingStakeModal, setKingStakeModal] = useState(false)
  const [kingUnstakeModal, setKingUnstakeModal] = useState(false)
  const [poolStakeModal, setPoolStakeModal] = useState(false)
  const [poolUnstakeModal, setPoolUnstakeModal] = useState(false)
  const [clanType, setClanType] = useState("")
  const [currentPoolId, setCurrentPoolId] = useState(undefined)
  const [poolManageModal, setPoolManageModal] = useState(false)
  const [poolManageMode, setPoolManageMode] = useState("deposit")
  const [poolAmount, setPoolAmount] = useState(0)
  const [poolRewardModal, setPoolRewardModal] = useState(false)
  const [basicEgld, setBasicEgld] = useState(0)
  const [basicEcpx, setBasicEcpx] = useState(0)

  const { genesis, x, king, emblem } = useSelector(state => state.Nft)

  const nftStakingContractAddress = new Address(contracts.NftStaking.address)
  const nftStakingContract = new SmartContract({
    address: nftStakingContractAddress,
    abi: contracts.NftStaking.abi,
  })

  const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, {
    timeout: 30000,
  })

  const { address: userAddress } = useGetAccountInfo()
  const { hasPendingTransactions } = useGetPendingTransactions()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userAddress) return

    dispatch(getNftInfo(userAddress))
  }, [userAddress, hasPendingTransactions])

  useEffect(() => {
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getStakingInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        setStakingInfo(config)

        setClanType(config.clan_type.toString())
      } catch (error) {
        console.log(error)
      }
    })()
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getPoolsInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getPoolsInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )
        const poolsInfo = firstValue?.valueOf()
        setPoolStakingInfo(poolsInfo)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [hasPendingTransactions])

  useEffect(() => {
    if (!userAddress) return
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getUserStakingInfo"),
          args: [new AddressValue(new Address(userAddress))],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getUserStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        const staked_genesis_nonces = config.genesis_user_staked_tokens
        const staked_genesis_tokens = []
        const staked_genesis_token_ids = []

        for (const nonce of staked_genesis_nonces) {
          const token_id = nonceToGenesis(nonce)

          staked_genesis_token_ids.push(token_id)
        }

        if (staked_genesis_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_genesis_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              staked_genesis_tokens.push({
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_x_nonces = config.x_user_staked_tokens
        const staked_x_tokens = []
        const staked_x_token_ids = []

        for (const nonce of staked_x_nonces) {
          const token_id = nonceToECPX(nonce)

          staked_x_token_ids.push(token_id)
        }

        if (staked_x_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_x_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              const metadata_no = Number(nft.name.split("#")[1])
              let clan = ""

              for (const info of CLAN_INFO) {
                if (
                  (metadata_no >= info[1] && metadata_no <= info[2]) ||
                  metadata_no == info[3] ||
                  metadata_no == info[4]
                ) {
                  clan = info[0]

                  break
                }
              }

              staked_x_tokens.push({
                clan,
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_king_nonces = config.king_user_staked_tokens
        const staked_king_tokens = []
        const staked_king_token_ids = []

        for (const nonce of staked_king_nonces) {
          const token_id = nonceToECPX(nonce)

          staked_king_token_ids.push(token_id)
        }

        if (staked_king_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_king_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              const metadata_no = Number(nft.name.split("#")[1])
              let clan = ""

              for (const info of CLAN_INFO) {
                if (
                  (metadata_no >= info[1] && metadata_no <= info[2]) ||
                  metadata_no == info[3] ||
                  metadata_no == info[4]
                ) {
                  clan = info[0]

                  break
                }
              }

              staked_king_tokens.push({
                clan,
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_clan_nonces = config.clan_user_staked_tokens
        const staked_clan_tokens = []
        const staked_clan_token_ids = []

        for (const nonce of staked_clan_nonces) {
          const token_id = nonceToECPX(nonce)

          staked_clan_token_ids.push(token_id)
        }

        if (staked_clan_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_clan_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              const metadata_no = Number(nft.name.split("#")[1])
              let clan = ""

              for (const info of CLAN_INFO) {
                if (
                  (metadata_no >= info[1] && metadata_no <= info[2]) ||
                  metadata_no == info[3] ||
                  metadata_no == info[4]
                ) {
                  clan = info[0]

                  break
                }
              }

              staked_clan_tokens.push({
                clan,
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        setUserStakingInfo(config)
        setStakedGenesis(staked_genesis_tokens)
        setStakedX(staked_x_tokens)
        setStakedKing(staked_king_tokens)
        setStakedClan(staked_clan_tokens)
      } catch (error) {
        console.log(error)
      }
    })()
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getPoolStakingInfo"),
          args: [new AddressValue(new Address(userAddress))],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition =
          nftStakingContract.getEndpoint("getPoolStakingInfo")

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()

        console.log("pool user info", config)

        setPoolUserStakingInfo(config)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userAddress, hasPendingTransactions])

  const genesisStakeAll = () => {
    if (!userAddress || !genesis.length) {
      return
    }

    toast.info("Staking all Genesis NFTs is processing")
    ;(async () => {
      try {
        const args = []

        for (const nft of genesis) {
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        const tx = nftStakingContract.methodsExplicit
          .genesisStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisUnstakeAll = () => {
    if (!userAddress || !stakedGenesis.length) {
      return
    }

    toast.info("Unstaking all staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedGenesis.length < 50 ? 200 + stakedGenesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisSelectiveStake = selectedGenesis => {
    if (!userAddress || !genesis.length) {
      return
    }
    if (!selectedGenesis.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")
    toast.info("Staking selected Genesis NFTs is processing")
    ;(async () => {
      try {
        const args = []

        for (const nft of selectedGenesis) {
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        const tx = nftStakingContract.methodsExplicit
          .genesisStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisSelectiveUnstake = selectedGenesis => {
    if (!userAddress || !stakedGenesis.length) {
      return
    }
    if (!selectedGenesis.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")
    let args = new List(
      new Type("u64"),
      selectedGenesis.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedGenesis.length < 50 ? 200 + stakedGenesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisStake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Staking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisStake([])
          .withSingleESDTNFTTransfer(
            TokenPayment.nonFungible(nft.collection, nft.nonce),
            new Address(userAddress)
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const genesisUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .genesisUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const poolStake = selectedGenesis => {
    if (!userAddress || !x.filter(nft => nft.clan != clanType).length) {
      return
    }

    toast.info("Staking selected Genesis NFTs is processing")
    ;(async () => {
      try {
        const args = []

        for (const nft of selectedGenesis) {
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        console.log(args)
        const tx = nftStakingContract.methodsExplicit
          .poolStake([new BigUIntValue(currentPoolId)])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const poolUnstake = selectedNFTs => {
    if (!userAddress || !selectedNFTs.length) {
      return
    }

    let args = new List(
      new Type("u64"),
      selectedNFTs.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .poolUnstake([new BigUIntValue(currentPoolId), args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            selectedNFTs.length < 50 ? 200 + selectedNFTs.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const checkClaim = () => {
    if (!userAddress) {
      return
    }

    if (userStakingInfo) {
      if (
        BigZero.isEqualTo(userStakingInfo.user_egld_reward) &&
        BigZero.isEqualTo(userStakingInfo.user_ecpx_reward)
      ) {
        toast.info("No enough rewards to claim")

        return
      }

      if (!userStakingInfo.egld_enough_deposit) {
        toast.warn("No enough deposited EGLD to claim")

        return
      }

      if (!userStakingInfo.ecpx_enough_deposit) {
        toast.warn("No enough deposited ECPX to claim")

        return
      }

      setShowClaimModal(true)
    }
  }

  const claim = type => {
    if (!userAddress) {
      return
    }

    toast.info("Claiming Reward is processing")
    setShowClaimModal(false)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .claim([new U64Value(type)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xStake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Staking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withSingleESDTNFTTransfer(
            TokenPayment.nonFungible(nft.collection, nft.nonce),
            new Address(userAddress)
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xSelectiveUnStake = selectedX => {
    if (!userAddress) {
      return
    }
    if (!selectedX.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")

    let args = new List(
      new Type("u64"),
      selectedX.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked Genesis NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingStake = nft => {
    if (!userAddress) {
      return
    }

    let emblem_nonce = 0

    for (const emblem_nft of emblem) {
      if (emblem_nft.clan == nft.clan) {
        emblem_nonce = emblem_nft.nonce

        break
      }
    }

    if (!emblem_nonce) {
      toast.warn(`You don't have emblem to stake King of ${nft.clan}`)

      return
    }

    toast.info(`Staking King of ${nft.clan} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(
            [
              TokenPayment.nonFungible(nft.collection, nft.nonce),
              TokenPayment.nonFungible(nft.collection, emblem_nonce),
            ],
            new Address(userAddress)
          )
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${
            genesis.length < 50 ? 200 + genesis.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .kingUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const clanUnstake = nft => {
    if (!userAddress) {
      return
    }

    toast.info(`Unstaking ${nft.identifier} is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .clanUnstake([new U64Value(nft.nonce)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xStakeAll = () => {
    if (!userAddress || !x.length) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of x) {
          if (nft.clan !== clanType) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
          }
        }

        if (!args.length) {
          toast.info("No ECPX NFT to stake")

          return
        }

        toast.info("Staking all ECPX NFTs is processing")

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xUnstakeAll = () => {
    if (!userAddress || !stakedX.length) {
      return
    }

    toast.info("Unstaking all ECPX NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .xUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedX.length < 50 ? 200 + stakedX.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const xSelectiveStake = selectedX => {
    if (!userAddress || !x.length) {
      return
    }

    if (!selectedX.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")

    ;(async () => {
      try {
        const args = []

        for (const nft of selectedX) {
          if (nft.clan !== clanType) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
          }
        }

        if (!args.length) {
          toast.info("No ECPX NFT to stake")

          return
        }

        toast.info("Staking all ECPX NFTs is processing")

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingStakeAll = () => {
    if (!userAddress || !king.length) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of king) {
          let emblem_nonce = 0

          for (const emblem_nft of emblem) {
            if (emblem_nft.clan == nft.clan) {
              emblem_nonce = emblem_nft.nonce

              break
            }
          }

          if (emblem_nonce) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
            args.push(TokenPayment.nonFungible(nft.collection, emblem_nonce))
          }
        }

        if (!args.length) {
          toast.warn("No King of Clan with Emblem")

          return
        }

        toast.info(`Staking all Kings of Clan is processing`)

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingUnstakeAll = () => {
    if (!userAddress || !stakedKing.length) {
      return
    }

    toast.info(`Unstaking all Kings of Clan is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .kingUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedKing.length < 50 ? 200 + stakedKing.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingSelectiveStake = selectedKings => {
    if (!userAddress || !king.length) {
      return
    }

    if (!selectedKings.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")

    ;(async () => {
      try {
        const args = []

        for (const nft of selectedKings) {
          let emblem_nonce = 0

          for (const emblem_nft of emblem) {
            if (emblem_nft.clan == nft.clan) {
              emblem_nonce = emblem_nft.nonce

              break
            }
          }

          if (emblem_nonce) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
            args.push(TokenPayment.nonFungible(nft.collection, emblem_nonce))
          }
        }

        if (!args.length) {
          toast.warn("No King of Clan with Emblem")

          return
        }

        toast.info(`Staking all Kings of Clan is processing`)

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const kingSelectiveUnstake = selectedKings => {
    if (!userAddress || !stakedKing.length) {
      return
    }
    if (!selectedKings.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")
    let args = new List(
      new Type("u64"),
      selectedKings.map(el => new U64Value(el.nonce))
    )
    toast.info(`Unstaking all Kings of Clan is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .kingUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedKing.length < 50 ? 200 + stakedKing.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const clanStakeAll = () => {
    if (!userAddress || !x.length || !clanType) {
      return
    }

    ;(async () => {
      try {
        const args = []

        for (const nft of x) {
          if (nft.clan == clanType) {
            args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
          }
        }

        if (!args.length) {
          toast.info(`No ${clanType} NFTs to stake`)

          return
        }

        toast.info(`Staking all ${clanType} NFTs is processing`)

        const tx = nftStakingContract.methodsExplicit
          .xStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const clanUnstakeAll = () => {
    if (!userAddress || !stakedClan.length) {
      return
    }

    toast.info(`Unstaking all ${clanType} NFTs is processing`)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .clanUnstake([])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: `${
            stakedClan.length < 50 ? 200 + stakedClan.length * 10 : 600
          }000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onCreatePool = nft => {
    if (!(king.length > 0)) {
      // toast.error("You don't have King NFT.");
      // return;
    }
    ;(async () => {
      try {
        const args = []
        args.push(new TokenIdentifierValue(nft.collection))
        for (const clan of CLAN_INFO) {
          if (nft.clan == clan[0]) {
            console.log(clan[0])
            args.push(
              ...[
                BytesValue.fromUTF8(clan[0]),
                new U64Value(clan[1]),
                new U64Value(clan[2]),
                new U64Value(clan[3]),
                new U64Value(clan[4]),
                new U64Value(clan[5]),
              ]
            )
          }
        }
        console.log(args)
        if (!(args.length > 0)) {
          toast.error("You don't have King NFT.")
          return
        }
        const tx = nftStakingContract.methodsExplicit
          .createPool(args)
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onDeposit = pool_id => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_deposit([new BigUIntValue(pool_id)])
          .withValue(convertEsdtToWei(poolAmount))
          .buildTransaction()

        const rawTx = {
          value: convertEsdtToWei(poolAmount).toString(),
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onWithDraw = pool_id => {
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_withdraw([
            TokenIdentifierValue.egld(),
            new BigUIntValue(convertEsdtToWei(poolAmount)),
            new BigUIntValue(pool_id),
          ])
          .buildTransaction()

        const rawTx = {
          value: 0,
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "90000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const onClaim = pool_id => {
    if (!userAddress) {
      return
    }

    toast.info("Claiming Reward is processing")
    setShowClaimModal(false)
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .pool_claim([new BigUIntValue(pool_id)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }
  const onPoolUpdateBasicReward = pool_id => {
    ;(async () => {
      console.log(pool_id, new BigUIntValue(convertEsdtToWei(0.1)))
      const tx = nftStakingContract.methodsExplicit
        .updatePoolBasicReward([
          new BigUIntValue(pool_id),
          new BigUIntValue(convertEsdtToWei(basicEgld)),
          new BigUIntValue(convertEsdtToWei(basicEcpx)),
        ])
        .buildTransaction()

      const rawTx = {
        value: "0",
        data: tx.getData().toString(),
        receiver: contracts.NftStaking.address,
        gasLimit: "90000000",
      }

      await sendTransactions({
        transactions: [rawTx],
        transactionsDisplayInfo: {
          processingMessage: "Processing Transaction",
          errorMessage: "Transaction Failed",
          successMessage: "Transaction Success",
        },
        redirectAfterSign: false,
      })
    })()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="nft-staking">
          <Row>
            <Col xl="9">
              <Row>
                <Col md="6" className="mb-5">
                  <div className="nft-staking-pool">
                    <img src={ECPX} alt="" width={60} height={60} />
                    <h3>Elrond Cyberpunks X</h3>
                    <h6>Stake your NFTs from ECPx Collection</h6>
                    <p>
                      {userStakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(userStakingInfo.x_user_egld_reward)
                          )
                        : 0}{" "}
                      $EGLD Claimable Rewards
                    </p>
                    <p>
                      {userStakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(userStakingInfo.x_user_ecpx_reward)
                          )
                        : 0}{" "}
                      $ECPx Claimable Rewards
                    </p>
                    <span>
                      Reward pool:{" "}
                      {stakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(stakingInfo.x_egld_reward_pool)
                          )
                        : 0}{" "}
                      $EGLD
                    </span>
                    <span>
                      Reward pool:{" "}
                      {stakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(stakingInfo.x_ecpx_reward_pool)
                          )
                        : 0}{" "}
                      $ECPX
                    </span>
                    <span>
                      Staked NFTs:{" "}
                      {stakingInfo ? stakingInfo.x_staked_count.toNumber() : 0}
                    </span>
                    <span>
                      Your Staked NFTs:{" "}
                      {userStakingInfo
                        ? userStakingInfo.x_user_staked_tokens.length
                        : 0}
                    </span>
                    <p>
                      Daily rewards($EGLD) per NFT:{" "}
                      <span>
                        {stakingInfo
                          ? shortenEsdt(
                              convertWeiToEsdt(
                                stakingInfo.x_basic_egld_reward.toNumber()
                              )
                            )
                          : 0}{" "}
                        $EGLD
                      </span>
                    </p>
                    <p>
                      Daily rewards($ECPX) per NFT:{" "}
                      <span>
                        {stakingInfo
                          ? shortenEsdt(
                              convertWeiToEsdt(
                                stakingInfo.x_basic_ecpx_reward.toNumber()
                              )
                            )
                          : 0}{" "}
                        $ECPX
                      </span>
                    </p>

                    <div
                      className="d-flex justify-content-around w-100"
                      style={{ maxWidth: 320 }}
                    >
                      <button
                        className="stake-all"
                        onClick={() => setXStakeModal(true)}
                      >
                        <i className="bx bx-chart" />
                        Stake
                      </button>

                      <button
                        className="unstake-all"
                        onClick={() => setXUnstakeModal(true)}
                      >
                        <i className="bx bx-chart" />
                        Unstake
                      </button>

                      <button
                        className="claim"
                        onClick={() => claim(1)}
                        disabled={
                          !(userStakingInfo && userStakingInfo.x_claimable)
                        }
                      >
                        <i className="bx bx-chart" />
                        Claim
                      </button>
                    </div>
                  </div>
                </Col>

                <Col md="6" className="mb-5">
                  <div className="nft-staking-pool">
                    <img src={Battle} alt="" width={60} height={60} />

                    <h3>King of Clan</h3>
                    <h6>Stake King with Emblem from ECPx Collection</h6>
                    <p>
                      {userStakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(
                              userStakingInfo.king_user_egld_reward
                            )
                          )
                        : 0}{" "}
                      $EGLD Claimable Rewards
                    </p>
                    <p>
                      {userStakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(
                              userStakingInfo.king_user_ecpx_reward
                            )
                          )
                        : 0}{" "}
                      $ECPx Claimable Rewards
                    </p>
                    <span>
                      Reward pool:{" "}
                      {stakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(stakingInfo.king_egld_reward_pool)
                          )
                        : 0}{" "}
                      $EGLD
                    </span>
                    <span>
                      Reward pool:{" "}
                      {stakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(stakingInfo.king_ecpx_reward_pool)
                          )
                        : 0}{" "}
                      $ECPX
                    </span>
                    <span>
                      Staked NFTs:{" "}
                      {stakingInfo
                        ? stakingInfo.king_staked_count.toNumber()
                        : 0}
                    </span>
                    <span>
                      Your Staked NFTs:{" "}
                      {userStakingInfo
                        ? userStakingInfo.king_user_staked_tokens.length
                        : 0}
                    </span>
                    <p>
                      Daily rewards($EGLD) per NFT:{" "}
                      <span>
                        {stakingInfo
                          ? shortenEsdt(
                              convertWeiToEsdt(
                                stakingInfo.king_basic_egld_reward.toNumber()
                              )
                            )
                          : 0}{" "}
                        $EGLD
                      </span>
                    </p>
                    <p>
                      Daily rewards($ECPX) per NFT:{" "}
                      <span>
                        {stakingInfo
                          ? shortenEsdt(
                              convertWeiToEsdt(
                                stakingInfo.king_basic_ecpx_reward.toNumber()
                              )
                            )
                          : 0}{" "}
                        $ECPX
                      </span>
                    </p>

                    <div
                      className="d-flex justify-content-around w-100"
                      style={{ maxWidth: 320 }}
                    >
                      <button
                        className="stake-all"
                        onClick={() => setKingStakeModal(true)}
                      >
                        <i className="bx bx-chart" />
                        Stake
                      </button>

                      <button
                        className="unstake-all"
                        onClick={() => setKingUnstakeModal(true)}
                      >
                        <i className="bx bx-chart" />
                        Unstake
                      </button>

                      <button
                        className="claim"
                        onClick={() => claim(3)}
                        disabled={
                          !(userStakingInfo && userStakingInfo.king_claimable)
                        }
                      >
                        <i className="bx bx-chart" />
                        Claim
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-5">
                  <div className="nft-staking-pool">
                    <img src={Battle} alt="" width={60} height={60} />

                    <h3>After Battle Prize Pool</h3>

                    {stakingInfo && clanType ? (
                      <>
                        <h6>
                          Stake your NFTs from <p>{clanType}</p>
                        </h6>

                        <p>
                          {userStakingInfo
                            ? shortenEsdt(
                                convertWeiToEsdt(
                                  userStakingInfo.clan_user_egld_reward
                                )
                              )
                            : 0}{" "}
                          $EGLD Claimable Rewards
                        </p>
                        <p>
                          {userStakingInfo
                            ? shortenEsdt(
                                convertWeiToEsdt(
                                  userStakingInfo.clan_user_ecpx_reward
                                )
                              )
                            : 0}{" "}
                          $ECPx Claimable Rewards
                        </p>

                        <span>
                          Reward pool:{" "}
                          {stakingInfo
                            ? shortenEsdt(
                                convertWeiToEsdt(
                                  stakingInfo.clan_egld_reward_pool
                                )
                              )
                            : 0}{" "}
                          $EGLD
                        </span>
                        <span>
                          Reward pool:{" "}
                          {stakingInfo
                            ? shortenEsdt(
                                convertWeiToEsdt(
                                  stakingInfo.clan_ecpx_reward_pool
                                )
                              )
                            : 0}{" "}
                          $ECPX
                        </span>

                        <span>
                          Staked NFTs:{" "}
                          {stakingInfo
                            ? stakingInfo.clan_staked_count.toNumber()
                            : 0}
                        </span>
                        <span>
                          Your Staked NFTs:{" "}
                          {userStakingInfo
                            ? userStakingInfo.clan_user_staked_tokens.length
                            : 0}
                        </span>
                        <p>
                          Daily rewards($EGLD) per NFT:{" "}
                          <span>
                            {stakingInfo
                              ? shortenEsdt(
                                  convertWeiToEsdt(
                                    stakingInfo.clan_basic_egld_reward.toNumber()
                                  )
                                )
                              : 0}{" "}
                            $EGLD
                          </span>
                        </p>
                        <p>
                          Daily rewards($ECPX) per NFT:{" "}
                          <span>
                            {stakingInfo
                              ? shortenEsdt(
                                  convertWeiToEsdt(
                                    stakingInfo.clan_basic_ecpx_reward.toNumber()
                                  )
                                )
                              : 0}{" "}
                            $ECPX
                          </span>
                        </p>

                        <div
                          className="d-flex justify-content-around w-100"
                          style={{ maxWidth: 320 }}
                        >
                          <button
                            className="stake-all"
                            onClick={() => clanStakeAll()}
                          >
                            <i className="bx bx-chart" />
                            Stake All
                          </button>

                          <button
                            className="unstake-all"
                            onClick={() => clanUnstakeAll()}
                          >
                            <i className="bx bx-chart" />
                            Unstake All
                          </button>

                          <button
                            className="claim"
                            onClick={() => claim(2)}
                            disabled={
                              !(
                                userStakingInfo &&
                                userStakingInfo.clan_claimable
                              )
                            }
                          >
                            <i className="bx bx-chart" />
                            Claim
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <span>
                          NFTs of Winning Clan on battle will be staked here
                          with higher daily reward
                        </span>
                        <p>Battle is coming now</p>

                        <div
                          className="d-flex justify-content-around w-100"
                          style={{ maxWidth: 320 }}
                        >
                          <button className="claim">
                            <i className="bx bx-chart" />
                            Coming Soon
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </Col>

                <Col md="6" className="mb-5">
                  <div className="nft-staking-pool">
                    <img src={Genesis} alt="" width={60} height={60} />
                    <h3 className="text-center">Elrond Cyberpunks Genesis</h3>
                    <h6>Stake your NFTs from ECPx Collection</h6>
                    <p>
                      {userStakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(
                              userStakingInfo.genesis_user_egld_reward
                            )
                          )
                        : 0}{" "}
                      $EGLD Claimable Rewards
                    </p>
                    <p>
                      {userStakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(
                              userStakingInfo.genesis_user_ecpx_reward
                            )
                          )
                        : 0}{" "}
                      $ECPx Claimable Rewards
                    </p>
                    <span>
                      Reward pool($EGLD):{" "}
                      {stakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(
                              stakingInfo.genesis_egld_reward_pool
                            )
                          )
                        : 0}{" "}
                      $EGLD
                    </span>
                    <span>
                      Reward pool($ECPX):{" "}
                      {stakingInfo
                        ? shortenEsdt(
                            convertWeiToEsdt(
                              stakingInfo.genesis_ecpx_reward_pool
                            )
                          )
                        : 0}{" "}
                      $ECPX
                    </span>
                    <span>
                      Staked NFTs:{" "}
                      {stakingInfo
                        ? stakingInfo.genesis_staked_count.toNumber()
                        : 0}
                    </span>
                    <span>
                      Your Staked NFTs:{" "}
                      {userStakingInfo
                        ? userStakingInfo.genesis_user_staked_tokens.length
                        : 0}
                    </span>
                    <p>
                      Daily rewards($EGLD) per NFT:{" "}
                      <span>
                        {stakingInfo
                          ? shortenEsdt(
                              convertWeiToEsdt(
                                stakingInfo.genesis_basic_egld_reward.toNumber()
                              )
                            )
                          : 0}{" "}
                        $EGLD
                      </span>
                    </p>
                    <p>
                      Daily rewards($ECPX) per NFT:{" "}
                      <span>
                        {stakingInfo
                          ? shortenEsdt(
                              convertWeiToEsdt(
                                stakingInfo.genesis_basic_ecpx_reward.toNumber()
                              )
                            )
                          : 0}{" "}
                        $ECPX
                      </span>
                    </p>

                    <div
                      className="d-flex justify-content-between w-100"
                      style={{ maxWidth: 320 }}
                    >
                      <button
                        className="stake-all"
                        onClick={() => setGenesisStakeModal(true)}
                      >
                        <i className="bx bx-chart" />
                        Stake
                      </button>

                      <button
                        className="unstake-all"
                        onClick={() => setGenesisUnstakeModal(true)}
                      >
                        <i className="bx bx-chart" />
                        Unstake
                      </button>

                      <button
                        className="claim"
                        onClick={() => claim(0)}
                        disabled={
                          !(
                            userStakingInfo && userStakingInfo.genesis_claimable
                          )
                        }
                      >
                        <i className="bx bx-chart" />
                        Claim
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="my-nfts">
                <Col xs="12">
                  <h3>My NFTs</h3>
                </Col>

                <Col xs="12">
                  <h5>Elrond Cyberpunks Genesis</h5>
                </Col>

                {genesis.length > 0 ? (
                  genesis.map((nft, index) => {
                    return (
                      <NftCard
                        nft={nft}
                        key={index}
                        onClick={() => genesisStake(nft)}
                      />
                    )
                  })
                ) : (
                  <Col xs="12">
                    <h4 className="text-center my-3">
                      You don't have Genesis Collection NFT
                    </h4>
                  </Col>
                )}

                <Col xs="12">
                  <h5>
                    Elrond Cyberpunks X <span>King of Clan</span>
                  </h5>
                </Col>

                {king.length > 0 ? (
                  king.map((nft, index) => (
                    <NftCard
                      nft={nft}
                      key={index}
                      onClick={() => kingStake(nft)}
                    />
                  ))
                ) : (
                  <Col xs="12">
                    <h4 className="text-center my-3">
                      You don't have Genesis Collection NFT
                    </h4>
                  </Col>
                )}

                {/* <Col xs="12">
                                    <h5>
                                        Elrond Cyberpunks X <span>Emblem of Clan</span>
                                    </h5>
                                </Col>

                                {
                                    emblem.length > 0 ? (
                                        emblem.map((nft, index) => (
                                            <NftCard nft={nft} key={index} />
                                        ))
                                    ) : (
                                        <Col xs="12">
                                            <h4 className='text-center my-3'>
                                                You don't have Genesis Collection NFT
                                            </h4>
                                        </Col>
                                    )
                                } */}

                {clanType && (
                  <>
                    <Col xs="12">
                      <h5>
                        Elrond Cyberpunks X <span>{clanType}</span>
                      </h5>
                    </Col>

                    {x.length > 0 ? (
                      x
                        .filter(nft => nft.clan == clanType)
                        .map((nft, index) => (
                          <NftCard
                            nft={nft}
                            key={index}
                            onClick={() => xStake(nft)}
                          />
                        ))
                    ) : (
                      <Col xs="12">
                        <h4 className="text-center my-3">
                          You don't have {clanType} NFT
                        </h4>
                      </Col>
                    )}
                  </>
                )}

                <Col xs="12">
                  <h5>
                    Elrond Cyberpunks X <span>Member of Clan</span>
                  </h5>
                </Col>

                {x.length > 0 ? (
                  x
                    .filter(nft => nft.clan != clanType)
                    .map((nft, index) => (
                      <NftCard
                        nft={nft}
                        key={index}
                        onClick={() => xStake(nft)}
                      />
                    ))
                ) : (
                  <Col xs="12">
                    <h4 className="text-center my-3">
                      You don't have Genesis Collection NFT
                    </h4>
                  </Col>
                )}
              </Row>

              {userStakingInfo && (
                <Row className="my-nfts">
                  <Col xs="12">
                    <h3>Staked NFTs</h3>
                  </Col>

                  <Col xs="12">
                    <h5>Elrond Cyberpunks Genesis</h5>
                  </Col>

                  {stakedGenesis.length > 0 ? (
                    stakedGenesis.map((nft, index) => (
                      <NftCard
                        staked
                        nft={nft}
                        key={index}
                        onClick={() => genesisUnstake(nft)}
                      />
                    ))
                  ) : (
                    <Col xs="12">
                      <h4 className="text-center my-3">
                        You don't have Staked Genesis Collection NFT
                      </h4>
                    </Col>
                  )}

                  <Col xs="12">
                    <h5>
                      Elrond Cyberpunks X <span>King of Clan</span>
                    </h5>
                  </Col>

                  {stakedKing.length > 0 ? (
                    stakedKing.map((nft, index) => (
                      <NftCard
                        nft={nft}
                        key={index}
                        staked
                        onClick={() => kingUnstake(nft)}
                      />
                    ))
                  ) : (
                    <Col xs="12">
                      <h4 className="text-center my-3">
                        You don't have Staked King of ECPX clan
                      </h4>
                    </Col>
                  )}

                  {/* <Col xs="12">
                                            <h5>
                                                Elrond Cyberpunks X <span>Emblem of Clan</span>
                                            </h5>
                                        </Col>

                                        {
                                            emblem.length > 0 ? (
                                                emblem.map((nft, index) => (
                                                    <NftCard nft={nft} key={index} />
                                                ))
                                            ) : (
                                                <Col xs="12">
                                                    <h4 className='text-center my-3'>
                                                        You don't have Genesis Collection NFT
                                                    </h4>
                                                </Col>
                                            )
                                        } */}

                  {clanType && (
                    <>
                      <Col xs="12">
                        <h5>
                          Elrond Cyberpunks X <span>{clanType}</span>
                        </h5>
                      </Col>

                      {stakedClan.length > 0 ? (
                        stakedClan.map((nft, index) => (
                          <NftCard
                            nft={nft}
                            key={index}
                            staked
                            onClick={() => clanUnstake(nft)}
                          />
                        ))
                      ) : (
                        <Col xs="12">
                          <h4 className="text-center my-3">
                            You don't have Staked NFT on {clanType} Pool
                          </h4>
                        </Col>
                      )}
                    </>
                  )}

                  <Col xs="12">
                    <h5>
                      Elrond Cyberpunks X <span>Member of Clan</span>
                    </h5>
                  </Col>

                  {stakedX.length > 0 ? (
                    stakedX.map((nft, index) => (
                      <NftCard
                        nft={nft}
                        key={index}
                        staked
                        onClick={() => xUnstake(nft)}
                      />
                    ))
                  ) : (
                    <Col xs="12">
                      <h4 className="text-center my-3">
                        You don't have Staked ECPX NFT
                      </h4>
                    </Col>
                  )}
                </Row>
              )}
            </Col>

            <Col xl="3">
              <Row>
                <Col xs="12" className="mb-3">
                  <Balance />
                </Col>
                <Col xs="12">
                  <CollectionInfo />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <NFTStakeModal
        nfts={genesis}
        onStake={genesisSelectiveStake}
        setModal={setGenesisStakeModal}
        isOpen={genesisStakeModal}
        nftCollection="Elrond Cyberpunks Genesis"
      />
      <NftUnstakeModal
        nfts={stakedGenesis}
        onUnstake={genesisSelectiveUnstake}
        setModal={setGenesisUnstakeModal}
        isOpen={genesisUnstakeModal}
        nftCollection="Elrond Cyberpunks Genesis"
      />

      <NFTStakeModal
        nfts={x}
        onStake={xSelectiveStake}
        setModal={setXStakeModal}
        isOpen={xStakeModal}
        nftCollection="Elrond Cyberpunks X"
      />
      <NftUnstakeModal
        nfts={stakedX}
        onUnstake={xSelectiveUnStake}
        setModal={setXUnstakeModal}
        isOpen={xUnstakeModal}
        nftCollection="Elrond Cyberpunks X"
      />

      <NFTStakeModal
        nfts={king}
        onStake={kingSelectiveStake}
        setModal={setKingStakeModal}
        isOpen={kingStakeModal}
        nftCollection="Elrond Cyberpunks X King of Clan"
      />
      <NftUnstakeModal
        nfts={stakedKing}
        onUnstake={kingSelectiveUnstake}
        setModal={setKingUnstakeModal}
        isOpen={kingUnstakeModal}
        nftCollection="Elrond Cyberpunks X King of Clan"
      />
      <NFTStakeModal
        nfts={x.filter(nft => nft.clan != clanType)}
        onStake={poolStake}
        setModal={setPoolStakeModal}
        isOpen={poolStakeModal}
        nftCollection="Elrond Cyberpunks X King of Clan"
      />
      <NftUnstakeModal
        nfts={currentPoolStakedTokens}
        onUnstake={poolUnstake}
        setModal={setPoolUnstakeModal}
        isOpen={poolUnstakeModal}
        nftCollection="Elrond Cyberpunks X King of Clan"
      />
      <Modal
        isOpen={poolManageModal}
        toggle={() => {
          setPoolManageModal(false)
        }}
        style={{
          width: "fit-content",
        }}
        centered
      >
        <div
          className="d-flex"
          style={{
            margin: "10px 20px",
          }}
        >
          <span>Amount:</span>
          <input
            type="text"
            style={{ width: "200px" }}
            onChange={e => setPoolAmount(e.target.value)}
          />{" "}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 20px",
            gap: "5px",
          }}
        >
          {" "}
          <button
            className="genesis-stake"
            onClick={() => {
              if (poolManageMode == "deposit") {
                onDeposit(currentPoolId)
              } else {
                onWithDraw(currentPoolId)
              }
              setPoolManageModal(false)
            }}
          >
            OK
          </button>{" "}
          <button
            className="genesis-close"
            onClick={() => setPoolManageModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={poolRewardModal}
        toggle={() => {
          setPoolRewardModal(false)
        }}
        style={{
          width: "fit-content",
        }}
        centered
      >
        <div
          className="d-flex"
          style={{
            margin: "10px 20px",
          }}
        >
          <span>EGLD Amount:</span>
          <input
            type="text"
            style={{ width: "200px" }}
            defaultValue={basicEgld}
            onChange={e => setBasicEgld(e.target.value)}
          />{" "}
        </div>
        <div
          className="d-flex"
          style={{
            margin: "10px 20px",
          }}
        >
          <span>ECPX Amount:</span>
          <input
            type="text"
            style={{ width: "200px" }}
            defaultValue={basicEcpx}
            onChange={e => setBasicEcpx(e.target.value)}
          />{" "}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 20px",
            gap: "5px",
          }}
        >
          {" "}
          <button
            className="genesis-stake"
            onClick={() => {
              onPoolUpdateBasicReward(currentPoolId)
              setPoolRewardModal(false)
            }}
          >
            OK
          </button>{" "}
          <button
            className="genesis-close"
            onClick={() => setPoolRewardModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default NftStaking
