
import axios from 'axios';
import { ElrondApiUrl } from "configs";

export const getAccountTransactions = async (address, functionName, from, size) => {
  const configUrl = `${ElrondApiUrl}/accounts/${address}/transactions?function=${functionName}&from=${from}&size=${size}`;

  try {
      const { data } = await axios.get(configUrl);

      return data;
  } catch {}

  return [];
}

export const getAccountTransactionsCount = async (address, functionName) => {
  const configUrl = `${ElrondApiUrl}/accounts/${address}/transactions/count?function=${functionName}`;

  try {
      const { data } = await axios.get(configUrl);

      return data;
  } catch {}

  return 0;
}

export const getNft = async (nftId) => {
  try {
    const url = `${ElrondApiUrl}/nfts/${nftId}`;
    const { data } = await axios.get(url);

    return data;
  } catch {}

  return undefined;
}

export const getNfts = async (nftIds) => {
  try {
    const url = `${ElrondApiUrl}/nfts?identifiers=${nftIds.join(',')}`;
    const { data } = await axios.get(url);

    return data;
  } catch {}

  return [];
}

export const getEsdtTokens = async (tokenIds) => {
  try {
    const url = `${ElrondApiUrl}/tokens?identifiers=${tokenIds.join(',')}`;
    const { data } = await axios.get(url);

    return data;
  } catch {}

  return [];
}