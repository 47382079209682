import React from "react"
import ReactApexChart from "react-apexcharts"

const Tokenomy = () => {
  document.title = "Tokenomy | ECPX HUB"

  const tokenSupply = 440000000
  const series = [
    tokenSupply * 0.09,
    tokenSupply * 0.15,
    tokenSupply * 0.1,
    tokenSupply * 0.15,
    tokenSupply * 0.25,
    tokenSupply * 0.18,
    tokenSupply * 0.05,
    tokenSupply * 0.03,
  ]
  const options = {
    chart: {
      type: "donut",
    },
    labels: [
      "Community Fund",
      "Holders of $ECPx",
      "Private Sale",
      "Public Sale",
      "Development",
      "Treasury",
      "Marketing Growth Pool",
      "Advisors",
    ],
    dataLabels: {
      style: {
        fontSize: "13px",
      },
      dropShadow: false,
    },
    legend: {
      labels: {
        colors: "#A6B0CF",
      },
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: "100%",
            height: "420px",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <h4>Introduction</h4>
        <p>
          $ECPx is a decentralized finance (DeFi) token developed on the
          MultiversX blockchain. Its main purpose is to fuel the ECPxHUB
          platform and ecosystem, enabling users to interact with smart
          contracts via a range of user-friendly tools.
        </p>
        <ul>
          <li>
            <b>Token Name:</b> $ECPx
          </li>
          <li>
            <b>Token Identifier:</b> ECPX-5cbfeb
          </li>
          <li>
            <b>Token Symbol:</b> ECPx
          </li>
          <li>
            <b>Token Supply:</b> 440,000,000
          </li>
          <li>
            <b>Blockchain:</b> Multivers X
          </li>
          <li>
            <b>Holders:</b> 2 000 +
          </li>
        </ul>

        <h4>Listing</h4>
        <p>
          Currently, you can find $ECPx on all DEXs within the MultiversX
          ecosystem. In One Dex, there is an open liquidity pool where you can
          add LP and stake it in the farm for a 54% Annual Reward/LP – learn
          more. Additionally, there is a regular staking pool with a 30% APR.
          After we allocate percentages for “Community Funds” and “Partners”, a
          pre-sale will be open. Until then, we will have even more projects and
          added liquidity. For more information, please check out our “RoadMap“.
        </p>

        <h4>Liquidity</h4>

        <h5>ECPx HUB</h5>
        <p>
          The ECPxHUB is a decentralized platform that provides seamless staking
          strategies and defi services for users, powered by blockchain
          technology. $ECPx token is designed to be used as a utility token
          within the ECPxHUB platform. Users can utilize $ECPx tokens for
          various activities within the platform.
        </p>

        <h5>Clan Battles</h5>
        <p>
          Users will need $ECPx tokens to participate in Clan battles, where
          they can form or join clans, compete against other clans, and win the
          privilege to stake their NFTs in a special staking pool. Clan battles
          will be powered by a DAO that ensures transparency, fairness, and
          community governance.
        </p>

        <h5>Special staking pools</h5>
        <p>
          The staking pools in ECPx HUB are specially designed to provide a wide
          range of passive income opportunities while being flexible enough to
          meet various requirements.
        </p>

        <h5>Wiz Row</h5>
        <p>
          A marketplace that enables its users to create smart contracts
          tailored to their needs. The smart contracts will be deployable on
          multiple blockchain networks. 40% of every deployment fee will be
          added to $ECPx liquidity.
        </p>

        <h5>$UTK Staking</h5>
        <p>
          We will link $ECPx to the movement and performance of $UTK on the
          market. We believe that the altcoin season is just starting, and UTK
          is at the beginning of its super cycle. Therefore, instead of adding
          our $UTK to liquidity now, we will stake them at the current 28% APY
          and periodically add them into $ECPx liquidity. We like the UTK
          project and we believe in their business model.
        </p>

        <h5>Burn</h5>
        <p>
          Our plans include burning 50% of the supply in ~2.5 years (10% per six
          months). The exact start date for this process will be included in the
          next update of the road map. Our goal is to achieve a price of ~0.1$ –
          0.3$ per $ECPx after the implementation of the entire supply.
        </p>

        <h4>Distribution</h4>
        <div className="w-100 d-flex justify-content-center mt-4 mb-4">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={"780px"}
          />
        </div>

        <h4>$ECPx will be distributed by the following economics</h4>
        <ul>
          <li>
            <b>Development:</b> 25% of the total supply will be sold in a
            private sale to strategic partners and early supporters to fund the
            development and growth of the $ECPx ecosystem.
          </li>
          <li>
            <b>Treasury:</b> 18% of the total supply.
          </li>
          <li>
            <b>Public Sale:</b> 15% of the total supply will be sold in a public
            sale to the wider community through a fair and transparent process.
          </li>
          <li>
            <b>Pre-Sale:</b> 10% of the total supply will be sold in a private
            sale.
          </li>
          <li>
            <b>Community Incentives:</b> 9% of the total supply will be
            allocated for community incentives, such as airdrops, staking
            rewards, and liquidity provision, to encourage user adoption and
            engagement.
          </li>
          <li>
            <b>Marketing:</b> 5% of the total supply
          </li>
          <li>
            <b>Advisors:</b> 3% of the total supply
          </li>
        </ul>

        <h4>Release supply schedule</h4>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            src={
              "https://elrondcyberpunks.com/ecp_mxpy/uploads/2023/04/token-release.png"
            }
            alt=""
            width={"80%"}
          />
        </div>

        <h4>Governance</h4>
        <p>
          The $ECPx token will be governed by a DAO, which will be responsible
          for making important decisions related to the platform, such as
          changes to the staking mechanics, tokenomics, and platform upgrades.
          Token holders will be able to participate in the governance process by
          voting on proposals and shaping the future development of the ECPxHUB
          platform.
        </p>

        <h4>Conclusion</h4>
        <p>
          $ECPx is a utility token that aims to revolutionize the defi industry
          by providing a decentralized platform powered by blockchain
          technology. With its utility within the ECPxHUB platform and the
          support of the community-driven DAO governance, $ECPx token is poised
          to create an immersive ecosystem that offers a seamless and rewarding
          experience for users.
        </p>

        <p>
          Don’t miss out on the chance to be a part of this amazing project –
          get your hands on $ECPx now!
        </p>
      </div>
    </React.Fragment>
  )
}

export default Tokenomy
