import React, { useState, useEffect } from 'react';

import {
    Container,
    Row,
    Col
} from "reactstrap";

import Balance from "components/Common/Balance";
import CollectionInfo from 'components/Common/CollectionInfo';

import {
    TokenPayment,
    BigUIntValue,
    U32Value,
    U64Value,
    Account,
    Address,
    SmartContract,
    BytesValue,
    ContractFunction,
    AddressValue,
    ResultsParser,
    OptionalValue,
    TokenIdentifierValue,
    VariadicValue
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { contracts, ElrondApiUrl, ElrondGatewayUrl } from "configs";
import { convertWeiToEsdt, convertEsdtToWei, shortenEsdt, nonceToGenesis, nonceToECPX } from "utils";
import {
    useGetAccountInfo,
    useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

import EGLD from 'assets/ecpx/images/buy-package/egld.png';
import Logo from 'assets/ecpx/images/buy-package/logo.png';

const BuyPackage = () => {
    const [saleInfo, setSaleInfo] = useState(undefined);

    const nftSaleContractAddress = new Address(contracts.NftSale.address);
    const nftSaleContract = new SmartContract({ address: nftSaleContractAddress, abi: contracts.NftSale.abi });

    const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, { timeout: 30000 });

    const { address: userAddress } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();

    useEffect(() => {
        (async () => {
          try {
            const query = nftSaleContract.createQuery({
              func: new ContractFunction('getSaleInfo'),
              args: []
            });
    
            const queryResponse = await networkProvider.queryContract(query);
                
            const resultsParser = new ResultsParser();
            const endpointDefinition = nftSaleContract.getEndpoint('getSaleInfo');
    
            const { firstValue } = resultsParser.parseQueryResponse(queryResponse, endpointDefinition);
    
            const config = firstValue?.valueOf();
    
            setSaleInfo(config);
          } catch(error) {
            console.log(error);
          }
        })();
    }, [hasPendingTransactions]);

    const buy = (amount) => {
        if(!userAddress) {
            return;
        }

        (async () => {
            try {
                const tx = nftSaleContract.methodsExplicit.buy([])
                    .withValue(
                        TokenPayment.egldFromBigInteger(amount)
                    )
                    .buildTransaction();
        
                const rawTx = {
                    value: amount.toString(),
                    data: tx.getData().toString(),
                    receiver: contracts.NftSale.address,
                    gasLimit: '60000000'
                };
        
                await sendTransactions({
                    transactions: [rawTx],
                    transactionsDisplayInfo: {
                        processingMessage: 'Processing Transaction',
                        errorMessage: 'Transaction Failed',
                        successMessage: 'Transaction Success',
                    },
                    redirectAfterSign: false,
                });
            } catch(error) {
                console.log(error);
            }
        })();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container className='buy-package'>
                    <Row>
                        <Col xl="9">
                            <Row>
                                <Col sm="4">
                                    <div className='nft-package'>
                                        <img src={EGLD} width={60} height={60} />

                                        <h3>
                                            { saleInfo ? shortenEsdt(convertWeiToEsdt(saleInfo.first_pack_price)) : '...' } $EGLD
                                        </h3>

                                        <p>
                                            {saleInfo? saleInfo.first_pack_nfts.toNumber() : '...'} Random NFTs (ECPx)
                                        </p>

                                        <p>
                                            {saleInfo ? shortenEsdt(convertWeiToEsdt(saleInfo.first_pack_ft)) : '...'} $ECPx
                                        </p>

                                        <a href='https://elrondcyberpunks.com/roadmap/' target='_blank' rel="noreferrer">
                                            Check ECPx Utility
                                        </a>
                                        
                                        <button
                                            disabled={(
                                                saleInfo && (
                                                    saleInfo.member_count.toNumber() < saleInfo.first_pack_nfts.toNumber() ||
                                                    saleInfo.ecpx_amount.lt(saleInfo.first_pack_ft)
                                                )) ? true : false }
                                            onClick={() => {
                                                if(saleInfo) {
                                                    buy(saleInfo.first_pack_price);
                                                }
                                            }}
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                </Col>

                                <Col sm="4">
                                    <div className='nft-package'>
                                        <img src={EGLD} width={60} height={60} />

                                        <h3>
                                            { saleInfo ? shortenEsdt(convertWeiToEsdt(saleInfo.second_pack_price)) : '...' } $EGLD
                                        </h3>

                                        <p>
                                            {saleInfo ? saleInfo.second_pack_nfts.toNumber() : '...'} Random NFTs (ECPx)
                                        </p>

                                        <p>
                                            { saleInfo ? shortenEsdt(convertWeiToEsdt(saleInfo.second_pack_ft)) : '...'} $ECPx
                                        </p>

                                        <a href='https://elrondcyberpunks.com/roadmap/' target='_blank' rel="noreferrer">
                                            Check ECPx Utility
                                        </a>
                                        
                                        <button
                                            disabled={(
                                                saleInfo && (
                                                    saleInfo.member_count.toNumber() < saleInfo.second_pack_nfts.toNumber() ||
                                                    saleInfo.ecpx_amount.lt(saleInfo.second_pack_ft)
                                                )) ? true : false }
                                            onClick={() => {
                                                if(saleInfo) {
                                                    buy(saleInfo.second_pack_price);
                                                }
                                            }}
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                </Col>

                                <Col sm="4">
                                    <div className='nft-package'>
                                        <img src={EGLD} width={60} height={60} />

                                        <h3>
                                            { saleInfo ? shortenEsdt(convertWeiToEsdt(saleInfo.third_pack_price)) : '...' } $EGLD
                                        </h3>

                                        <p>
                                            {saleInfo ? saleInfo.third_pack_nfts.toNumber() : '...'} Random NFTs (ECPx)
                                        </p>

                                        <p>
                                            { saleInfo ? shortenEsdt(convertWeiToEsdt(saleInfo.third_pack_ft)) : '...'} $ECPx
                                        </p>

                                        <span>
                                            1 King of Clan + Emblem
                                        </span>

                                        <a href='https://elrondcyberpunks.com/roadmap/' target='_blank' rel="noreferrer">
                                            Check ECPx Utility
                                        </a>
                                        
                                        <button
                                            disabled={(
                                                saleInfo && (
                                                    saleInfo.member_count.toNumber() < saleInfo.third_pack_nfts.toNumber() ||
                                                    saleInfo.ecpx_amount.lt(saleInfo.third_pack_ft) ||
                                                    saleInfo.king_count.toNumber() < 1
                                                )) ? true : false }
                                            onClick={() => {
                                                if(saleInfo) {
                                                    buy(saleInfo.third_pack_price);
                                                }
                                            }}
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='package-info'>
                                <p>
                                    Package Info:
                                </p>

                                <span>
                                    Take your package now, stake it and start earning passive income immediately. If you own the King of Clan, it will allow you to participate in DAO battles, organize your clan and decide for yourself where and how to invest your earned rewards.
                                </span>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <div className='ecpx-info'>
                                        <img src={Logo} alt='' />

                                        <div className='d-flex flex-column'>
                                            <h5>
                                                Don't have any <span>$ECPx</span> token yet? <a href="https://ecpxhub.com/buy-ecpx?firstToken=EGLD&secondToken=ECPX-5cbfeb" target="_blank" rel="noreferrer">Buy Now</a>
                                            </h5>
                                            <p>Learn more: How to earn with it, where you can use it, and what its development goals will be - <a href="https://elrondcyberpunks.com/tokenomics/" target="_blank" rel="noreferrer">Tokenomics</a></p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Col>

                        <Col xl="3">
                            <Row>
                                <Col xs="12" className='mb-3'>
                                    <Balance />
                                </Col>

                                <Col xs="12">
                                    <CollectionInfo condensed />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BuyPackage;