import React from "react"
import { FaXTwitter, FaTelegram, FaDiscord } from "react-icons/fa6"

const FollowUs = () => {
  return (
    <React.Fragment>
      <div className="black-card d-flex justify-content-between align-items-center">
        <div style={{ fontSize: "16px", color: "white", fontWeight: "500" }}>
          Follow Us
        </div>
        <div className="d-flex gap-2">
          <a
            className="external-link"
            href="https://twitter.com/ElrondCyberpunk"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-box">
              <FaXTwitter />
            </div>
            <div className="link-name">Twitter</div>
          </a>

          <a
            className="external-link"
            href="https://discord.gg/PUdsURJyYe"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-box">
              <FaDiscord />
            </div>
            <div className="link-name">Discord</div>
          </a>

          <a
            className="external-link"
            href="https://t.me/+DxFz1bJ1ZwdmY2I0"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-box">
              <FaTelegram />
            </div>
            <div className="link-name">Telegram</div>
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FollowUs
