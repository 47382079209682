import React from "react"

import { Container, Row, Col } from "reactstrap"

import Balance from "components/Common/Balance"
import MyTokens from "components/Common/MyTokens"

import { Link } from "react-router-dom"

import Store from "assets/ecpx/images/marketplace/store.png"
import LogoOnly from "assets/ecpx/images/dashboard/logo-sm.png"

const Marketplace = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <Col xl="9">
              <Row className="marketplace-body">
                <h3>
                  Do you want to sell everything, everywhere, at any time with
                  MultiversX?
                </h3>

                <h5>
                  Create your online store in a secure Web3 environment with
                  just a few clicks.
                </h5>

                <h6>Coming soon!</h6>

                <img src={Store} alt="" />
              </Row>
            </Col>

            <Col xl="3">
              <Row>
                <Col xs="12">
                  <Balance />
                </Col>

                <Col xs="12">
                  <Link to="/buy-ecpx" className="buy-token-panel">
                    <img src={LogoOnly} />
                    <span>
                      <i className="bx bx-chart" />
                      &nbsp; BUY $ECPx TOKEN
                    </span>
                  </Link>
                </Col>

                <Col xs="12">
                  <MyTokens />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Marketplace
