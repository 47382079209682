import {
  useGetAccountInfo,
  useGetNetworkConfig,
} from "@multiversx/sdk-dapp/hooks"
import "assets/scss/datatables.scss"
import { wallet } from "common/data/crypto"
import withRouter from "components/Common/withRouter"
import { getAccountDetailFromApi, shortenAddress } from "helpers/mvx"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import WalletActivities from "./walletActivities"
import { axiosApi } from "helpers/api_helper"
import {
  GET_TOTAL_SEND_AMOUNT,
  GET_TOTAL_RECEIVE_AMOUNT,
} from "helpers/url_helper"
import { convertWeiToEsdt, shortenEsdt } from "utils"
import { getEsdtTokens } from "utils/elrond-api"
import { ECPX_TOKEN_ID, ONE, WEGLD } from "helpers/constants"
import { ElrondApiUrl } from "configs"
import imgEgld from "assets/images/coins/EGLD.svg"
import EcompassChart from "components/custom/EcompassChart"
import BigNumber from "bignumber.js/bignumber"
import axios from "axios"
import { useSelector } from "react-redux"
import MyTokens from "components/Common/MyTokens"

const MvxAddress = ({ explorerAddress, address }) => {
  return (
    <a
      href={`${explorerAddress}/accounts/${address}`}
      target="_blank"
      rel="noreferrer"
      style={{ color: "#33ffd0bf" }}
    >
      {shortenAddress(address)}
    </a>
  )
}

const CryptoWallet = () => {
  const [isMenu, setIsMenu] = useState(false)
  document.title = "My Wallet | ECPx HUB"

  const {
    account: { address: address, balance: balance },
    shard,
  } = useGetAccountInfo()
  const {
    network: { apiAddress, explorerAddress },
  } = useGetNetworkConfig()

  const [herotag, setHerotag] = useState("")
  const [totalSendAmount, setTotalSendAmount] = useState(0)
  const [totalReceiveAmount, setTotalReceiveAmount] = useState(0)
  const [mainTokens, setMainTokenInfos] = useState([])
  const [ecpxBalance, setEcpxBalance] = useState(0)
  const [oneBalance, setOneBalance] = useState(0)

  const { egld, availableBalance } = useSelector(state => state.Coin)

  useEffect(() => {
    ;(async () => {
      const _accountDetail = await getAccountDetailFromApi(apiAddress, address)
      console.log("_accountDetail", _accountDetail)
      if (_accountDetail && _accountDetail.username) {
        const _herotag = _accountDetail.username.slice(0, -7)
        setHerotag(_herotag)
      }

      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/accounts/${address}/tokens/${ECPX_TOKEN_ID}`
        )
        setEcpxBalance(data?.balance)
      } catch {}

      try {
        const { data } = await axios.get(
          `${ElrondApiUrl}/accounts/${address}/tokens/${ONE}`
        )
        setOneBalance(data?.balance)
      } catch {}
    })()

    getTotalSendAmount(address)
    getTotalReceiveAmount(address)
  }, [address, apiAddress])

  useEffect(() => {
    ;(async () => {
      const tokens = await getEsdtTokens([ECPX_TOKEN_ID, ONE])
      setMainTokenInfos(tokens)
    })()
  }, [])

  const getTotalSendAmount = account => {
    axiosApi.get(`${GET_TOTAL_SEND_AMOUNT}/${account}`).then(res => {
      setTotalSendAmount(res.data.total_amount)
    })
  }

  const getTotalReceiveAmount = account => {
    axiosApi.get(`${GET_TOTAL_RECEIVE_AMOUNT}/${account}`).then(res => {
      setTotalReceiveAmount(res.data.total_amount)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row style={{ rowGap: "28px" }}>
            <Col xl="4">
              <Card className="h-100">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-4">
                      <i className="mdi mdi-account-circle text-success h1" />
                    </div>

                    <div className="flex-grow-1">
                      <div className="text-muted">
                        <h5>
                          {herotag ? (
                            herotag
                          ) : (
                            <MvxAddress
                              explorerAddress={explorerAddress}
                              address={address}
                            />
                          )}
                        </h5>
                        <p className="mb-0">Shard {shard}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top">
                  <Row>
                    <Col sm="6">
                      <div>
                        <p className="text-muted mb-2">Available Balance</p>
                        <h5>
                          ${" "}
                          {availableBalance
                            ? availableBalance +
                              (address
                                ? Number(
                                    shortenEsdt(
                                      convertWeiToEsdt(
                                        new BigNumber(balance).multipliedBy(
                                          egld
                                        )
                                      )
                                    )
                                  )
                                : 0)
                            : 0}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="text-sm-end mt-4 mt-sm-0">
                        <p className="text-muted mb-2">Since last month</p>
                        <h5>
                          + $0
                          <span className="badge bg-success ms-2 align-bottom">
                            0 %
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="border-top">
                  <p className="text-muted mb-4">In this month</p>
                  <div className="text-center">
                    <Row>
                      <Col sm="6">
                        <div>
                          <div className="font-size-24 text-success mb-2">
                            <i className="bx bx-send" />
                          </div>
                          <p className="text-muted mb-2">Send</p>
                          <h5>{totalSendAmount ? totalSendAmount : 0} $ECPX</h5>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mt-4 mt-sm-0">
                          <div className="font-size-24 text-success mb-2">
                            <i className="bx bx-import" />
                          </div>

                          <p className="text-muted mb-2">receive</p>
                          <h5>
                            {totalReceiveAmount ? totalReceiveAmount : 0} $ECPX
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col className="d-flex flex-column" xl="8">
              <Row>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <img src={imgEgld} width={"30px"} />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-white mb-2">EGLD</p>
                          <h5 className="mb-0">
                            <span className="font-size-14 text-muted">
                              {address
                                ? shortenEsdt(
                                    convertWeiToEsdt(new BigNumber(balance)),
                                    2
                                  )
                                : 0}
                              {" EGLD "}= $
                              {mainTokens.length > 0 && address
                                ? shortenEsdt(
                                    convertWeiToEsdt(
                                      new BigNumber(balance).multipliedBy(egld)
                                    )
                                  )
                                : 0}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <img
                            src={
                              mainTokens.length > 0
                                ? mainTokens.filter(
                                    row => row.ticker == "ONE"
                                  )[0].assets.pngUrl
                                : "#"
                            }
                            width={"30px"}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-white mb-2">ONE</p>
                          <h5 className="mb-0">
                            <span className="font-size-14 text-muted">
                              {shortenEsdt(
                                convertWeiToEsdt(new BigNumber(oneBalance)),
                                2
                              )}
                              {" ONE = $ "}
                              {mainTokens.length > 0
                                ? shortenEsdt(
                                    convertWeiToEsdt(
                                      new BigNumber(oneBalance)
                                    ).multipliedBy(
                                      mainTokens.filter(
                                        row => row.ticker == "ONE"
                                      )[0].price
                                    ),
                                    5
                                  )
                                : "0"}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <img
                            src={
                              mainTokens.length > 0
                                ? mainTokens.filter(
                                    row => row.ticker == "ECPX"
                                  )[0].assets.pngUrl
                                : "#"
                            }
                            width={"30px"}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-white mb-2">ECPX</p>
                          <h5 className="mb-0">
                            <span className="font-size-14 text-muted">
                              {shortenEsdt(
                                convertWeiToEsdt(new BigNumber(ecpxBalance)),
                                2
                              )}
                              {" ECPX = $ "}
                              {mainTokens.length > 0
                                ? shortenEsdt(
                                    convertWeiToEsdt(
                                      new BigNumber(ecpxBalance)
                                    ).multipliedBy(
                                      mainTokens.filter(
                                        row => row.ticker == "ECPX"
                                      )[0].price
                                    ),
                                    5
                                  )
                                : "0"}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{ flexGrow: "1" }}>
                <EcompassChart baseTokenTicker="USDC" />
                {/* <WalletOverView wallet={wallet} /> */}
              </div>
            </Col>
            <Col xm="4">
              <MyTokens />
            </Col>
            <Col xl="8">
              <WalletActivities />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CryptoWallet.propTypes = {
  wallet: PropTypes.any,
  onGetWallet: PropTypes.func,
}

export default withRouter(CryptoWallet)
