import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { axiosApi } from "helpers/api_helper"
import { GET_TOP_WARRIORS } from "helpers/url_helper"
import { BlinkBlur } from "react-loading-indicators"
import { loadingDelayTime } from "helpers/constants"
import { shortenAddress } from "utils"

const TopWarriors = () => {
  const [topWarriors, setTopWarriors] = useState([])
  const [period, setPeriod] = useState("All")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getTopWarriors()
  }, [])

  const getTopWarriors = (date_range = period) => {
    setIsLoading(true)
    axiosApi
      .get(`${GET_TOP_WARRIORS}/${date_range}`)
      .then(res => {
        setTopWarriors(res.data)
        setTimeout(() => {
          setIsLoading(false)
        }, [loadingDelayTime])
      })
      .catch(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, [loadingDelayTime])
      })
  }

  const togglePeriodChecked = value => {
    setPeriod(value)
    getTopWarriors(value)
  }

  return (
    <>
      <div className="top-clans-card p-4 h-100">
        <div className="title">
          <h3>MVP,s</h3>

          <div className="btn-group" role="group">
            <input
              type="radio"
              className="btn-check"
              name="btnradio_warrior"
              id="btnradio_warrior_3m"
              autoComplete="off"
              onClick={() => togglePeriodChecked("3m")}
            />
            <label
              className="btn btn-outline-success"
              htmlFor="btnradio_warrior_3m"
            >
              3 Months
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio_warrior"
              id="btnradio_warrior_6m"
              autoComplete="off"
              onClick={() => togglePeriodChecked("6m")}
            />
            <label
              className="btn btn-outline-success"
              htmlFor="btnradio_warrior_6m"
            >
              6 Months
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio_warrior"
              id="btnradio_warrior_all"
              autoComplete="off"
              defaultChecked
              onClick={() => togglePeriodChecked("All")}
            />
            <label
              className="btn btn-outline-success"
              htmlFor="btnradio_warrior_all"
            >
              All
            </label>
          </div>
        </div>
        <div className="mt-3">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "480px" }}
            >
              <BlinkBlur color="#34c38f" size="medium" text="" textColor="" />
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="ecpx-table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-start">Most Valuable Players</th>
                    <th className="text-end" style={{ minWidth: "170px" }}>
                      Total $ECPx deposit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topWarriors.map((row, id) => {
                    return (
                      <tr key={`warrior-li-${id}`}>
                        <td scope="row">{id + 1}</td>
                        <td className="text-start">
                          {shortenAddress(row.account, 20)}
                        </td>
                        <td className="text-end">{row.total_deposit} $ECPX</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TopWarriors
