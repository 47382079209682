import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  DappProvider,
} from '@multiversx/sdk-dapp/wrappers';

import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';

import { isDev, walletConnectV2ProjectId } from 'configs';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DappProvider
        environment={isDev ? 'devnet' : 'mainnet'}
        customNetworkConfig={{
            name: 'ecpx-hub-frontend',
            apiTimeout: 12000,
            walletConnectV2ProjectId
        }}
        dappConfig={{
          shouldUseWebViewProvider: true,
        }}
    >
      <Provider store={store}>
        <React.Fragment>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.Fragment>
      </Provider>
      <TransactionsToastList />
      <NotificationModal />
      <SignTransactionsModals />
      <ToastContainer />
    </DappProvider>
  </LocalizationProvider>
);

serviceWorker.unregister()
