import {
    UPDATE_COIN_INFO,
    GET_COIN_INFO
} from "./actionTypes";

export const updateCoinInfo = (data) => ({
    type: UPDATE_COIN_INFO,
    payload: { data },
});

export const getCoinInfo = (data) => ({
    type: GET_COIN_INFO,
    payload: { data },
});