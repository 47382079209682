import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_NFT_INFO } from "./actionTypes";
import { updateNftInfo } from "./actions";

import axios from "axios";

import { ElrondApiUrl } from "configs";
import { GenesisCollection, XCollection, CLAN_INFO, PunkzCollection } from "helpers/constants";

const callApi = async (userAddress) => {
    const genesis = [];
    const x = [];
    const king = [];
    const emblem = [];
    const punkz = [];

    try {
        const { data } = await axios.get(`${ElrondApiUrl}/accounts/${userAddress}/nfts?size=200&collections=${GenesisCollection}`)

        for(const nft of data) {
            genesis.push({
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
            });
        }
    } catch (error) {
        console.log(error);
    }

    try {
        const { data } = await axios.get(`${ElrondApiUrl}/accounts/${userAddress}/nfts?size=200&collections=${PunkzCollection}`)

        for(const nft of data) {
            punkz.push({
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
            });
        }
    } catch (error) {
        console.log(error);
    }


    try {
        const { data } = await axios.get(`${ElrondApiUrl}/accounts/${userAddress}/nfts?size=200&collections=${XCollection}`)

        const emblem_of_king = [];
        
        for(const clan of CLAN_INFO) {
            emblem_of_king.push({
                [clan[4]]: clan[5]
            });
        }

        for(const nft of data) {
            const metadata_no = parseInt(nft.name.split('#')[1]);

            for(const clan of CLAN_INFO) {
                 if(metadata_no == clan[4]) {
                    king.push({
                        clan: clan[0],
                        identifier: nft.identifier,
                        collection: nft.collection,
                        nonce: nft.nonce,
                        image: nft.url,
                    });

                    break;
                } else if(metadata_no == clan[5]) {
                    emblem.push({
                        clan: clan[0],
                        identifier: nft.identifier,
                        nonce: nft.nonce,
                        image: nft.url,
                    });

                    break;
                } else if(metadata_no >= clan[1] && metadata_no <= clan[2] || metadata_no == clan[3]) {
                    x.push({
                        clan: clan[0],
                        collection: nft.collection,
                        identifier: nft.identifier,
                        nonce: nft.nonce,
                        image: nft.url,
                    });
                    
                    break;
                }
            }
        }
    } catch (error) {
        console.log(error);
    }

    return { genesis, x, king, emblem, punkz };
}

function* getNftInfo({ payload: { data: address} }) {
    try {
        const response = yield call(callApi, address);

        yield put(updateNftInfo(response));
    } catch (error) {
        console.log(error);
    }
}

function* nftSaga() {
    yield takeEvery(GET_NFT_INFO, getNftInfo);
}

export default nftSaga;
