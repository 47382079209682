import React, { useCallback, useEffect, useState } from "react"

import NFTLogo from "../../assets/ecpx/images/validator-staking/Genesis.png"
import ECPXLogo from "../../assets/ecpx/images/validator-staking/ECPx-lgAbt.png"
import BackgroundImg from "../../assets/ecpx/images/validator-staking/background.svg"
import {
  ECPX_TOKEN_ID,
  ElrondApiUrl,
  ElrondGatewayUrl,
  contracts,
} from "configs"
import {
  Address,
  AddressValue,
  ContractFunction,
  List,
  ResultsParser,
  SmartContract,
  TokenPayment,
  Type,
  U64Value,
} from "@multiversx/sdk-core/out"
import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out"
import {
  NFTStakeModal,
  NftUnstakeModal,
} from "components/ValidatorStaking/NFTSelectModal"
import { useDispatch, useSelector } from "react-redux"
import { getNftInfo } from "store/actions"
import {
  convertEsdtToWei,
  convertWeiToEsdt,
  nonceToGenesis,
  nonceToPunkz,
  shortenEsdt,
} from "utils"
import { toast } from "react-toastify"
import { sendTransactions } from "@multiversx/sdk-dapp/services"
import axios from "axios"
import { Col, Row } from "reactstrap"

export default function ValidatorStaking() {
  const [stakingInfo, setStakingInfo] = useState(undefined)
  const [userStakingInfo, setUserStakingInfo] = useState(undefined)
  const [nftSelectModal, setNftSelectModal] = useState(false)
  const [nftUnstakeModal, setNftUnstakeModal] = useState(false)
  const { hasPendingTransactions } = useGetPendingTransactions()
  const { genesis, punkz } = useSelector(state => state.Nft)
  const [stakedGenesis, setStakedGenesis] = useState([])
  const [stakedPunkz, setStakedPunkz] = useState([])
  const dispatch = useDispatch()

  const nftStakingContractAddress = new Address(contracts.NftStaking.address)
  const nftStakingContract = new SmartContract({
    address: nftStakingContractAddress,
    abi: contracts.NftStaking.abi,
  })
  const { address: userAddress } = useGetAccountInfo()

  const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, {
    timeout: 30000,
  })

  const onValidatorStake = useCallback((nfts, ecpx_amount) => {
    if (!userAddress) {
      return
    }

    if (!nfts.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")
    ;(async () => {
      try {
        const args = []

        for (const nft of nfts) {
          args.push(TokenPayment.nonFungible(nft.collection, nft.nonce))
        }

        args.push(
          TokenPayment.fungibleFromBigInteger(
            ECPX_TOKEN_ID,
            convertEsdtToWei(ecpx_amount),
            18
          )
        )
        if (!args.length) {
          toast.info("No NFT to stake")
          return
        }

        toast.info("Staking all Selected NFTs is processing")

        const tx = nftStakingContract.methodsExplicit
          .validatorStake([])
          .withMultiESDTNFTTransfer(args, new Address(userAddress))
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: userAddress,
          gasLimit: `${args.length < 50 ? 200 + args.length * 10 : 600}000000`,
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const onValidatorUnStake = useCallback(nfts => {
    if (!userAddress) {
      return
    }

    if (!nfts.length)
      return toast.info("PLEASE SELECT NFTS WHICH YOU WANT TO INTERACT.")

    let args = new List(
      new Type("u64"),
      nfts.map(el => new U64Value(el.nonce))
    )
    toast.info("Unstaking selected staked NFTs is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .validatorUnstake([args])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (!userAddress) return

    dispatch(getNftInfo(userAddress))
  }, [userAddress, hasPendingTransactions])

  useEffect(() => {
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getValidatorStakingInfo"),
          args: [],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition = nftStakingContract.getEndpoint(
          "getValidatorStakingInfo"
        )

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()
        console.log(config)

        setStakingInfo(config)
      } catch (error) {
        console.log(error)
      }
    })()
    ;(async () => {
      try {
        const query = nftStakingContract.createQuery({
          func: new ContractFunction("getValidatorUserStakingInfo"),
          args: [new AddressValue(new Address(userAddress))],
        })

        const queryResponse = await networkProvider.queryContract(query)

        const resultsParser = new ResultsParser()
        const endpointDefinition = nftStakingContract.getEndpoint(
          "getValidatorUserStakingInfo"
        )

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        )

        const config = firstValue?.valueOf()
        const staked_genesis_nonces = config.validator_user_staked_genesis_nfts
        const staked_genesis_tokens = []
        const staked_genesis_token_ids = []
        for (const nonce of staked_genesis_nonces) {
          const token_id = nonceToGenesis(nonce)

          staked_genesis_token_ids.push(token_id)
        }

        if (staked_genesis_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_genesis_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              staked_genesis_tokens.push({
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        const staked_punkz_nonces = config.validator_user_staked_punkz_nfts
        const staked_punkz_tokens = []
        const staked_punkz_token_ids = []
        for (const nonce of staked_punkz_nonces) {
          const token_id = nonceToPunkz(nonce)

          staked_punkz_token_ids.push(token_id)
        }

        if (staked_punkz_token_ids.length) {
          try {
            const { data } = await axios.get(
              `${ElrondApiUrl}/nfts?size=200&identifiers=${staked_punkz_token_ids.join(
                ","
              )}`
            )

            for (const nft of data) {
              staked_punkz_tokens.push({
                collection: nft.collection,
                identifier: nft.identifier,
                nonce: nft.nonce,
                image: nft.url,
              })
            }
          } catch (error) {
            console.log(error)
          }
        }

        setStakedGenesis(staked_genesis_tokens)
        setStakedPunkz(staked_punkz_tokens)
        setUserStakingInfo(config)

        // setClanType(config.clan_type.toString())
      } catch (error) {
        console.log(error)
      }
    })()
  }, [hasPendingTransactions])

  const claim = type => {
    if (!userAddress) {
      return
    }

    toast.info("Claiming Reward is processing")
    ;(async () => {
      try {
        const tx = nftStakingContract.methodsExplicit
          .claim([new U64Value(type)])
          .buildTransaction()

        const rawTx = {
          value: "0",
          data: tx.getData().toString(),
          receiver: contracts.NftStaking.address,
          gasLimit: "200000000",
        }

        await sendTransactions({
          transactions: [rawTx],
          transactionsDisplayInfo: {
            processingMessage: "Processing Transaction",
            errorMessage: "Transaction Failed",
            successMessage: "Transaction Success",
          },
          redirectAfterSign: false,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  return (
    <div className="page-content">
      <Row style={{ margin: "12px 0px", gap: "10px" }}>
        <Col xz="12">
          <div className="info-panel">
            <div className="info-row">
              <span className="label">Total Stakex $ECPX</span>
              <span className="value">
                {" "}
                {stakingInfo
                  ? shortenEsdt(
                      convertWeiToEsdt(
                        stakingInfo.validator_staked_ecpx_count.toNumber()
                      )
                    )
                  : 0}{" "}
              </span>
            </div>
            <hr />
            <div className="info-row">
              <span className="label">Reward Pool</span>
              <span className="value">
                {" "}
                {stakingInfo
                  ? shortenEsdt(
                      convertWeiToEsdt(
                        stakingInfo.validator_reward_pool_ecpx.toNumber()
                      )
                    )
                  : 0}{" "}
              </span>
            </div>
            <hr />
            <div className="info-row">
              <span className="label">Staker Count</span>
              <span className="value">
                {stakingInfo
                  ? stakingInfo.validator_staker_count.toNumber()
                  : 0}
              </span>
            </div>
          </div>
        </Col>
        <Col xz="12">
          <div className="info-panel">
            <div className="info-row">
              <span className="label">Genesis NFT</span>
              <span className="value">
                {" "}
                {stakingInfo
                  ? stakingInfo.validator_genesis_nft_count.toNumber()
                  : 0}{" "}
              </span>
            </div>
            <hr />
            <div className="info-row">
              <span className="label">RealPunkz NFT</span>
              <span className="value">
                {" "}
                {stakingInfo
                  ? stakingInfo.validator_punkz_nft_count.toNumber()
                  : 0}{" "}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div className="validator-staking-pool">
        <h1>Merge staking</h1>
        <h6>
          Merge 70,000 $ECPx per every Genesis NFT and receive ~25% APR or merge
          100,000 $ECPx per every RealPunkz for ~45% APR
        </h6>
        <div
          className="d-flex align-items-center card-container"
          style={{
            gap: "20px",
          }}
        >
          <div className="validator-card validator-nft d-flex align-items-center">
            <div className="description d-flex flex-column">
              <h2>Genesis & Real Punkz</h2>
              <p>
                Choose the amount of NFTs you want to lock in order to receive
                rewards.
              </p>
            </div>
            <img
              src={NFTLogo}
              height={200}
              style={{ borderRadius: "12px" }}
            ></img>
          </div>
          <h3>+</h3>
          <div className="validator-card ecpx-token d-flex align-items-center">
            <div className="description d-flex flex-column">
              <h2>ECPX Token</h2>
              <p>Amount of ECPX needed based on NFTs available to stake.</p>
            </div>
            <img src={ECPXLogo} height={200}></img>
          </div>
        </div>
        <div className="user-staking">
          <span>Genesis & Real Punkz NFTs + ECPX Tokens</span>
          <div className="label-item">
            <span className="label">NFTs Staked</span>
            <span className="value">
              {userStakingInfo
                ? userStakingInfo.validator_user_staked_tokens.toNumber()
                : 0}
            </span>
          </div>
          <div className="label-item">
            <span className="label">ECPX Staked</span>
            <span className="value">
              {userStakingInfo
                ? shortenEsdt(
                    convertWeiToEsdt(
                      userStakingInfo.validator_user_staked_ecpx.toNumber()
                    )
                  )
                : 0}{" "}
              $ECPX
            </span>
          </div>
          <div className="label-item">
            <span className="label">Genesis APR</span>
            <span className="value">
              {stakingInfo
                ? stakingInfo.validator_basic_ecpx_apr.toNumber()
                : 0}
              %
            </span>
          </div>
          <div className="label-item">
            <span className="label">Real Punkz APR</span>
            <span className="value">
              {stakingInfo
                ? stakingInfo.validator_punkz_ecpx_apr.toNumber()
                : 0}
              %
            </span>
          </div>
          <div className="label-item">
            <span className="label">Rewards</span>
            <span className="value">
              {userStakingInfo
                ? userStakingInfo.validator_user_ecpx_reward.toNumber()
                : 0}{" "}
              $ECPX
            </span>
          </div>
          <div className="actions">
            <button className="btn btn-outline claim" onClick={() => claim(4)}>
              Claim
            </button>
            <button
              className="btn btn-outline stake"
              onClick={() => setNftSelectModal(true)}
            >
              + Stake
            </button>
            <button
              className="btn btn-outline unstake"
              onClick={() => setNftUnstakeModal(true)}
            >
              - Unstake
            </button>
          </div>
        </div>
      </div>
      {stakingInfo && (
        <NFTStakeModal
          nfts={[...genesis, ...punkz]}
          onStake={onValidatorStake}
          setModal={setNftSelectModal}
          isOpen={nftSelectModal}
          nftCollection="Staking"
          basicEcpxRate={stakingInfo?.validator_basic_ecpx_rate.toNumber()}
          punkzEcpxRate={stakingInfo?.validator_punkz_ecpx_rate.toNumber()}
        />
      )}
      <NftUnstakeModal
        nfts={[...stakedGenesis, ...stakedPunkz]}
        onUnstake={onValidatorUnStake}
        setModal={setNftUnstakeModal}
        isOpen={nftUnstakeModal}
        nftCollection="Unstaking"
      />
    </div>
  )
}
