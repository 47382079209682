import { all, fork } from "redux-saga/effects";

import LayoutSaga from "./layout/saga";
import CoinSaga from "./coin/saga";
import NftSaga from "./nft/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(CoinSaga),
    fork(NftSaga),
  ]);
}
