import React, { useEffect, useState, useCallback } from "react"
import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks"
import { sendTransactions } from "@multiversx/sdk-dapp/services"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers"
import {
  BigUIntValue,
  Address,
  ContractFunction,
  ResultsParser,
  SmartContract,
  BytesValue,
  BooleanValue,
  ArgSerializer,
  StringValue,
  TokenIdentifierValue,
} from "@multiversx/sdk-core/out"
import axios from "axios"
import BigNumber from "bignumber.js/bignumber"
import { useSearchParams } from "react-router-dom"
import clsx from "clsx"
import styles from "./styles.module.scss"
import { PaymentPanel } from "components/custom/PaymentPanel"
import { BigZero, coinNames } from "helpers/constants"
import {
  convertEsdtToWei,
  convertWeiToEsdt,
  getSymbol,
  convertBigNumberValueToLocalString,
} from "utils"
import {
  otherContracts,
  ElrondApiUrl,
  ElrondGatewayUrl,
  ECPX_TOKEN_ID,
  WEGLD_TOKEN_ID,
} from "configs"
import { toast } from "react-toastify"
import "./style.scss"
import { AiOutlineSwap } from "react-icons/ai"

const Swap = () => {
  // document.title = "Swap | ECPX HUB"
  const {
    account: { address: userAddress, balance: userBalance, shard },
  } = useGetAccountInfo()
  const { hasPendingTransactions } = useGetPendingTransactions()
  const [searchParams, setSearchParams] = useSearchParams()

  const [slippage, setSlippage] = useState(0.01)

  const [isFixedInput, setIsFixedInput] = useState(true)
  const [isFixedoutput, setIsFixedOutput] = useState(false)

  const [firstTokenAmount, setFirstTokenAmount] = useState(BigZero)
  const [firstTokenAmountWithoutSlippage, setFirstTokenAmountWithoutSlippage] =
    useState(BigZero)
  const [secondTokenAmount, setSecondTokenAmount] = useState(BigZero)

  const [wrapRequired, setWrapRequired] = useState(false)
  const [unwrapRequired, setUnwrapRequired] = useState(false)

  const [firstToken, setFirstToken] = useState("EGLD")
  const [secondToken, setSecondToken] = useState(ECPX_TOKEN_ID)
  const [isReversePosition, setIsReversePosition] = useState(false)

  const [validSecondTokens, setValidSecondTokens] = useState([ECPX_TOKEN_ID])
  const [validPaths, setValidPaths] = useState([])
  const [searchingRoutes, setSearchingRoutes] = useState(false)
  const [optimalPath, setOptimalPath] = useState([])

  const [pairInfos, setPairInfos] = useState([])
  const [activePairInfos, setActivePairInfos] = useState([])
  const [pairTokens, setPairTokens] = useState([])
  const [pairTokenInfos, setPairTokenInfos] = useState({})
  const [swapPaths, setSwapPaths] = useState([])

  const [showModal, setShowModal] = useState(false)

  const viewMethod = useCallback(
    async ({ contract, method, args = [] }) => {
      const { address, abi } = contract

      const contractAddress = new Address(address)
      // const contractAbi = new SmartContractAbi(abi);
      const smartContract = new SmartContract({
        address: contractAddress,
        abi: abi,
      })

      const query = smartContract.createQuery({
        func: new ContractFunction(method),
        args,
      })

      const networkProvider = new ProxyNetworkProvider(ElrondGatewayUrl, {
        timeout: 10000,
      })
      const queryResponse = await networkProvider.queryContract(query)

      const resultsParser = new ResultsParser()
      const endpointDefinition = smartContract.getEndpoint(method)

      const { firstValue } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      )

      return firstValue?.valueOf()
    },
    [userAddress]
  )

  useEffect(() => {
    if (hasPendingTransactions) return
    ;(async () => {
      try {
        const pairs = await viewMethod({
          contract: otherContracts.Onedex,
          method: "viewPairs",
        })

        const allPairs = []
        const activePairs = []

        const availableTokens = []
        const allTokens = []
        const tokenInfos = {}

        availableTokens.push("EGLD")

        tokenInfos["EGLD"] = {
          identifier: "EGLD",
          balance: convertWeiToEsdt(userAddress ? userBalance : 0, 18),
          decimal: 18,
        }

        for (const pair of pairs) {
          const first_token_id = pair.first_token_id
          const second_token_id = pair.second_token_id
          const lp_token_id = pair.lp_token_id

          // console.log(pair.pair_id.toNumber(), pair);

          if (!allTokens.includes(first_token_id)) {
            allTokens.push(first_token_id)
            availableTokens.push(first_token_id)
          }

          if (!allTokens.includes(second_token_id)) {
            allTokens.push(second_token_id)
            availableTokens.push(second_token_id)
          }

          if (lp_token_id && !allTokens.includes(lp_token_id)) {
            allTokens.push(lp_token_id)
          }
        }

        // if (allTokens.length) {
        //   try {
        //     const { data } = await axios.get(
        //       `${ElrondApiUrl}/tokens?size=1000&identifiers=${allTokens.join(
        //         ","
        //       )}`
        //     )

        //     for (const coin of data) {
        //       tokenInfos[coin?.identifier] = {
        //         identifier: coin?.identifier,
        //         balance: BigZero,
        //         decimal: coin?.decimals,
        //       }
        //     }
        //   } catch (error) {
        //     console.log(error)
        //   }
        // }

        if (allTokens.length) {
          console.log(allTokens.length)
          const batchSize = 300; // Define the maximum number of tokens per request
          const batches = Math.ceil(allTokens.length / batchSize); // Calculate the number of batches
      
          for (let i = 0; i < batches; i++) {
              const startIdx = i * batchSize;
              const endIdx = Math.min((i + 1) * batchSize, allTokens.length);
              const batchTokens = allTokens.slice(startIdx, endIdx);
      
              try {
                  const { data } = await axios.get(
                      `${ElrondApiUrl}/tokens?size=1000&identifiers=${batchTokens.join(",")}`
                  );
      
                  for (const coin of data) {
                      tokenInfos[coin?.identifier] = {
                          identifier: coin?.identifier,
                          balance: BigZero,
                          decimal: coin?.decimals,
                      };
                  }
              } catch (error) {
                  console.log(error);
              }
          }
      }
      

        // if (allTokens.length && userAddress) {
        //   try {
        //     const { data } = await axios.get(
        //       `${ElrondApiUrl}/accounts/${userAddress}/tokens?size=1000&identifiers=${allTokens.join(
        //         ","
        //       )}`
        //     )

        //     for (const token of data) {
        //       const token_id = token.identifier
        //       const decimal = token.decimals
        //       const balance = new BigNumber(token.balance || 0)

        //       tokenInfos[token_id] = {
        //         identifier: token_id,
        //         balance: convertWeiToEsdt(balance, decimal),
        //         decimal: decimal,
        //       }
        //     }
        //   } catch (error) {
        //     console.log(error)
        //   }
        // }

        if (allTokens.length && userAddress) {
          const batchSize = 300; // Define the maximum number of tokens per request
          const batches = Math.ceil(allTokens.length / batchSize); // Calculate the number of batches
      
          for (let i = 0; i < batches; i++) {
              const startIdx = i * batchSize;
              const endIdx = Math.min((i + 1) * batchSize, allTokens.length);
              const batchTokens = allTokens.slice(startIdx, endIdx);
      
              try {
                  const { data } = await axios.get(
                      `${ElrondApiUrl}/accounts/${userAddress}/tokens?size=1000&identifiers=${batchTokens.join(",")}`
                  );
      
                  for (const token of data) {
                      const token_id = token.identifier;
                      const decimal = token.decimals;
                      const balance = new BigNumber(token.balance || 0);
      
                      tokenInfos[token_id] = {
                          identifier: token_id,
                          balance: convertWeiToEsdt(balance, decimal),
                          decimal: decimal,
                      };
                  }
              } catch (error) {
                  console.log(error);
              }
          }
      }
      

        for (const pair of pairs) {
          const first_token_id = pair.first_token_id
          const second_token_id = pair.second_token_id
          const lp_token_id = pair.lp_token_id

          allPairs.push({
            ...pair,
            first_token_balance: tokenInfos[first_token_id].balance,
            first_token_decimal: tokenInfos[first_token_id].decimal,
            second_token_balance: tokenInfos[second_token_id].balance,
            second_token_decimal: tokenInfos[second_token_id].decimal,
            lp_token_balance:
              lp_token_id && tokenInfos[lp_token_id]
                ? tokenInfos[lp_token_id].balance
                : BigZero,
            lp_token_decimal: 18,
          })
        }

        for (const pair of allPairs) {
          if (pair.state.name === "Active") {
            activePairs.push(pair)
          }
        }

        setPairInfos(allPairs)
        setActivePairInfos(activePairs)
        setPairTokens(availableTokens)
        setPairTokenInfos(tokenInfos)
        // console.log('tokenInfos', tokenInfos);
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userAddress, hasPendingTransactions])

  const viewMultiPathAmountOut = async (path, amount_in) => {
    if (path.length < 2) return BigZero

    // console.log(pairTokenInfos[path[path.length - 1]].decimal);

    try {
      const args = []

      args.push(
        new BigUIntValue(
          convertEsdtToWei(amount_in, pairTokenInfos[path[0]]?.decimal)
        )
      )

      for (const token of path) {
        args.push(BytesValue.fromUTF8(token))
      }

      const amount = await viewMethod({
        contract: otherContracts.Onedex,
        method: "getMultiPathAmountOut",
        args,
      })

      return convertWeiToEsdt(
        amount,
        pairTokenInfos[path[path.length - 1]].decimal
      )
    } catch (error) {
      console.log(error)
      return BigZero
    }
  }

  useEffect(() => {
    if (isFixedoutput) return

    if (BigZero.gte(firstTokenAmount) || !firstToken || !secondToken) {
      if (BigZero.lt(secondTokenAmount)) {
        setSecondTokenAmount(BigZero)
      }

      return
    }

    const timer = setTimeout(async () => {
      let bestPath = [],
        bestOutAmount = BigZero

      setSearchingRoutes(true)

      // console.log('find output amount');

      for (const path of validPaths) {
        const outAmount = await viewMultiPathAmountOut(path, firstTokenAmount)
        // console.log(path, outAmount.toString());

        if (bestOutAmount.lt(outAmount)) {
          bestOutAmount = outAmount
          bestPath = path.slice(0)
        }
      }

      // const resultAmount = shortenEsdt(bestOutAmount);
      setSecondTokenAmount(new BigNumber(bestOutAmount))
      setOptimalPath(bestPath)
      setSearchingRoutes(false)

      //   console.log('bestPath', bestPath);
    }, 500)

    return () => clearTimeout(timer)
  }, [firstTokenAmount, firstToken, secondToken, validPaths])

  useEffect(() => {
    let paths = []
    const depth_1_paths = []
    const depth_2_paths = []
    const depth_3_paths = []
    const depth_4_paths = []

    // depth 1
    for (const pair of activePairInfos) {
      depth_1_paths.push([pair.first_token_id, pair.second_token_id])
      depth_1_paths.push([pair.second_token_id, pair.first_token_id])
    }

    // depth 2
    for (const first_path of depth_1_paths) {
      for (const second_path of depth_1_paths) {
        if (
          first_path[1] === second_path[0] &&
          first_path[0] !== second_path[1]
        ) {
          depth_2_paths.push(first_path.concat([second_path[1]]))
        }
      }
    }

    // depth 3
    for (const first_path of depth_2_paths) {
      for (const second_path of depth_1_paths) {
        if (
          first_path[2] === second_path[0] &&
          first_path[0] !== second_path[1] &&
          first_path[1] !== second_path[1]
        ) {
          depth_3_paths.push(first_path.concat([second_path[1]]))
        }
      }
    }

    // depth 4
    for (const first_path of depth_3_paths) {
      for (const second_path of depth_1_paths) {
        if (
          first_path[3] === second_path[0] &&
          first_path[0] !== second_path[1] &&
          first_path[1] !== second_path[1] &&
          first_path[2] !== second_path[1]
        ) {
          depth_4_paths.push(first_path.concat([second_path[1]]))
        }
      }
    }

    paths = paths.concat(depth_1_paths)
    paths = paths.concat(depth_2_paths)
    paths = paths.concat(depth_3_paths)
    paths = paths.concat(depth_4_paths)

    setSwapPaths(paths)
  }, [activePairInfos])

  useEffect(() => {
    setFirstTokenAmount(BigZero)
    setSecondTokenAmount(BigZero)
    setOptimalPath([])

    if (firstToken && secondToken) {
      const paths = []

      let token_in = firstToken
      let token_out = secondToken

      if (firstToken === coinNames[0]) {
        token_in = coinNames[1]
        setWrapRequired(true)
      } else {
        setWrapRequired(false)
      }

      if (secondToken === coinNames[0]) {
        token_out = coinNames[1]
        setUnwrapRequired(true)
      } else {
        setUnwrapRequired(false)
      }

      for (const path of swapPaths) {
        if (path[0] === token_in && path[path.length - 1] === token_out) {
          paths.push(path)
        }
      }

      setValidPaths(paths)
      //   console.log('ValidPaths', paths);

      setSearchParams({
        firstToken: firstToken,
        secondToken: secondToken,
      })
    }
  }, [firstToken, secondToken, swapPaths])

  const onSwap = async () => {
    if (!userAddress) {
      toast.error("Connect your wallet")
      return
    }

    if (!isFixedInput) {
      toast.error("Must be Fixed-Input-Swap")
      return
    }

    if (
      BigZero.gte(firstTokenAmount) ||
      firstTokenAmount.gt(pairTokenInfos[firstToken]?.balance) ||
      BigZero.gte(secondTokenAmount) ||
      !firstToken ||
      !secondToken ||
      optimalPath.length < 2
    )
      return

    // console.log(
    //   isFixedInput,
    //   isFixedoutput,
    //   firstToken,
    //   secondToken,
    //   wrapRequired,
    //   unwrapRequired
    // )

    try {
      const txs = []
      const amount = convertEsdtToWei(
        firstTokenAmount,
        pairTokenInfos[firstToken]?.decimal
      )

      if (wrapRequired) {
        let wrapAddress = otherContracts.Wrap0.address
        if (shard === 1) {
          wrapAddress = otherContracts.Wrap1.address
        } else if (shard === 2) {
          wrapAddress = otherContracts.Wrap2.address
        }

        const wrapTx = {
          value: amount,
          data: "wrapEgld",
          receiver: wrapAddress,
          gasLimit: 5_000_000,
        }

        txs.push(wrapTx)
      }

      const args = [
        new TokenIdentifierValue(wrapRequired ? WEGLD_TOKEN_ID : firstToken),
        new BigUIntValue(amount),
        new StringValue("swapMultiTokensFixedInput"),
        new BigUIntValue(
          convertEsdtToWei(
            secondTokenAmount.multipliedBy(1 - slippage),
            pairTokenInfos[secondToken]?.decimal
          )
        ), // min_amount_out
        new BooleanValue(unwrapRequired), // unwrap_required
      ]
      for (const path of optimalPath) {
        args.push(BytesValue.fromUTF8(path))
      }
      const { argumentsString } = new ArgSerializer().valuesToString(args)
      const data = `ESDTTransfer@${argumentsString}`

      const tx = {
        value: 0,
        data,
        receiver: otherContracts.Onedex.address,
        gasLimit: 30_000_000,
      }
      txs.push(tx)

      const txName = "Swap"
      const { sessionId, error } = await sendTransactions({
        transactions: txs,
        transactionsDisplayInfo: {
          processingMessage: `Processing ${txName} Request`,
          errorMessage: `Error occured during ${txName} Request`,
          successMessage: `${txName} Request Successful`,
        },
        redirectAfterSign: false,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className={clsx(styles.card)}>
        <div className={styles.cardBody}>
          <div
            className="d-flex justify-content-start w-100"
            style={{ fontSize: 24, color: "white" }}
          >
            Swap
          </div>
          <div
            className=""
            style={{
              color: "white",
              fontSize: ".9rem",
              textAlign: "left",
            }}
          >
            The easiest way to quickly obtain the fastest scalable $ECPx token.
          </div>
          <div className="swap-inputs-container mt-4 mb-4">
            {/* <div className={styles.upDownContainer}>
                                            <div
                                                className={styles.upDown}
                                                onClick={() => {
                                                    if (firstToken && secondToken) {
                                                        const newFirstToken = secondToken;
                                                        const newSecondToken = firstToken;

                                                        setFirstToken(newFirstToken);
                                                        setSecondToken(newSecondToken);
                                                    }
                                                }}
                                            >
                                                <SwapVert style={{ color: 'white' }} />
                                            </div>
                                        </div> */}
            <PaymentPanel
              title="From"
              isFirstToken={true}
              isPayment
              tokens={isReversePosition ? validSecondTokens : pairTokens}
              setToken={setFirstToken}
              amount={firstTokenAmount}
              setSwapType={() => {
                setIsFixedInput(true)
                setIsFixedOutput(false)
              }}
              setAmount={setFirstTokenAmount}
              tokenId={firstToken}
              onClick={() => setShowFirstTokenModal(true)}
              pairTokenInfos={pairTokenInfos}
            />
            <div
              className="swap-token-position-button"
              onClick={() => {
                if (firstToken && secondToken) {
                  const newFirstToken = secondToken
                  const newSecondToken = firstToken
                  setFirstToken(newFirstToken)
                  setSecondToken(newSecondToken)

                  setIsReversePosition(!isReversePosition)
                }
              }}
            >
              <AiOutlineSwap style={{ color: "white" }} />
            </div>
            <PaymentPanel
              title="To"
              isFirstToken={false}
              isPayment
              tokens={isReversePosition ? pairTokens : validSecondTokens}
              setToken={setSecondToken}
              amount={secondTokenAmount}
              setSwapType={() => {
                setIsFixedInput(false)
                setIsFixedOutput(true)
              }}
              setAmount={setSecondTokenAmount}
              tokenId={secondToken}
              onClick={() => setShowSecondTokenModal(true)}
              pairTokenInfos={pairTokenInfos}
            />
          </div>

          {/* <div className={clsx(styles.swapInfo, 'mt-0')}>
                                        <div className={styles.swapInfoBody}>
                                        {
                                            searchingRoutes ? (
                                            <div className={'d-flex justify-content-between w-100 px-2 mt-2'}>
                                                <span>Searching for Routes</span>
                                                <Spinner className='text-white' animation="border" />
                                            </div>
                                            ) : (
                                            <>
                                                {
                                                optimalPath.length ? (
                                                    <div className='d-flex justify-content-between mt-2'>
                                                    <div className={'d-flex justify-content-start'}>
                                                        <span>Route</span>
                                                    </div>
                                                    <div className={'d-flex flex-column align-items-end'}>
                                                        <span>{optimalPath.map((token) => getSymbol(token)).join(' > ')}</span>
                                                    </div>
                                                    </div>
                                                ) : (
                                                    BigZero.lt(firstTokenAmount) ? (
                                                    <span>No swap route found.</span>
                                                    ) : (
                                                    <></>
                                                    )
                                                )
                                                }
                                                {
                                                optimalPath.length ? (
                                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                                    <div className={'d-flex justify-content-start'}>
                                                        <span>Rate</span>
                                                    </div>
                                                    <div className={'d-flex flex-column align-items-end'}>
                                                        {
                                                        (BigZero.lt(firstTokenAmount) && BigZero.lt(secondTokenAmount)) ? (
                                                            <>
                                                            <span>1 {getSymbol(firstToken)} ≃ {shortenEsdt(secondTokenAmount.dividedBy(firstTokenAmount))} {getSymbol(secondToken)}</span>
                                                            <span>1 {getSymbol(secondToken)} ≃ {shortenEsdt(firstTokenAmount.dividedBy(secondTokenAmount))} {getSymbol(firstToken)}</span>
                                                            </>
                                                        ) : (
                                                            <span>-</span>
                                                        )
                                                        }
                                                    </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                                }
                                            </>
                                            )
                                        }
                                        <div className={'d-flex justify-content-between w-100 mt-2'}>
                                            <span>Slippage Tolerance</span>
                                            <span>{slippage * 100}%</span>
                                        </div>
                                        <div className={'d-flex justify-content-end w-100 mt-2'}>
                                            <CustomButton
                                                onClick={() => setSlippage(0.0025)}
                                                className={clsx(styles.slippageBtn, slippage === 0.0025 ? styles.active : '')}
                                            >
                                                0.25%
                                            </CustomButton>
                                            <CustomButton
                                                onClick={() => setSlippage(0.01)}
                                                className={clsx(styles.slippageBtn, slippage === 0.01 ? styles.active : '')}
                                            >
                                                1%
                                            </CustomButton>
                                            <CustomButton
                                                onClick={() => setSlippage(0.025)}
                                                className={clsx(styles.slippageBtn, slippage === 0.025 ? styles.active : '')}
                                            >
                                                2.5%
                                            </CustomButton>
                                            <input className={styles.slippageInput} type='number' placeholder='custom'
                                            onChange={(e) => {
                                                const value = +e.target.value;

                                                if (value > 0 && value <= 100) {
                                                setSlippage(value / 100);
                                                } else {
                                                setSlippage(0.01);
                                                }
                                            }}
                                            />
                                        </div>
                                        </div>
                                    </div> */}

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <div style={{ fontSize: ".8rem", color: "#fff" }}>
                You will receive:{" "}
                <span style={{ color: "#10FFC6" }}>
                  {convertBigNumberValueToLocalString(secondTokenAmount)}
                </span>{" "}
                ${getSymbol(secondToken)}
              </div>
              <a
                className="learn-ecpx-link"
                target="_blank"
                rel="noreferrer"
                href="https://elrondcyberpunks.com/tokenomics"
              >
                Learn more about $ECPx
              </a>
            </div>

            {userAddress ? (
              <button
                className={clsx("btn btn-block", styles.swapButton)}
                disabled={hasPendingTransactions}
                onClick={onSwap}
              >
                Swap
              </button>
            ) : (
              <>
                {/* <button
                  color="primary"
                  className="connect-wallet d-flex align-items-center"
                  onClick={() => setShowModal(true)}
                >
                  <i className="bx bx-wallet mr-3" />
                  Connect Wallet
                </button> */}
                <div className="text-success">You must connect wallet.</div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Swap
