import React, { useEffect, useState } from 'react';

import { Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { BigZero } from 'helpers/constants';
import { shortenEsdt } from 'utils';
import { CustomButton } from '../CustomButton';
import styles from './styles.module.scss';
import { FastField } from 'formik';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange } = props;

    return (
        <NumericFormat
            {...props}
            getInputRef={ref}
            allowLeadingZeros={false}
            thousandSeparator
            valueIsNumericString
            onValueChange={(values, sourceInfo) => {
                onChange({
                    target: {
                        name: sourceInfo.source,
                        value: values.value,
                    },
                });
            }}
        />
    );
});

export const CustomInput = (
    {
        className,
        placeholder,
        value = BigZero,
        maxValue = BigZero,
        setAmount,
        noMax = false,
        leftAlign = false,
        isPayment = false,
        setSwapType,
        isDisabled = false,
    }
) => {
    const [inputString, setInputString] = useState('');

    useEffect(() => {
        updateInput();
    }, [value]);

    const updateInput = () => {
        // console.log(value.toString());

        let valueString = '';
        if (!BigZero.isEqualTo(value)) {
            const parts = parseFloat(value.toFixed(4)).toString().split('.');

            valueString += parts[0];

            if (parts.length > 1) {
                valueString = valueString + ('.' + parts[1].substring(0, 4));
            }
        }

        setInputString(valueString);
    };

    const useStyles = makeStyles({
        custom: {
            '& .MuiInputBase-input': {
                color: 'white !important',
                '-webkit-text-fill-color': 'white !important',
                padding: '10px 10px',
                // maxWidth: '200px',
                minWidth: '0px',
                fontSize: '16px !important',
            },
            '&': {
                flex: 1,
            },
        },
        right: {
            '& .MuiInputBase-input': {
                textAlign: 'right !important'
            }
        },
        left: {
            '& .MuiInputBase-input': {
                textAlign: 'left !important',
            }
        }
    });

    const classes = useStyles();

    return (
        <TextField
            disabled={isDisabled}
            className={clsx(className, classes.custom, leftAlign ? '' : classes.right)}
            placeholder={placeholder || ''}
            value={inputString}
            onClick={setSwapType}
            onChange={(e) => {
                setInputString(e.target.value);

                const newValue = new BigNumber(e.target.value || '0');

                if (!newValue.isEqualTo(value)) {
                    // if(!noMax && maxValue.lte(newValue)) {
                    //     setAmount(maxValue);
                    //     updateInput();
                    // } else {
                    //     setAmount(newValue);
                    // }
                    setAmount(newValue);
                }

            }}
            name='numberformat'
            variant="standard"
            InputProps={{
                disableUnderline: true,
                inputComponent: NumberFormatCustom,
                startAdornment: !isPayment ?
                    <></> :
                    noMax ?
                        <></> :
                        <CustomButton
                            className={styles.maxBtn}
                            onClick={() => {
                                setAmount(maxValue);
                                updateInput();
                            }}
                        >
                            MAX
                        </CustomButton>,
                endAdornment: noMax ?
                    <></> :
                    isPayment ?
                        <></> :
                        <CustomButton
                            className={styles.maxBtn}
                            onClick={() => {
                                setAmount(maxValue);
                                updateInput();
                            }}
                        >
                            MAX
                        </CustomButton>
            }}
            autoComplete='off'
        />
    );
};
