import axios from 'axios';

export async function getAccountDetailFromApi(apiAddress, address) {
    try {
        const url = `${apiAddress}/accounts/${address}`;
        const { data } = await axios(url);
        
        return data;
    } catch (err) {
        console.error('getAccountDetailFromApi', err);
        return undefined;
    }
};
