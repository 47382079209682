import React from "react"
import { Button } from "reactstrap"

const ClanDetails = props => {
  const openInNewTab = url => {
    window.open(url, "_blank")
  }

  const checkMarket = clan => {
    const itemsFilter = "All"
    const activeFilters = encodeURIComponent(
      `[{"trait":"Clan","attribute":"${clan}"}]`
    )

    const url = `https://www.frameit.gg/marketplace/ECPX-cdbb2d/items?itemsFilter=${itemsFilter}&activeFilters=${activeFilters}`

    window.open(url, "_blank")
  }

  return (
    <>
      <div className="rank-detail-card">
        {props.clan ? (
          <>
            <img
              className="logo"
              src={props.clan.clan_logo}
              alt="rank logo"
              width={"200px"}
            />

            <div className="content" style={{ marginTop: "-140px" }}>
              <div className="p-3">
                <div style={{ marginTop: "140px" }}>
                  <h3>{props.clan.clan_name}</h3>
                </div>
                <div className="d-flex gap-2 mt-3">
                  <Button
                    color="success"
                    outline
                    // style={{ fontSize: "12px" }}
                    onClick={() => checkMarket(props.clan.clan_name)}
                  >
                    Check Market
                  </Button>

                  <Button
                    color="link"
                    className="btn btn-link waves-effect"
                    style={{ color: "#34C38F" }}
                    onClick={() =>
                      openInNewTab("https://elrondcyberpunks.com/the-purples/")
                    }
                  >
                    Learn More
                  </Button>
                </div>
                <div
                  className="mt-3"
                  style={{
                    height: "200px",
                    overflowY: "scroll",
                    scrollbarWidth: "thin",
                    scrollbarColor: "transparent transparent",
                    paddingBottom: "15px",
                  }}
                >
                  {props.clan.clan_description}
                </div>
              </div>
              <span className="top"></span>
              <span className="right"></span>
              <span className="bottom"></span>
              <span className="left"></span>
            </div>
          </>
        ) : (
          <div>No clan selected</div>
        )}
      </div>
    </>
  )
}

export default ClanDetails
