import React, { FC, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

// const PAGE_SIZES: number[] = [8, 16, 24];
const THROTTLE_TIME = 500;

export const CustomPagination = ({
  pageCount,
  pageIndex,
  pageSize,
  gotoPage,
  disabled = false,
  // setPageSize,
}) => {
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;
  const [isInThrottle, setIsInThrottle] = useState(false);
  const previousPage = () => {
    if (canPreviousPage) {
      gotoPage(pageIndex - 1);
    }
  };
  const nextPage = () => {
    if (canNextPage) {
      gotoPage(pageIndex + 1);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-4">
      <div className="d-flex">
          <button
            className="btn btn-primary pagination-button"
            style={{ marginRight: '.5rem' }}
            onClick={() => {
              gotoPage(0);
              setIsInThrottle(true);
              setTimeout(() => {
                setIsInThrottle(false);
              }, THROTTLE_TIME);
            }}
            disabled={!canPreviousPage || isInThrottle || disabled}
          >
            <FaAngleDoubleLeft />
          </button>
          <button
            className="btn btn-primary pagination-button"
            onClick={() => {
              previousPage();
              setIsInThrottle(true);
              setTimeout(() => {
                setIsInThrottle(false);
              }, THROTTLE_TIME);
            }}
            disabled={!canPreviousPage || isInThrottle || disabled}
          >
            <FaAngleLeft />
          </button>
      </div>

      <div className="d-flex align-items-center" style={{ color: '#f0f0f0', marginLeft: '.8rem', marginRight: '.8rem' }}>
        <span>
          Page{" "}
          <span className="font-weight-bold">
            {pageIndex + 1}
          </span>{" "}
          of{" "}
          <span className="font-weight-bold">
            {pageCount}
          </span>
        </span>{" "}
      </div>

      <div className="d-flex">
          <button
            className="btn btn-primary pagination-button"
            style={{ marginRight: '.5rem' }}
            onClick={() => {
              nextPage();
              setIsInThrottle(true);
              setTimeout(() => {
                setIsInThrottle(false);
              }, THROTTLE_TIME);
            }}
            disabled={!canNextPage || isInThrottle || disabled}
          >
            <FaAngleRight />
          </button>
          <button
            className="btn btn-primary pagination-button"
            onClick={() => {
              gotoPage(pageCount - 1);
              setIsInThrottle(true);
              setTimeout(() => {
                setIsInThrottle(false);
              }, THROTTLE_TIME);
            }}
            disabled={!canNextPage || isInThrottle || disabled}
          >
            <FaAngleDoubleRight />
          </button>
      </div>
    </div>
  );
};
