import React from "react"

import BuyGenesis from "assets/ecpx/images/collection-info/genesis-buy.png"
import BuyEcpx from "assets/ecpx/images/collection-info/ecpx-buy.png"
import MintEcpx from "assets/ecpx/images/collection-info/ecpx-mint.png"

import Punk from "assets/ecpx/images/collection-info/punk.png"

const CollectionInfo = ({ condensed = false }) => {
  return (
    <div className="collection-info">
      {condensed ? (
        <h4 className="text-center">Elrond Cyberpunks</h4>
      ) : (
        <React.Fragment>
          <h3>Collections Info</h3>

          <h5>Genesis Info:</h5>

          <p>
            Supply: <span>344</span>
          </p>

          <p>
            Status: <span>Sold Out</span>
          </p>

          <p>
            ATH: <span>25 $EGLD</span>
          </p>

          <hr />

          <h5>ECPx Info:</h5>

          <p>
            Supply: <span>4004 + 20 Clan Emblems</span>
          </p>

          <p>
            Status: <span>Mintable</span>
          </p>

          <p>
            ATH: <span>7.10 $EGLD</span>
          </p>

          <hr />

          <h5>Battle Prize Pool:</h5>

          <p>
            For who: <span>Winners of the Battle</span>
          </p>

          <p>
            Status: <span>Custom</span>
          </p>

          <p>
            Prize: <span>Custom</span>
          </p>
        </React.Fragment>
      )}

      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <a
          href="https://xoxno.com/collection/MAXWELLS-a03c6e"
          target="_blank"
          rel="noreferrer"
        >
          <img src={BuyGenesis} alt="" />
        </a>

        <a
          href="https://xoxno.com/collection/ECPX-cdbb2d"
          target="_blank"
          rel="noreferrer"
        >
          <img src={BuyEcpx} alt="" />
        </a>

        <a
          href="https://www.frameit.gg/marketplace/ECPX-cdbb2d/mint"
          target="_blank"
          rel="noreferrer"
        >
          <img src={MintEcpx} alt="" />
        </a>

        <a
          href="https://elrondcyberpunks.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Punk} alt="" />
        </a>
      </div>
    </div>
  )
}

export default CollectionInfo
