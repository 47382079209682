import { ResultsParser } from "@multiversx/sdk-core/out";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { elrondProvider } from "./provider";

// if tx is not a Transaction instance but a plain object, "transformAndSignTransactions" is applied to the object so you don't need to call "refreshAccount" for sync nonce
export async function mvxDappSendTransactions(txs, txName) {
    const { sessionId, error } = await sendTransactions({
        transactions: txs,
        transactionsDisplayInfo: {
            processingMessage: `Processing ${txName} Request`,
            errorMessage: `Error occured during ${txName} Request`,
            successMessage: `${txName} Request Successful`,
        },
        redirectAfterSign: false,
    });

    return { sessionId, error };
}

export async function mvxQuery(interaction) {
    const query = interaction.check().buildQuery();
    const queryResponse = await elrondProvider.queryContract(query);
    console.log('queryResponse', queryResponse);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } =
      new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
    const output = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
    console.log('output', output);

    if (!firstValue || !returnCode.isSuccess()) {
        console.log('mvxQuery', firstValue, returnCode, returnMessage);
        throw Error(returnMessage);
    }

    return firstValue.valueOf();
}
