import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks"
import { ExplorerLink } from "@multiversx/sdk-dapp/UI"
import clsx from "clsx"
import React, { useState, useEffect } from "react"
import { Card, CardBody, NavItem, NavLink, Table } from "reactstrap"
import { axiosApi } from "helpers/api_helper"
import { shortenAddress, convertSecondsToPrintDateTimeString } from "utils"
import { GET_BUY_SELL_TRANSACTIONS } from "helpers/url_helper"

const WalletActivities = () => {
  const [activeTab, setActiveTab] = useState("All")
  const { address } = useGetAccountInfo()
  const [activities, setActivities] = useState([])

  const toggleTab = tab => {
    if (activeTab == tab) return

    setActiveTab(tab)

    axiosApi.get(`${GET_BUY_SELL_TRANSACTIONS}/${address}/${tab}`).then(res => {
      setActivities(res.data)
    })
  }

  useEffect(() => {
    axiosApi
      .get(`${GET_BUY_SELL_TRANSACTIONS}/${address}/${activeTab}`)
      .then(res => {
        setActivities(res.data)
      })
  }, [])

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Activities</h4>
        <ul className="nav nav-tabs nav-tabs-custom">
          <NavItem>
            <NavLink
              className={clsx({
                active: activeTab === "All",
              })}
              onClick={() => toggleTab("All")}
            >
              All
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={clsx({
                active: activeTab === "Buy",
              })}
              onClick={() => toggleTab("Buy")}
            >
              Buy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={clsx({
                active: activeTab === "Sell",
              })}
              onClick={() => toggleTab("Sell")}
            >
              Sell
            </NavLink>
          </NavItem>
        </ul>

        <div className="mt-4">
          {activities.length == 0 ? (
            <div
              className="text-center"
              style={{ margin: "60px 0px 40px 0px" }}
            >
              No activites yet.
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="ecpx-table mb-0">
                <thead>
                  <tr className="text-center">
                    <th>No</th>
                    <th>Hash</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {activities.map((row, id) => (
                    <tr className="text-center" key={`activity-${id}`}>
                      <td>{id + 1}</td>
                      <td>
                        <ExplorerLink
                          page={`transactions/${row.hash}`}
                          text={shortenAddress(row.hash, 8)}
                        />
                      </td>
                      <td>
                        {convertSecondsToPrintDateTimeString(
                          Date.now() / 1000 - row.timestamp
                        )}{" "}
                        ago
                      </td>
                      <td>{row.type}</td>
                      <td>ECPX</td>
                      <td>{row.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default WalletActivities
