import React from "react"
import { Container, Row, Col } from "reactstrap"
import Balance from "components/Common/Balance"
import MyTokens from "components/Common/MyTokens"
import EcompassChart from "components/custom/EcompassChart"
import Swap from "./Swap"

const BuyToken = () => {
  document.title = "Swap | ECPX HUB"

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col xl="9">
            <Swap />

            <div className="mt-4">
              <EcompassChart baseTokenTicker="USDC" />
            </div>
          </Col>

          <Col xl="3">
            <Balance />
            <div className='mt-4'>
              <MyTokens />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default BuyToken
