import axios from "axios"
import { BackendApiUrl } from "configs"

export const axiosApi = axios.create({
  baseURL: BackendApiUrl,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)
