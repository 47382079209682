import {
    UPDATE_NFT_INFO,
    GET_NFT_INFO
} from "./actionTypes";

export const updateNftInfo = (data) => ({
    type: UPDATE_NFT_INFO,
    payload: { data },
});

export const getNftInfo = (data) => ({
    type: GET_NFT_INFO,
    payload: { data },
});