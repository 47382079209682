import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Modal, Button } from "reactstrap"
import { Link } from "react-router-dom"
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
} from "@multiversx/sdk-dapp/UI"
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
//i18n
import { withTranslation } from "react-i18next"
import { walletConnectV2ProjectId } from "configs"
import { useLocation } from "react-router-dom"
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { useGetAccountInfo, useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks"
import { shortenAddress } from "utils"
import { logout } from "@multiversx/sdk-dapp/utils"
// Images
import logoLightSvg from "../../assets/ecpx/images/logo-sm.png"
import walletImg from "assets/images/wallet_logo.svg"
import cryptoWalletImg from "assets/images/Wallet.svg"
import keyImg from "assets/images/Key.svg"

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

// import user1 from "assets/images/users/avatar-1.jpg"
import user1 from "assets/ecpx/images/avatar.png"

const Header = props => {
  const [showModal, setShowModal] = useState(false)

  const { address: userAddress } = useGetAccountInfo()
  const isLoggedIn = useGetIsLoggedIn()
  const { pathname } = useLocation()

  useEffect(() => {
    if (userAddress) {
      setShowModal(false)
    }
  }, [userAddress])

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  const commonProps = {
    // nativeAuth: true, // optional
    callbackRoute: pathname,
  }

  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")

  const openInNewTab = url => {
    window.open(url, "_blank")
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" width="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            {!isLoggedIn ? (
              <button
                color="primary"
                className="connect-wallet d-flex align-items-center"
                onClick={() => {
                  if (!isLoggedIn) {
                    setShowModal(true)
                  }
                }}
              >
                <i className="bx bx-wallet mr-3" />
                Connect Wallet
              </button>
            ) : (
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item "
                  id="page-header-user-dropdown"
                  tag="button"
                  style={{ color: "#a6b0cf" }}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src={user1}
                    alt="Header Avatar"
                  />
                  <span className="d-none d-xl-inline-block ms-2 me-1">
                    {shortenAddress(userAddress)}
                  </span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {/* <DropdownItem>
                    <Link to="/comingsoon" style={{ color: "#A6B0CF" }}>
                      {" "}
                      <i className="bx bx-user font-size-16 align-middle me-1" />
                      {props.t("Profile")}{" "}
                    </Link>
                  </DropdownItem> */}
                  <DropdownItem tag="a" href="/my-wallet">
                    <Link to="/my-wallet" style={{ color: "#A6B0CF" }}>
                      <i className="bx bx-wallet font-size-16 align-middle me-1" />
                      {props.t("My Wallet")}
                    </Link>
                  </DropdownItem>
                  <DropdownItem tag="span" href="#">
                    <a href="https://wizrow.com/" style={{ color: "#A6B0CF" }} target="_blank" rel="noreferrer">
                      {/* <span className="badge bg-success float-end">11</span> */}
                      <i className="bx bx-wrench font-size-16 align-middle me-1" />
                      {props.t("Wizrow")}
                    </a>
                  </DropdownItem>
                  <DropdownItem tag="a" href="/comingsoon">
                    <Link to="/comingsoon" style={{ color: "#A6B0CF" }}>
                      <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                      {props.t("Lock screen")}
                    </Link>
                  </DropdownItem>
                  <div className="dropdown-divider" />
                  <Link
                    className="dropdown-item"
                    onClick={() => logout("dashboard")}
                  >
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>{props.t("Logout")}</span>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>
      </header>

      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(false)
        }}
        centered
        zIndex={100}
        style={{ padding: "0px 0px" }}
      >
        {/* <div className="modal-header pb-0 mb-2">
          <Row className="align-items-center">
            <Col className="col-8">
              <div className="title" style={{ paddingLeft: "18px" }}>
                <h4 className="text-success">Welcome Back !</h4>
                <p>Sign in to continue to ECPX.</p>
              </div>
            </Col>
            <Col className="col-4 align-self-end">
              <img src={walletImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div> */}
        <div
          className="d-flex justify-content-end"
          style={{ paddingRight: "20px" }}
        >
          <button
            className="btn btn-soft-secondary"
            onClick={() => {
              setShowModal(false)
            }}
          >
            X
          </button>
        </div>

        <div className="modal-body">
          <div className="wallet-connection-content">
            <div className="wallet-connection">
              <div style={{ paddingLeft: "18px", marginBottom: "40px" }}>
                <h4 className="mb-4">Welcome back!</h4>
                <p>Connect Your Wallet to Continue</p>
              </div>

              <WalletConnectLoginButton
                className="waves-effect waves-light wallet-connect-btn"
                loginButtonText="xPortal App"
                {...commonProps}
                // {...(walletConnectV2ProjectId
                //   ? {
                //       isWalletConnectV2: true
                //     }
                //   : {})}
                // nativeAuth
              />

              <ExtensionLoginButton
                className="waves-effect waves-light wallet-connect-btn"
                loginButtonText="MultiversX DeFi Wallet"
                {...commonProps}
                //nativeAuth
              />

              <LedgerLoginButton
                className="waves-effect waves-light wallet-connect-btn"
                loginButtonText="Ledger"
                {...commonProps}
                //nativeAuth
              />

              <WebWalletLoginButton
                className="waves-effect waves-light wallet-connect-btn"
                loginButtonText="MultiversX Web wallet"
                {...commonProps}
                //nativeAuth
              />
            </div>

            <div className="wallet-introduction">
              <div
                className="space-between-title-and-description"
                style={{ paddingLeft: "18px" }}
              >
                <h4>What is a Wallet?</h4>
              </div>

              <div className="d-flex align-items-center mb-4">
                <img
                  className="img-fluid"
                  src={cryptoWalletImg}
                  alt="crypto wallet"
                  width={"80px"}
                />
                <div>
                  <h6>A Home for your Digital Assets</h6>
                  <span style={{ fontSize: "10px" }}>
                    Wallets are used to send, receive, store and display digital
                    assets like Ethereum and NFTs.
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center mb-4">
                <img
                  className="img-fluid"
                  src={keyImg}
                  alt="Key"
                  width={"80px"}
                />
                <div>
                  <h6>A New Way to Log In</h6>
                  <span style={{ fontSize: "10px" }}>
                    Instead of creating new accounts and passwords on every
                    website, just connect your wallet
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-2">
                <Button
                  color="success"
                  outline
                  style={{ fontSize: "12px" }}
                  onClick={() =>
                    openInNewTab("https://xport.al/referral/1qepy3vyy2")
                  }
                >
                  Get a Wallet
                </Button>

                <Button
                  color="link"
                  className="btn btn-link waves-effect"
                  style={{ fontSize: "12px", color: "#A6B0CF" }}
                  onClick={() =>
                    openInNewTab(
                      "https://help.xportal.com/en/collections/3880463-how-to-guides"
                    )
                  }
                >
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
