import React, { useContext, useEffect, useRef } from "react"
import { createChart } from "lightweight-charts"
import imgEcompass from "assets/images/ecompass.svg"

function mostrarNumero(numero) {
  try {
    let numString = ""
    if (numero > 9) {
      numString = numero.toFixed(2)
    } else if (numero < 0.01) {
      const regex = /.*\..*?0[1-9]../
      const matchResult = numero.toString().match(regex)
      numString = matchResult ? matchResult[0] : numero.toString()
    } else {
      numString = numero.toFixed(3)
    }
    const decimalPart = numString.split(".")[1]
    if (!decimalPart) {
      numString += ".00"
    } else if (decimalPart.length === 1) {
      numString += "0"
    }
    const lenght_decimals = numString.split(".")[1].length
    return parseFloat(numString).toFixed(lenght_decimals)
  } catch (e) {
    return "0.00"
  }
}

const EcompassChart = ({ baseTokenTicker }) => {
  const chartContainerRef = useRef(null)
  const chartRef = useRef(null)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const fetchData = async () => {
      const now = new Date()
      //const from = new Date(now.getTime() - 83 * 60 * 60 * 1000); // Subtract 7 days
      const to = new Date(now.getTime() + 1 * 60 * 60 * 1000) // Add 1 hour
      const from = new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000) // Subtract 6 days
      //console.log('from',Math.floor(from.getTime()));
      //console.log('to',to);

      const uri =
        baseTokenTicker == "USDC"
          ? `https://apiv2.e-compass.io/price/history?exchange=onedex&basePair=ECPX&quotePair=USDC&from=${Math.floor(
              from.getTime() / 1000
            )}&to=${Math.floor(to.getTime() / 1000)}&interval=10`
          : `https://apiv2.e-compass.io/price/history?exchange=onedex&basePair=ECPX&quotePair=WEGLD&from=${Math.floor(
              from.getTime() / 1000
            )}&to=${Math.floor(to.getTime() / 1000)}&interval=10`
      //console.log('uri',uri);

      const response = await fetch(uri, { signal })
      const data = await response.json()
      window.addEventListener("resize", handleResize)
      if (!chartRef.current && chartContainerRef.current) {
        chartRef.current = createChart(chartContainerRef.current, {
          height: 300,
          rightPriceScale: {
            borderColor: "#0B0F21",
            visible: true,
            scaleMargins: {
              top: 0.2,
              bottom: 0.1,
            },
          },
          timeScale: {
            borderColor: "#0B0F21",
            timeVisible: true,
            secondsVisible: false,
          },
          layout: {
            textColor: "gray",
            background: {
              color: "#0B0F21",
            },
          },
          grid: {
            horzLines: {
              visible: true,
              color: "#3d3c59",
            },
            vertLines: {
              visible: true,
              color: "#3d3c59",
            },
          },
        })
      }
      const candlestickData = data
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(({ timestamp, close }) => ({
          value: parseFloat(close.toString()),
          time: Number(timestamp),
        }))

      const candlestickSeries = chartRef.current?.addAreaSeries({
        lineColor: "#10FFC6",
        topColor: "#10FFC6",
        bottomColor: "#0B0F21",
      })

      const currentPrice = mostrarNumero(
        parseFloat(data[data.length - 1].close)
      )
      const decimalCount =
        Number(currentPrice) % 1 !== 0
          ? currentPrice.toString().split(".")[1].length
          : 0
      const precision = decimalCount > 0 ? decimalCount : 2
      const minMove =
        decimalCount > 0
          ? parseFloat(`0.${"0".repeat(decimalCount - 1)}1`)
          : 0.01

      if (candlestickSeries) {
        candlestickSeries.applyOptions({
          priceFormat: {
            type: "price",
            precision: precision,
            minMove: minMove,
          },
        })
        candlestickSeries.setData(candlestickData)
      }
    }
    const handleResize = () => {
      if (chartContainerRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
        })
      }
    }
    fetchData()
    return () => {
      if (chartRef.current) {
        chartRef.current.remove()
        chartRef.current = null
        window.removeEventListener("resize", handleResize)
        controller.abort()
      }
    }
  }, [])

  return (
    <div
      style={{
        padding: ".8rem",
        background: "black",
        // border: '1px solid #3C3C3C',
        borderRadius: "5px",
      }}
      // className='mt-4'
    >
      <div className="d-flex justify-content-between">
        <a
          style={{ textDecoration: "none", fontSize: "12px", color: "white" }}
          {...{
            target: "_blank",
            href:
              baseTokenTicker == "USDC"
                ? "https://e-compass.io/onedex/chart/ecpx/usdc"
                : "https://e-compass.io/onedex/chart/ecpx/egld",
          }}
        >
          <img src={imgEcompass} style={{ width: "15px" }} alt="ecompas_img" />{" "}
          Show detailed chart
        </a>
        <div style={{ fontSize: ".8rem", color: "#00FFA3" }}>
          ECPX/{baseTokenTicker == "USDC" ? "USDC" : "EGLD"} Price History
        </div>
      </div>
      <div className="mt-2" ref={chartContainerRef} />
      <div
        className="mt-2"
        style={{ fontSize: "12px", textAlign: "center", color: "white" }}
      >
        Powered by e-compass
      </div>
      <div
        className="mt-0"
        style={{ textAlign: "right", color: "white", fontSize: "10px" }}
      >
        *Price in {baseTokenTicker == "USDC" ? "USDC" : "EGLD"}
      </div>
    </div>
  )
}

export default EcompassChart
