import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import backVideo from "assets/ecpx/images/vibrance1.mp4"
import TopClans from "./TopClans"
import TopWarriors from "./TopWarriors"
import ClanRanks from "./ClanRanks"
import ClanDetails from "./ClanDetails"
import { queryGetBattleInfo } from "helpers/mvx"
import Tooltip from "@mui/material/Tooltip"

const Leaderboard = () => {
  document.title = "Leaderboard | ECPx HUB"
  
  const [selectedBattleId, setSelectedBattleId] = useState(0)
  const [selectedClan, setSelectedClan] = useState(undefined)
  const [battleStatus, setBattleStatus] = useState(false) // true is ongoing, false is ended


  useEffect(() => {
    ;(async () => {
      const _battleInfo = await queryGetBattleInfo()
      setSelectedBattleId(_battleInfo.battle_id)
      if (_battleInfo.end_time >= new Date().getTime() / 1000) {
        setBattleStatus(true)
      } else {
        setBattleStatus(false)
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="position-relative">
          <video
            className="background-video mb-4"
            src={backVideo}
            autoPlay
            loop
          />

          <div
            className="position-absolute"
            style={{ bottom: "5px", right: "15px" }}
          >
            <div className="battle-id-box">
              #{selectedBattleId}
              <Tooltip title={battleStatus ? "Ongoing" : "Ended"}>
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    background: battleStatus ? "green" : "gray",
                    borderRadius: "150px",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <Row style={{ rowGap: "20px" }}>
          <Col sm={7}>
            <ClanRanks
              battle_id={selectedBattleId}
              onClanClick={setSelectedClan}
            />
          </Col>
          <Col sm={5}>
            <ClanDetails clan={selectedClan} />
          </Col>
          <Col sm={6}>
            <TopClans />
          </Col>
          <Col sm={6}>
            <TopWarriors />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Leaderboard
