import React from "react"
import { Link } from "react-router-dom"
import { Row, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import Genesis from "assets/ecpx/images/dashboard/genesis.png"
import ECPX from "assets/ecpx/images/dashboard/ecpx.png"
import RP from "assets/ecpx/images/dashboard/rp.png"
import ECPxLightLogo from "assets/ecpx/images/dashboard/ECPx-lgAbt.png"
import Db_img from "assets/ecpx/images/dashboard/Db_img.png"
import NFTStakingImg from "assets/ecpx/images/dashboard/NFT_left_side_view.png"
import LeaderboardImg from "assets/ecpx/images/dashboard/Contract_left_side_view.png"
import VariableStakingImg from "assets/ecpx/images/dashboard/Blockchain_left_side_view.png"
import BattleStatsImg from "assets/ecpx/images/dashboard/Chart_left_side_view.png"

import FollowUs from "./FollowUs"
import Balance from "components/Common/Balance"
import Swap from "../BuyToken/Swap"

import { ImEnter } from "react-icons/im"

const Dashboard = props => {
  //meta title
  document.title = "ECPx HUB"

  return (
    <React.Fragment>
      <div className="page-content">
        <div>
          <Row style={{ rowGap: "20px" }}>
            <Col xl="4">
              <FollowUs />
            </Col>

            <Col xl="8">
              <div className="black-card d-flex align-items-center justify-content-end h-100">
                <a
                  href="https://elrondcyberpunks.com/"
                  style={{ fontSize: "16px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Website |&nbsp;
                </a>
                <a
                  href="https://wizrow.com/"
                  style={{ fontSize: "16px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Wiz Row
                </a>
              </div>
            </Col>

            <Col xl="8">
              <div className="h-100">
                <Swap />
              </div>
            </Col>

            <Col xl="4">
              <div className="d-flex flex-column h-100 gap-4">
                <div>
                  <Balance />
                </div>

                <div style={{ flexGrow: "1" }}>
                  <div className="buy-token-panel h-100">
                    <div className="d-flex align-items-center">
                      <img
                        src={ECPxLightLogo}
                        alt="ecpx light logo"
                        width={"100px"}
                      />
                      <div
                        style={{
                          fontSize: "24px",
                          color: "white",
                          fontWeight: "600",
                        }}
                      >
                        $ECPx
                      </div>
                    </div>

                    <a
                      href="https://explorer.multiversx.com/tokens/ECPX-5cbfeb"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="btn btn-outline-success">
                        View in Explorer
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Col>

            <Col xl={"4"}>
              <div className="d-flex flex-column h-100 gap-4">
                <div
                  className="black-card d-flex justify-content-between"
                  style={{ flexGrow: "1" }}
                >
                  <div>
                    <h4>Leaderboard</h4>
                    <p className="text-success">Real time results</p>
                    <Link to="/leaderboard">
                      <div
                        className="btn btn-outline-success"
                        style={{ marginTop: "24px" }}
                      >
                        Learn More
                      </div>
                    </Link>
                  </div>
                  <img
                    src={LeaderboardImg}
                    alt=""
                    width="100px"
                    style={{ objectFit: "contain" }}
                  />
                </div>

                <div className="black-card d-flex justify-content-between">
                  <div>
                    <h4>Battle Stats</h4>
                    <p className="text-success">All Transaction Tracking</p>

                    <Link to="/battle-stats">
                      <div
                        className="btn btn-outline-success"
                        style={{ marginTop: "24px" }}
                      >
                        Learn More
                      </div>
                    </Link>
                  </div>
                  <img
                    src={BattleStatsImg}
                    alt=""
                    width="100px"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            </Col>

            <Col xl={"8"}>
              <div className="position-relative">
                <img
                  src={Db_img}
                  width="100%"
                  style={{
                    objectFit: "cover",
                    borderRadius: "8px",
                    maxHeight: "360px",
                  }}
                />
                <div
                  className="position-absolute"
                  style={{ bottom: "0", right: "0" }}
                >
                  <Link to="/battle">
                    <div
                      className="btn btn-success"
                      style={{ fontSize: "20px" }}
                    >
                      <ImEnter />
                    </div>
                  </Link>
                </div>
              </div>
            </Col>

            <Col xl="6">
              <div className="black-card d-flex justify-content-between">
                <div>
                  <h4>NFT Staking</h4>
                  <p className="text-success">
                    Stake your NFTs and earn $eGLD & ECPx
                  </p>
                  <Link to="/nft-staking">
                    <div
                      className="btn btn-outline-success"
                      style={{ marginTop: "24px" }}
                    >
                      Stake Now
                    </div>
                  </Link>
                </div>
                <img
                  src={NFTStakingImg}
                  alt=""
                  width="100px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>

            <Col xl="6">
              <div className="black-card d-flex justify-content-between">
                <div>
                  <h4>Clans Staking</h4>
                  <p className="text-success">Royal staking for clan members</p>
                  <Link to={"/clanpools"}>
                  <div
                    className="btn btn-outline-success"
                    style={{ marginTop: "24px" }}
                  >
                    Clans Staking
                  </div>
                  </Link>
                </div>
                <img
                  src={VariableStakingImg}
                  alt=""
                  width="100px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>

            <Col lg="4">
              <div className="collection-summary">
                <img src={Genesis} />
                <div className="d-flex flex-column">
                  <h4>Genesis</h4>
                  <span>Collection Identifier</span>
                  <h5>MAXWELLS-a03c6e</h5>
                  <a
                    href="https://explorer.multiversx.com/collections/MAXWELLS-a03c6e"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Explorer
                  </a>
                </div>
              </div>
            </Col>

            <Col lg="4">
              <div className="collection-summary">
                <img src={ECPX} />
                <div className="d-flex flex-column">
                  <h4>ECPx</h4>
                  <span>Collection Identifier</span>
                  <h5>ECPX-cdbb2d</h5>
                  <a
                    href="https://explorer.multiversx.com/collections/ECPX-cdbb2d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Explorer
                  </a>
                </div>
              </div>
            </Col>

            <Col lg="4">
              <div className="collection-summary">
                <img src={RP} />
                <div className="d-flex flex-column">
                  <h4>Real PunkZ</h4>
                  <span>Collection Identifier</span>
                  <h5>RPZ-b7ead4</h5>
                  <a
                    href="https://explorer.multiversx.com/collections/RPZ-b7ead4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Explorer
                  </a>
                </div>
              </div>
            </Col>

            {/* <Col xl="9">
              <Row>
                <Col sm="3">
                  <div className="buy-card black-card">
                    <i className="bx bx-package"></i>

                    <a
                      href="https://xoxno.com/collection/MAXWELLS-a03c6e"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy Genesis
                    </a>
                    <p>MAXWELLS-a03c6e</p>
                  </div>
                </Col>

                <Col sm="3">
                  <div className="buy-card blue-card">
                    <i className="bx bx-chart"></i>

                    <Link to="/nft-staking">Stake Genesis</Link>
                    <p>MAXWELLS-a03c6e</p>
                  </div>
                </Col>

                <Col sm="3">
                  <div className="buy-card black-card">
                    <i className="bx bx-package"></i>

                    <Link to="/buy-package">Buy ECPx</Link>
                    <p>ECPX-cdbb2d</p>
                  </div>
                </Col>

                <Col sm="3">
                  <div className="buy-card blue-card">
                    <i className="bx bx-chart"></i>

                    <Link to="/nft-staking">Stake ECPx</Link>
                    <p>ECPX-cdbb2d</p>
                  </div>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
