import { mvxQuery } from "./common";
import { battleSmartContract } from "./provider";

export async function queryGetBattleInfo() {
    const value = await mvxQuery(battleSmartContract.methods.getBattleInfo());
    const decoded = {
        battle_id: value.battle_id.toNumber(),
        battle_clans: value.battle_clans.map(v => v.toString()),
        battle_votes: value.battle_votes.map(v => v.toNumber()),
        start_time: value.start_time.toNumber(),
        end_time: value.end_time.toNumber(),
        current_time: value.current_time.toNumber(),
        status: value.status.name,
        winner: value.winner.toString(),
        clan_info_is_empty: value.clan_info_is_empty,
        fee: value.fee.toFixed(),
        egld_prize: value.egld_prize.toFixed(),
        ecpx_prize: value.ecpx_prize.toFixed(),
        min_power: value.min_power.toNumber(),
        duration: value.duration.toNumber(),
    };
    return decoded;
}
