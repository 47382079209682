export const convertSecondsToPrintDateTime = (totalSeconds) => {
  const days = Math.floor(totalSeconds / (3600 * 24));
  totalSeconds %= 3600 * 24;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
      days: days.toFixed(),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
  };
};

export const convertSecondsToPrintDateTimeString = (totalSeconds) => {
  const { days, hours, minutes, seconds } = convertSecondsToPrintDateTime(totalSeconds);

  return (days > 0 ? `${days}d ` : '') + `${hours}h ${minutes}m`;
};
